import { isPlainObject } from 'lodash';
export function isValidPreferenceKey(key, preferenceDeclaration) {
  if (!preferenceDeclaration || !Array.isArray(preferenceDeclaration.properties)) {
    return false;
  }
  return preferenceDeclaration.properties.some(function (prop) {
    return prop.key === key;
  });
}
export function filterValidOptions(opts, preferenceDeclaration) {
  if (!opts || !isPlainObject(opts)) return opts;
  var filteredOpts = {};
  Object.keys(opts).forEach(function (key) {
    if (isValidPreferenceKey(key, preferenceDeclaration)) {
      var v = opts[key];
      if (v !== undefined && v !== null) {
        filteredOpts[key] = v;
      }
    }
  });
  return filteredOpts;
}