import { ComponentRegistry } from '@digiforce-ui/builder-store';
import 'react-querybuilder/es/query-builder.scss';
export {
	ObjectTable,
	ObjectProTable,
	ObjectForm,
	ObjectList,
	ObjectListView,
	RecordDetailRelatedList,
	RecordDetailHeader,
	SpaceUsers,
	SpaceUsersModal,
	Organizations,
	OrganizationsModal,
	ObjectTree,
	ObjectExpandTable,
	ObjectModal,
	Iframe,
	API,
	DigiforceProvider,
	DigiforceRouter,
	Forms,
} from '@digiforce-ui/builder-object';
export { ObjectGrid, ObjectGridPivot } from '@digiforce-ui/builder-ag-grid';

import { MultipleContainers, ObjectKanban } from '@digiforce-ui/builder-kanban';
import { ObjectChart, PageChartView, ObjectOrganizationChart, PageOrganizationChartView } from '@digiforce-ui/builder-chart';
import { Page, QuerySource } from '@digiforce-ui/builder-page'

Object.assign(ComponentRegistry.components, {
	ObjectTable,
	ObjectProTable,
	ObjectForm,
	ObjectList,
	ObjectListView,
	RecordDetailRelatedList,
	RecordDetailHeader,
	SpaceUsers,
	SpaceUsersModal,
	Organizations,
	OrganizationsModal,
	ObjectTree,
	ObjectExpandTable,
	ObjectModal,
	Iframe,
	Page,
	PublicPage,
	QuerySource,
	WorkflowDesignerPage,
	MultipleContainers,
	ObjectKanban,
	ObjectChart,
	PageChartView,
	ObjectOrganizationChart,
	PageOrganizationChartView
});

export { ComponentRegistry };
import { PublicPage, WorkflowDesignerPage } from '@digiforce-ui/builder-page';
export { PublicPage, WorkflowDesignerPage }
import { isExpression, parseSingleExpression } from '@digiforce-ui/builder-object';
let Utils = {};
Object.assign(Utils, {
	isExpression,
	parseSingleExpression,
});
export { Utils };

import * as Visualization from '@digiforce-ui/builder-viz-lib';
export { Visualization };
import * as BuilderDGPlatform from '@digiforce-ui/builder-dgplatform';
export { BuilderDGPlatform };

if (window && !(window as any).BuilderDGPlatform) {
	(window as any).BuilderDGPlatform = BuilderDGPlatform;
}

import {
	ObjectTable,
	ObjectProTable,
	RecordDetailRelatedList,
	RecordDetailHeader,
	ObjectForm,
	ObjectList,
	ObjectListView,
	SpaceUsers,
	SpaceUsersModal,
	Organizations,
	OrganizationsModal,
	ObjectTree,
	ObjectExpandTable,
	ObjectModal,
	Iframe,
} from '@digiforce-ui/builder-object';

import * as DVDIntergration from '@digiforce/dvd-intergration/es/umd'

if (window && !(window as any).DVDIntergration) {
	(window as any).DVDIntergration = DVDIntergration;
}

import * as DigiforceVisualDesigner from '@digiforce/dvd-engine';
export { DigiforceVisualDesigner }
if (window && !(window as any).DigiforceVisualDesigner) {
	(window as any).DigiforceVisualDesigner = DigiforceVisualDesigner;
}

import * as DigiforceVisualDesignerExt from '@digiforce/dvd-engine-ext';
if (window && !(window as any).DigiforceVisualDesignerExt) {
	(window as any).DigiforceVisualDesignerExt = DigiforceVisualDesignerExt;
}
(window as any).AliLowCodeEngine = DigiforceVisualDesigner;
(window as any).AliLowCodeEngineExt = DigiforceVisualDesignerExt;

export { default as QueryBuilder } from 'react-querybuilder'
export { QueryBuilderAntD } from '@react-querybuilder/antd'

export { Editor } from '@tinymce/tinymce-react';