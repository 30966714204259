import { dataServicesSelector } from '../../selectors';
import { loadEntitiesDataRequest } from '../records_request';
import { createAction as baseCreateAction } from '../base';
import { FavoritesTypes } from '../../action_types';
export var FAVORITES_STATE_CHANGE_ACTION = 'FAVORITES_STATE_CHANGE';
export function loadFavoritesEntitiesData(options) {
  return function (dispatch, getState) {
    dispatch(baseCreateAction(FAVORITES_STATE_CHANGE_ACTION, FavoritesTypes.GET_FAVORITES_REQUEST, {}, {}));
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, FAVORITES_STATE_CHANGE_ACTION, service, Object.assign({
      RequestStatus: {
        SUCCESS: FavoritesTypes.GET_FAVORITES_SUCCESS,
        FAILURE: FavoritesTypes.GET_FAVORITES_FAILURE
      }
    }, options));
  };
}
export function changeActionSelected(actionSelected, id) {
  return function (dispatch, getState) {
    dispatch(baseCreateAction(FAVORITES_STATE_CHANGE_ACTION, FavoritesTypes.CHANGE_FAVORITES_ACTIONSELECTED, {
      actionSelected: actionSelected
    }, {
      id: id
    }));
  };
}
export function changeActionDisabled(actionDisabled, id) {
  return function (dispatch, getState) {
    dispatch(baseCreateAction(FAVORITES_STATE_CHANGE_ACTION, FavoritesTypes.CHANGE_FAVORITES_ACTIONDISABLED, {
      actionDisabled: actionDisabled
    }, {
      id: id
    }));
  };
}
export function changeRecords(records, id) {
  return function (dispatch, getState) {
    dispatch(baseCreateAction(FAVORITES_STATE_CHANGE_ACTION, FavoritesTypes.CHANGE_FAVORITES_RECORDS, {
      records: records
    }, {
      id: id
    }));
  };
}
export function changeAssistiveText(assistiveText, id) {
  return function (dispatch, getState) {
    dispatch(baseCreateAction(FAVORITES_STATE_CHANGE_ACTION, FavoritesTypes.CHANGE_FAVORITES_ASSISTIVETEXT, {
      assistiveText: assistiveText
    }, {
      id: id
    }));
  };
}