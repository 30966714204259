import { isArray, isNil } from 'lodash';
export var safeRunFunction = function safeRunFunction(fun, args, defaultValue, _this) {
  try {
    var DGPlatform = window.DGPlatform;
    var isDGPlatform = !!(DGPlatform && DGPlatform.getObjectUrl);
    var regDGPlatform = /\bDigiforce\b|\bDGPlatform\b|\bMeteor\b|\bSession\b/;
    if (!isDGPlatform && regDGPlatform.test(fun)) {
      console.info("Call DGPlatform|Digiforce|Meteor|Session, the script of the variable is not executed, and it is processed directly according to the empty value.");
      return "";
    }
    // TODO: Remove DGPlatform later, the above judgment isDGPlatform and returning the empty logic can be removed.
    var params = [];
    if (!isNil(args)) {
      params = isArray(args) ? args : [args];
    }
    return fun.bind(_this || {}).apply(void 0, params);
  } catch (error) {
    console.log(error);
    return defaultValue;
  }
};
export var BASE_FIELDNAMES_FOR_SYSTEM = ["created", "created_by", "modified", "modified_by"];
export var BASE_FIELDNAMES_FOR_PERMISSIONS = ["owner", "company_id", "company_ids", "locked"];
export var getObjectBaseFieldNames = function getObjectBaseFieldNames(objectSchema) {
  if (objectSchema && ["default", "meteor"].indexOf(objectSchema.datasource || "default") > -1) {
    return BASE_FIELDNAMES_FOR_PERMISSIONS;
  } else {
    return [];
  }
};
export var getObjectNameFieldKey = function getObjectNameFieldKey(objectSchema) {
  var nameFieldKey = objectSchema.NAME_FIELD_KEY;
  if (objectSchema.name === "organizations") {
    nameFieldKey = "name";
  }
  return nameFieldKey || "name";
};
export var getObjectChildrenFieldName = function getObjectChildrenFieldName(objectSchema) {
  return objectSchema.children_field || "children";
};
export var getObjectParentFieldName = function getObjectParentFieldName(objectSchema) {
  return objectSchema.parent_field || "parent";
};