import { useMemo } from 'react';
var preferPropDefaultTrue = function preferPropDefaultTrue(prop, context) {
  return prop === false ? false : prop ? true : context === false ? false : true;
};
var preferPropDefaultFalse = function preferPropDefaultFalse(prop, context) {
  return prop ? true : prop === false ? false : context ? true : false;
};
var preferProp = function preferProp(def, prop, context) {
  return def ? preferPropDefaultTrue(prop, context) : preferPropDefaultFalse(prop, context);
};
export var usePreferProp = function usePreferProp(def, prop, context) {
  return useMemo(function () {
    return preferProp(def, prop, context);
  }, [context, def, prop]);
};