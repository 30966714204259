import { toArray } from '../arrayUtils';
import { isValidValue, shouldRenderAsNumber } from './utils';
var convertOperator = function convertOperator(op) {
  return op.replace(/^(=)$/, '$1=').replace(/^notNull$/i, '!=').replace(/^null$/i, '==');
};
var negateIfNotOp = function negateIfNotOp(op, jsonRule) {
  return /^(does)?not/i.test(op) ? {
    '!': jsonRule
  } : jsonRule;
};
export var defaultRuleProcessorJsonLogic = function defaultRuleProcessorJsonLogic(_ref, _temp) {
  var field = _ref.field,
    operator = _ref.operator,
    value = _ref.value,
    valueSource = _ref.valueSource;
  var _ref2 = _temp === void 0 ? {} : _temp,
    parseNumbers = _ref2.parseNumbers;
  var valueIsField = valueSource === 'field';
  var fieldObject = {
    "var": field
  };
  var fieldOrNumberRenderer = function fieldOrNumberRenderer(v) {
    return valueIsField ? {
      "var": "" + v
    } : shouldRenderAsNumber(v, parseNumbers) ? parseFloat(v) : v;
  };
  if (operator === '<' || operator === '<=' || operator === '=' || operator === '!=' || operator === '>' || operator === '>=') {
    var _ref3;
    return _ref3 = {}, _ref3[convertOperator(operator)] = [fieldObject, fieldOrNumberRenderer(value)], _ref3;
  } else if (operator === 'null' || operator === 'notNull') {
    var _ref4;
    return _ref4 = {}, _ref4[(operator === 'notNull' ? '!' : '=') + "="] = [fieldObject, null], _ref4;
  } else if (operator === 'in' || operator === 'notIn') {
    // TODO: extract this map function
    var valArray = toArray(value).map(fieldOrNumberRenderer);
    if (valArray.length > 0) {
      var jsonRule = {
        "in": [fieldObject, valArray]
      };
      return negateIfNotOp(operator, jsonRule);
    }
    return false;
  } else if (operator === 'between' || operator === 'notBetween') {
    var _valArray = toArray(value);
    if (_valArray.length >= 2 && isValidValue(_valArray[0]) && isValidValue(_valArray[1])) {
      var first = _valArray[0],
        second = _valArray[1];
      if (!valueIsField && shouldRenderAsNumber(first, true) && shouldRenderAsNumber(second, true)) {
        var firstNum = parseFloat(first);
        var secondNum = parseFloat(second);
        if (secondNum < firstNum) {
          var tempNum = secondNum;
          second = firstNum;
          first = tempNum;
        } else {
          first = firstNum;
          second = secondNum;
        }
      } else if (valueIsField) {
        first = {
          "var": first
        };
        second = {
          "var": second
        };
      }
      var _jsonRule = {
        '<=': [first, fieldObject, second]
      };
      return negateIfNotOp(operator, _jsonRule);
    }
    return false;
  } else if (operator === 'contains' || operator === 'doesNotContain') {
    var _jsonRule2 = {
      "in": [fieldOrNumberRenderer(value), fieldObject]
    };
    return negateIfNotOp(operator, _jsonRule2);
  } else if (operator === 'beginsWith' || operator === 'doesNotBeginWith') {
    var _jsonRule3 = {
      startsWith: [fieldObject, fieldOrNumberRenderer(value)]
    };
    return negateIfNotOp(operator, _jsonRule3);
  } else if (operator === 'endsWith' || operator === 'doesNotEndWith') {
    var _jsonRule4 = {
      endsWith: [fieldObject, fieldOrNumberRenderer(value)]
    };
    return negateIfNotOp(operator, _jsonRule4);
  }
  return false;
};