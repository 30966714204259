import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import DigiforceModal from './modal';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
import { createModalAction } from '../../actions';
function makeMapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    closeModal: function closeModal(modalId) {
      return dispatch(createModalAction('isOpen', false, {
        id: modalId
      }));
    }
  };
};
export default connect(makeMapStateToProps, mapDispatchToProps)(DigiforceModal);