import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Grid from './salesforce_grid';
import { createGridAction, loadGridEntitiesData, removeViewAction as _removeViewAction } from '../../actions';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleChanged: function handleChanged(event, data) {
      dispatch(createGridAction('selection', data.selection, ownProps));
    },
    handlePageChanged: function handlePageChanged(currentPage) {
      var newOptions = {};
      if (ownProps.pager) {
        newOptions.count = true;
      }
      dispatch(createGridAction('currentPage', currentPage, Object.assign({}, ownProps, newOptions)));
    },
    init: function init(options) {
      var newOptions = {};
      if (options.pager) {
        newOptions.count = true;
      }
      dispatch(loadGridEntitiesData(Object.assign({}, options, newOptions)));
    },
    removeViewAction: function removeViewAction(viewId) {
      return dispatch(_removeViewAction(viewId));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Grid);