export function settingsStateSelector(state) {
  return state.settings ? state.settings : undefined;
}
export function dataServicesSelector(state) {
  var digiforceService = '/';
  if (window && window.Meteor) {
    digiforceService = window.Digiforce.absoluteUrl('', true);
  }
  if (digiforceService) {
    digiforceService = digiforceService.replace(/\/$/, "");
  }
  return digiforceService;
}