import { isArray, intersection } from "lodash";
import { useCallback } from "react";
import ScheduleDialog from "../../../components/queries/ScheduleDialog";
import { clientConfig } from "../../../services/auth";
import { policy } from "../../../services/policy";
import useUpdateQuery from "./useUpdateQuery";
import useQueryFlags from "./useQueryFlags";
import recordEvent from "../../../services/recordEvent";
export default function useEditScheduleDialog(query, onChange) {
  // We won't use flags that depend on data source
  var queryFlags = useQueryFlags(query);
  var updateQuery = useUpdateQuery(query, onChange);
  return useCallback(function () {
    if (!queryFlags.canEdit || !queryFlags.canSchedule) {
      return;
    }
    var intervals = clientConfig.queryRefreshIntervals;
    var allowedIntervals = policy.getQueryRefreshIntervals();
    var refreshOptions = isArray(allowedIntervals) ? intersection(intervals, allowedIntervals) : intervals;
    ScheduleDialog.showModal({
      schedule: query.schedule,
      refreshOptions: refreshOptions
    }).onClose(function (schedule) {
      recordEvent("edit_schedule", "query", query.id);
      updateQuery({
        schedule: schedule
      });
    });
  }, [query.id, query.schedule, queryFlags.canEdit, queryFlags.canSchedule, updateQuery]);
}