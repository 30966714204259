var settings = function settings(state, action) {
  if (state === void 0) {
    state = {};
  }
  switch (action.type) {
    case 'RECEIVED_SETTINGS':
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
};
export default settings;