import _Space from "antd/lib/space";
import _Button from "antd/lib/button";
import _notification from "antd/lib/notification";
import _message from "antd/lib/message";
import * as modal from './modal';
import { Tables, Objects } from '@digiforce-ui/builder-store';
import * as render from './render';
export * from './modal';
export * from './render';
var DigiforceUI = Object.assign({}, modal, render, {
  getTableSelectedRows: function getTableSelectedRows(id) {
    if (id === void 0) {
      id = "default";
    }
    return Tables.loadById(id).getSelectedRows();
  },
  clearTableSelectedRows: function clearTableSelectedRows(id) {
    if (id === void 0) {
      id = "default";
    }
    return Tables.loadById(id).clearSelectedRows();
  },
  getTableTotalCount: function getTableTotalCount(id) {
    if (id === void 0) {
      id = "default";
    }
    return Tables.loadById(id).getTotalCount();
  },
  reloadRecord: function reloadRecord(objectApiName, id) {
    Objects.getObject(objectApiName).reloadRecord(id);
  },
  reloadObject: function reloadObject(objectApiName) {
    Objects.reloadObject(objectApiName);
  },
  downloadFile: function downloadFile(downloadProps) {
    var download_url = downloadProps.download_url,
      file_name = downloadProps.file_name,
      file_size = downloadProps.file_size,
      object_name = downloadProps.object_name,
      record_id = downloadProps.record_id,
      file_id = downloadProps.file_id;
    var url;
    if (window.Meteor && window.Meteor.isCordova) {
      return window.Digiforce.cordovaDownload(download_url, file_name, file_id, file_size);
    } else {
      url = download_url + "?download=true";
      return window.location = url;
    }
  },
  previewFile: function previewFile(previewProps) {
    var download_url = previewProps.download_url,
      file_name = previewProps.file_name,
      file_size = previewProps.file_size,
      object_name = previewProps.object_name,
      record_id = previewProps.record_id,
      file_id = previewProps.file_id;
    DigiforceUI.downloadFile({
      download_url: download_url,
      file_name: file_name,
      file_size: file_size,
      object_name: object_name,
      record_id: record_id,
      file_id: file_id
    });
  },
  refs: {},
  getRef: function getRef(name) {
    return DigiforceUI.refs[name];
  },
  router: {
    go: function go(action, to) {
      var _ref = action || {},
        objectName = _ref.objectName,
        recordId = _ref.recordId;
      var router = window.FlowRouter;
      if (to) {
        if (router) {
          return router.go(to);
        } else {
          return window.open(to);
        }
      }
      if (router && objectName && recordId) {
        var _router$current;
        var params = ((_router$current = router.current()) === null || _router$current === void 0 ? void 0 : _router$current.params) || {};
        if (router.current().route.pathDef === '/app/:app_id/:object_name/view/:record_id') {
          //Detailed page
          if (params.object_name !== objectName) {
            // Objectform has a cache, and the new sub -table record may have a summary field of the main table record. You need to refresh the main table record
            DigiforceUI.reloadRecord(params.object_name, params.record_id);
          }
          if (params.record_id != recordId) {
            // Save and Insert
            router.go("/app/" + params.app_id + "/" + objectName + "/view/" + recordId);
          }
          router.reload();
        } else if (router.current().route.pathDef === '/app/:app_id/:object_name/grid/:list_view_id' || router.current().route.pathDef === '/app/:app_id/:object_name/:record_id/:related_object_name/grid') {
          if (action.type === 'new' && params.object_name === objectName) {
            router.go("/app/" + params.app_id + "/" + objectName + "/view/" + recordId);
          } else {
            if (params.object_name && params.related_object_name) {
              // Objectform has a cache, and the new sub -table record may have a summary field of the main table record. You need to refresh the main table record
              DigiforceUI.reloadRecord(params.object_name, params.record_id);
            }
            router.reload();
          }
        }
      } else {
        console.warn('Do not support route to action', action);
      }
    },
    reload: function reload() {
      var router = window.FlowRouter;
      if (router) {
        router.reload();
      }
    }
  },
  message: _message,
  notification: _notification,
  components: {
    Button: _Button,
    Space: _Space
  }
});
if (!window.DigiforceUI) {
  window.DigiforceUI = DigiforceUI;
}