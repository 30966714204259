import { CATEGORIES_STATE_CHANGE_ACTION } from '../../actions/views/categories';
// import _ from 'lodash'

function getRootNodes(records) {
  return _.map(records, '_id');
}
function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === CATEGORIES_STATE_CHANGE_ACTION) {
    var _Object$assign;
    var payload = action.payload;
    switch (payload.partialStateName) {
      case 'loadDataSauce':
        return Object.assign({}, state, {
          rootNodes: getRootNodes(payload.partialStateValue.records)
        });
      default:
        break;
    }
    return Object.assign({}, state, (_Object$assign = {}, _Object$assign[payload.partialStateName] = payload.partialStateValue, _Object$assign));
  }
  return state;
}
;
export default reducer;