import _extends from "@babel/runtime/helpers/extends";
import { has, map, isObject } from "lodash";
import { axios } from "./axios";
import { fetchDataFromJob } from "./query-result";
export var SCHEMA_NOT_SUPPORTED = 1;
export var SCHEMA_LOAD_ERROR = 2;
export var IMG_ROOT = "static/images/db-logos";
function mapSchemaColumnsToObject(columns) {
  return map(columns, function (column) {
    return isObject(column) ? column : {
      name: column
    };
  });
}
var DataSource = {
  query: function query() {
    return axios.get("/service/api/~packages-@digiforce/service-charts/data_sources");
  },
  get: function get(_ref) {
    var id = _ref.id;
    return axios.get("api/data_sources/" + id);
  },
  types: function types() {
    return axios.get("api/data_sources/types");
  },
  create: function create(data) {
    return axios.post("api/data_sources", data);
  },
  save: function save(data) {
    return axios.post("api/data_sources/" + data.id, data);
  },
  test: function test(data) {
    return axios.post("api/data_sources/" + data.id + "/test");
  },
  "delete": function _delete(_ref2) {
    var id = _ref2.id;
    return axios["delete"]("api/data_sources/" + id);
  },
  fetchSchema: function fetchSchema(data, refresh) {
    if (refresh === void 0) {
      refresh = false;
    }
    var params = {};
    if (refresh) {
      params.refresh = true;
    }
    return axios.get("/service/api/~packages-@digiforce/service-charts/data_sources/" + data.id + "/schema", {
      params: params
    }).then(function (data) {
      if (has(data, "job")) {
        return fetchDataFromJob(data.job.id)["catch"](function (error) {
          return error.code === SCHEMA_NOT_SUPPORTED ? [] : Promise.reject(new Error(data.job.error));
        });
      }
      return has(data, "schema") ? data.schema : Promise.reject();
    }).then(function (tables) {
      return map(tables, function (table) {
        return _extends({}, table, {
          columns: mapSchemaColumnsToObject(table.columns)
        });
      });
    });
  }
};
export default DataSource;