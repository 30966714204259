function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var isSQLLiteralValue = function isSQLLiteralValue(v) {
  return !!v && (v.type === 'String' || v.type === 'Number' || v.type === 'Boolean');
};
export var isSQLIdentifier = function isSQLIdentifier(v) {
  return !!v && v.type === 'Identifier';
};
export var isWildcardsOnly = function isWildcardsOnly(sqlExpr) {
  return isSQLLiteralValue(sqlExpr) && sqlExpr.type === 'String' && /^['"]?%+['"]?$/.test(sqlExpr.value);
};
export var getParamString = function getParamString(param) {
  switch (typeof param) {
    case 'number':
      return "" + param;
    case 'boolean':
      return param ? 'TRUE' : 'FALSE';
    default:
      return "'" + param + "'";
  }
};
export var getFieldName = function getFieldName(f) {
  return (typeof f === 'string' ? f : f.value).replace(/(^`|`$)/g, '');
};
var normalizeCombinator = function normalizeCombinator(c) {
  return c.replace('&&', 'and').replace('||', 'or').toLowerCase();
};
export var normalizeOperator = function normalizeOperator(op, flip) {
  if (flip) {
    if (op === '<') return '>';
    if (op === '<=') return '>=';
    if (op === '>') return '<';
    if (op === '>=') return '<=';
  }
  if (op === '<>') return '!=';
  return op;
};
export var evalSQLLiteralValue = function evalSQLLiteralValue(valueObj) {
  return valueObj.type === 'String' ? valueObj.value.replace(/^(['"]?)(.+?)\1$/, '$2') : valueObj.type === 'Boolean' ? valueObj.value.toLowerCase() === 'true' : parseFloat(valueObj.value);
};
export var generateFlatAndOrList = function generateFlatAndOrList(expr) {
  var combinator = normalizeCombinator(expr.operator);
  if (expr.left.type === 'AndExpression' || expr.left.type === 'OrExpression' || expr.left.type === 'XorExpression') {
    return [].concat(generateFlatAndOrList(expr.left), [combinator, expr.right]);
  }
  return [expr.left, combinator, expr.right];
};
export var generateMixedAndXorOrList = function generateMixedAndXorOrList(expr) {
  var arr = generateFlatAndOrList(expr);
  var currentLevel = 0;
  var orArray = {
    combinator: 'or',
    expressions: []
  };
  var xorArray = {
    combinator: 'xor',
    expressions: []
  };
  var andArray = {
    combinator: 'and',
    expressions: []
  };
  for (var i = 0; i < arr.length - 2; i += 2) {
    var levelDelta = 0;

    // istanbul ignore else
    if (arr[i + 1] === 'and') {
      levelDelta = 2 - currentLevel;
    } else if (arr[i + 1] === 'xor') {
      levelDelta = 1 - currentLevel;
    } else if (arr[i + 1] === 'or') {
      levelDelta = 0 - currentLevel;
    }
    if (levelDelta > 0) {
      for (var d = 0; d < levelDelta; d++) {
        currentLevel += 1;
        // istanbul ignore else
        if (currentLevel === 1) {
          xorArray = {
            combinator: 'xor',
            expressions: []
          };
          if (levelDelta === 1) {
            xorArray.expressions.push(arr[i]);
            if (i >= arr.length - 3 || arr[i + 3] === 'xor') {
              xorArray.expressions.push(arr[i + 2]);
            }
          }
        } else if (currentLevel === 2) {
          andArray = {
            combinator: 'and',
            expressions: []
          };
          andArray.expressions.push(arr[i], arr[i + 2]);
        }
      }
    } else if (levelDelta < 0) {
      for (var _d = 0; _d > levelDelta; _d--) {
        currentLevel -= 1;
        // istanbul ignore else
        if (currentLevel === 1) {
          xorArray.expressions.push(andArray);
          if (levelDelta === -1) {
            xorArray.expressions.push(arr[i + 2]);
          }
        } else if (currentLevel === 0) {
          orArray.expressions.push(xorArray);
          if (i >= arr.length - 3) {
            orArray.expressions.push(arr[i + 2]);
          }
        }
      }
    } else {
      // If here, then levelDelta === 0
      // istanbul ignore else
      if (currentLevel === 0) {
        if (i === 0 || i > 3 && arr[i - 3] !== 'or') {
          orArray.expressions.push(arr[i]);
        }
        if (i >= arr.length - 3 || arr[i + 3] === 'or') {
          orArray.expressions.push(arr[i + 2]);
        }
      } else if (currentLevel === 1) {
        xorArray.expressions.push(arr[i + 2]);
      } else if (currentLevel === 2) {
        andArray.expressions.push(arr[i + 2]);
      }
    }
  }

  // Close up shop
  if (currentLevel === 2) {
    xorArray.expressions.push(andArray);
    currentLevel -= 1;
  }
  if (currentLevel === 1) {
    orArray.expressions.push(xorArray);
    currentLevel -= 1;
  }

  // Collapse single-element arrays, in case there are only AND, only XOR, or only XOR/AND combinators
  if (orArray.expressions.length === 1 && 'combinator' in orArray.expressions[0]) {
    if (orArray.expressions[0].expressions.length === 1 && 'combinator' in orArray.expressions[0].expressions[0]) {
      return orArray.expressions[0].expressions[0];
    } else {
      return orArray.expressions[0];
    }
  }
  var returnArray = {
    combinator: 'or',
    expressions: []
  };

  // Collapse multi-element arrays, in case XOR level is unnecessary
  for (var _iterator = _createForOfIteratorHelperLoose(orArray.expressions), _step; !(_step = _iterator()).done;) {
    var o = _step.value;
    if ('combinator' in o) {
      // If here, then o is an XOR structure
      if ('combinator' in o.expressions[0] && o.expressions.length === 1) {
        // If here, then o.expressions[0] is an AND structure
        // that should supplant its parent XOR structure
        returnArray.expressions.push(o.expressions[0]);
      } else {
        returnArray.expressions.push(o);
      }
    } else {
      returnArray.expressions.push(o);
    }
  }
  return returnArray;
};