import { has } from "lodash";
import { useEffect, useState } from "react";
import location from "../../services/location";
export default function useFullscreenHandler() {
  var _useState = useState(has(location.search, "fullscreen")),
    fullscreen = _useState[0],
    setFullscreen = _useState[1];
  useEffect(function () {
    document.body.classList.toggle("headless", fullscreen);
    location.setSearch({
      fullscreen: fullscreen ? true : null
    }, true);
  }, [fullscreen]);
  var toggleFullscreen = function toggleFullscreen() {
    return setFullscreen(!fullscreen);
  };
  return [fullscreen, toggleFullscreen];
}