var _DigiforceFilters = DigiforceFilters,
  getBetweenTimeBuiltinValueItem = _DigiforceFilters.getBetweenTimeBuiltinValueItem;
// import moment from 'moment';

export var getObjectRecordUrl = function getObjectRecordUrl(objectName, recordId) {
  var url = "/app/-/" + objectName + "/view/" + recordId;
  return getRelativeUrl(url);
};
export var getObjectUrl = function getObjectUrl(objectName) {
  var url = "/app/-/" + objectName;
  return getRelativeUrl(url);
};
export var getAbsoluteUrl = function getAbsoluteUrl(url) {
  if (window.Meteor && !/^http(s?):\/\//.test(url)) {
    return window.Digiforce.absoluteUrl(url);
  }
  return url;
};
export var getRelativeUrl = function getRelativeUrl(url) {
  if (window.Meteor && !/^http(s?):\/\//.test(url)) {
    return window.DGPlatform.getRelativeUrl(url);
  }
  return url;
};
export var isMobile = function isMobile() {
  if (window.Digiforce && window.Digiforce.isMobile()) {
    return true;
  } else {
    return window.outerWidth < 767;
  }
};
export var getWidgetReductsConfig = function getWidgetReductsConfig() {
  return {
    "instances_pendings": {
      "label": "Pending",
      "position": "CENTER_TOP",
      "type": "object",
      "objectName": "instances",
      "filters": [[["inbox_users", "=", "{userId}"], "or", ["cc_users", "=", "{userId}"]]],
      "sort": "modified desc",
      "columns": [{
        "label": "Name",
        "field": "name",
        "href": true
      }, {
        "label": "Reporter",
        "field": "submitter_name",
        "width": "10rem"
      }, {
        "label": "Modified",
        "field": "modified",
        "type": "datetime",
        "width": "10rem"
      }],
      "noHeader": false,
      "unborderedRow": true,
      "showAllLink": false,
      "illustration": {
        "messageBody": "You have no pending documents."
      },
      rowIcon: {
        category: "standard",
        name: "task",
        size: "small"
      }
    },
    "announcements_week": {
      "label": "This week's announcements",
      "position": "CENTER_TOP",
      "type": "object",
      "objectName": "announcements",
      "filters": [[["owner", "=", "{userId}"], 'or', ["members", "=", "{userId}"]], ['created', 'between', 'last_7_days']],
      "sort": "created desc",
      "columns": [{
        "field": "name",
        "label": "Name",
        "href": true
      }, {
        "field": "created",
        "label": "Created",
        "width": "10rem",
        "type": "datetime"
      }],
      "noHeader": false,
      "unborderedRow": true,
      "showAllLink": true,
      "illustration": {
        "messageBody": "You have no pending documents in this week's announcement"
      },
      rowIcon: {
        category: "standard",
        name: "announcement",
        size: "small"
      }
    },
    "tasks_today": {
      "label": "Today's tasks",
      "position": "RIGHT",
      "type": "object",
      "objectName": "tasks",
      "filters": [["assignees", "=", "{userId}"], ["state", "<>", "complete"], ['due_date', 'between', 'last_30_days']],
      "sort": "due_date",
      "columns": [{
        "field": "name",
        "label": "Name",
        "href": true
      }],
      "unborderedRow": true,
      "showAllLink": true,
      "illustration": {
        "messageBody": "You have no tasks today."
      },
      "noHeader": true,
      rowIcon: {
        category: "standard",
        name: "timesheet_entry",
        size: "small"
      }
    },
    "events_today": {
      label: "Today's events",
      position: "RIGHT",
      type: "object",
      objectName: "events",
      filters: function filters() {
        var DGPlatform = window.DGPlatform;
        var utcOffset = DGPlatform && DGPlatform.USER_CONTEXT.user && DGPlatform.USER_CONTEXT.user.utcOffset;
        if (!utcOffset && utcOffset !== 0) {
          utcOffset = moment().utcOffset() / 60;
        }
        var today = getBetweenTimeBuiltinValueItem("today", utcOffset);
        var start = today.values[0];
        var end = today.values[1];
        return [[['owner', '=', '{userId}'], 'or', ['assignees', '=', '{userId}']], [['end', '>=', start], ['start', '<=', end]]];
      },
      sort: "start desc, end",
      columns: [{
        field: "name",
        label: "Name",
        href: true
      }],
      unborderedRow: true,
      showAllLink: true,
      illustration: {
        messageBody: "You have no events to do today."
      },
      "noHeader": true,
      rowIcon: {
        category: "standard",
        name: "event",
        size: "small"
      }
    }
  };
};