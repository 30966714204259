import moment from "moment";
import { clientConfig } from "../services/auth";
export var IntervalEnum = {
  NEVER: "Never",
  SECONDS: "second",
  MINUTES: "minute",
  HOURS: "hour",
  DAYS: "day",
  WEEKS: "week",
  MILLISECONDS: "millisecond"
};
export var AbbreviatedTimeUnits = {
  SECONDS: "s",
  MINUTES: "m",
  HOURS: "h",
  DAYS: "d",
  WEEKS: "w",
  MILLISECONDS: "ms"
};
function formatDateTimeValue(value, format) {
  if (!value) {
    return "";
  }
  var parsed = moment(value);
  if (!parsed.isValid()) {
    return "-";
  }
  return parsed.format(format);
}
export function formatDateTime(value) {
  return formatDateTimeValue(value, clientConfig.dateTimeFormat);
}
export function formatDateTimePrecise(value, withMilliseconds) {
  if (withMilliseconds === void 0) {
    withMilliseconds = false;
  }
  return formatDateTimeValue(value, clientConfig.dateFormat + (withMilliseconds ? " HH:mm:ss.SSS" : " HH:mm:ss"));
}
export function formatDate(value) {
  return formatDateTimeValue(value, clientConfig.dateFormat);
}
export function localizeTime(time) {
  var _time$split = time.split(":"),
    hrs = _time$split[0],
    mins = _time$split[1];
  return moment.utc().hour(hrs).minute(mins).local().format("HH:mm");
}
export function secondsToInterval(count) {
  if (!count) {
    return {
      interval: IntervalEnum.NEVER
    };
  }
  var interval = IntervalEnum.SECONDS;
  if (count >= 60) {
    count /= 60;
    interval = IntervalEnum.MINUTES;
  }
  if (count >= 60) {
    count /= 60;
    interval = IntervalEnum.HOURS;
  }
  if (count >= 24 && interval === IntervalEnum.HOURS) {
    count /= 24;
    interval = IntervalEnum.DAYS;
  }
  if (count >= 7 && !(count % 7) && interval === IntervalEnum.DAYS) {
    count /= 7;
    interval = IntervalEnum.WEEKS;
  }
  return {
    count: count,
    interval: interval
  };
}
export function pluralize(text, count) {
  var should = count !== 1;
  return text + (should ? "s" : "");
}
export function durationHumanize(durationInSeconds, options) {
  if (options === void 0) {
    options = {};
  }
  if (!durationInSeconds) {
    return "-";
  }
  var ret = "";
  var _secondsToInterval = secondsToInterval(durationInSeconds),
    interval = _secondsToInterval.interval,
    count = _secondsToInterval.count;
  var rounded = Math.round(count);
  if (rounded !== 1 || !options.omitSingleValueNumber) {
    ret = rounded + " ";
  }
  ret += pluralize(interval, rounded);
  return ret;
}
export function toHuman(text) {
  return text.replace(/_/g, " ").replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}
export function remove(items, item) {
  if (items === undefined) {
    return items;
  }
  var notEquals;
  if (item instanceof Array) {
    notEquals = function notEquals(other) {
      return item.indexOf(other) === -1;
    };
  } else {
    notEquals = function notEquals(other) {
      return item !== other;
    };
  }
  var filtered = [];
  for (var i = 0; i < items.length; i += 1) {
    if (notEquals(items[i])) {
      filtered.push(items[i]);
    }
  }
  return filtered;
}

/**
 * Formats number to string
 * @param value {number}
 * @param [fractionDigits] {number}
 * @return {string}
 */
export function formatNumber(value, fractionDigits) {
  if (fractionDigits === void 0) {
    fractionDigits = 3;
  }
  return Math.round(value) !== value ? value.toFixed(fractionDigits) : value.toString();
}

/**
 * Formats any number using predefined units
 * @param value {string|number}
 * @param divisor {number}
 * @param [units] {Array<string>}
 * @param [fractionDigits] {number}
 * @return {{unit: string, value: string, divisor: number}}
 */
export function prettyNumberWithUnit(value, divisor, units, fractionDigits) {
  if (units === void 0) {
    units = [];
  }
  if (isNaN(parseFloat(value)) || !isFinite(value)) {
    return {
      value: "",
      unit: "",
      divisor: 1
    };
  }
  var unit = 0;
  var greatestDivisor = 1;
  while (value >= divisor && unit < units.length - 1) {
    value /= divisor;
    greatestDivisor *= divisor;
    unit += 1;
  }
  return {
    value: formatNumber(value, fractionDigits),
    unit: units[unit],
    divisor: greatestDivisor
  };
}
export function prettySizeWithUnit(bytes, fractionDigits) {
  return prettyNumberWithUnit(bytes, 1024, ["bytes", "KB", "MB", "GB", "TB", "PB"], fractionDigits);
}
export function prettySize(bytes) {
  var _prettySizeWithUnit = prettySizeWithUnit(bytes),
    value = _prettySizeWithUnit.value,
    unit = _prettySizeWithUnit.unit;
  if (!value) {
    return "?";
  }
  return value + " " + unit;
}
export function join(arr) {
  if (arr === undefined || arr === null) {
    return "";
  }
  return arr.join(" / ");
}
export function formatColumnValue(value, columnType) {
  if (columnType === void 0) {
    columnType = null;
  }
  if (moment.isMoment(value)) {
    if (columnType === "date") {
      return formatDate(value);
    }
    return formatDateTime(value);
  }
  if (typeof value === "boolean") {
    return value.toString();
  }
  return value;
}