import { produce } from 'immer';
import { generateID } from '../internal/generateID';

/**
 * Generates a valid rule
 */
export var prepareRule = function prepareRule(rule) {
  return produce(rule, function (draft) {
    if (!draft.id) {
      draft.id = "r-" + generateID();
    }
  });
};

/**
 * Generates a valid rule group
 */
export var prepareRuleGroup = function prepareRuleGroup(queryObject) {
  return produce(queryObject, function (draft) {
    if (!draft.id) {
      draft.id = "g-" + generateID();
    }
    draft.rules = draft.rules.map(function (r) {
      return typeof r === 'string' ? r : 'rules' in r ? prepareRuleGroup(r) : prepareRule(r);
    });
  });
};

/**
 * Generates a valid rule or group
 */
export var prepareRuleOrGroup = function prepareRuleOrGroup(rg) {
  return 'rules' in rg ? prepareRuleGroup(rg) : prepareRule(rg);
};