import { createIntl } from '@digiforce/dvd-editor-core';
import en_US from './en-US.json';
import vi_VN from './vi-VN.json';
var _createIntl = createIntl({
    'en-US': en_US,
    'vi-VN': vi_VN
  }),
  intl = _createIntl.intl,
  intlNode = _createIntl.intlNode,
  getLocale = _createIntl.getLocale,
  setLocale = _createIntl.setLocale;
export { intl, intlNode, getLocale, setLocale };