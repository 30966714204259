import IntlMessageFormat from 'intl-messageformat';
export var isJSExpression = function isJSExpression(obj) {
  if (obj === void 0) {
    obj = '';
  }
  if (obj && typeof obj === 'object' && obj.type === 'JSExpression') {
    return true;
  }
  return false;
};

/**
 * Used to construct internationalized string processing functions
 * @param {*} locale internationalization identifier, such as en-US
 * @param {*} messages internationalization language pack
 */
export var generateI18n = function generateI18n(locale, messages) {
  if (locale === void 0) {
    locale = 'en-US';
  }
  if (messages === void 0) {
    messages = {};
  }
  return function (key, values) {
    if (values === void 0) {
      values = {};
    }
    if (!messages || !messages[key]) return '';
    var formater = new IntlMessageFormat(messages[key], locale);
    return formater.format(values);
  };
};