import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Favorites from './favorites';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps), {
      objectName: "favorites",
      columns: [{
        field: "name"
      }, {
        field: "object_name"
      }, {
        field: "record_type"
      }, {
        field: "record_id"
      }],
      sort: "sort_no desc"
    });
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    // init: (options: any) => dispatch(loadFavoritesEntitiesData(options))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Favorites);