import { merge } from "lodash";
import Renderer from "./Renderer";
import Editor from "./Editor";
var DEFAULT_OPTIONS = {
  controls: {
    enabled: false // `false` means "show controls" o_O
  },

  rendererOptions: {
    table: {
      colTotals: true,
      rowTotals: true
    }
  }
};
export default {
  type: "PIVOT",
  name: "Pivot Table",
  getOptions: function getOptions(options) {
    return merge({}, DEFAULT_OPTIONS, options);
  },
  Renderer: Renderer,
  Editor: Editor,
  defaultRows: 10,
  defaultColumns: 3,
  minColumns: 2
};