export function pluginInstanceSelector(state, name) {
  var instances = state.plugins ? state.plugins.instances : {};
  return instances[name];
}
export function pluginComponentsSelector(state, name) {
  var components = state.plugins ? state.plugins.components : {};
  if (components) {
    return components[name] ? components[name] : [];
  } else {
    return [];
  }
}
export function pluginComponentObjectSelector(state, name, id) {
  var components = pluginComponentsSelector(state, name);
  return components.find(function (n) {
    return n.id === id;
  });
}
export function pluginComponentSelector(state, name, id) {
  var componentObject = pluginComponentObjectSelector(state, name, id);
  return componentObject ? componentObject.component : null;
}