import { isString, isObject, get } from "lodash";
import { useState, useEffect } from "react";
import axios from "axios";
import { visualizationsSettings } from "../../visualizationsSettings";
import createReferenceCountingCache from "../../../lib/referenceCountingCache";
var cache = createReferenceCountingCache();
export default function useLoadGeoJson(mapType) {
  var _useState = useState(null),
    geoJson = _useState[0],
    setGeoJson = _useState[1];
  var _useState2 = useState(false),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  useEffect(function () {
    var mapUrl = get(visualizationsSettings, "choroplethAvailableMaps." + mapType + ".url", undefined);
    if (isString(mapUrl)) {
      setIsLoading(true);
      var cancelled = false;
      var promise = cache.get(mapUrl, function () {
        return axios.get(mapUrl)["catch"](function () {
          return null;
        });
      });
      promise.then(function (_ref) {
        var data = _ref.data;
        if (!cancelled) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'object | null' is not assignable... Remove this comment to see the full error message
          setGeoJson(isObject(data) ? data : null);
          setIsLoading(false);
        }
      });
      return function () {
        cancelled = true;
        cache.release(mapUrl);
      };
    } else {
      setGeoJson(null);
      setIsLoading(false);
    }
  }, [mapType]);
  return [geoJson, isLoading];
}