export function srNotify(_ref) {
  var text = _ref.text,
    _ref$expiry = _ref.expiry,
    expiry = _ref$expiry === void 0 ? 1000 : _ref$expiry,
    _ref$container = _ref.container,
    container = _ref$container === void 0 ? document.body : _ref$container,
    _ref$politeness = _ref.politeness,
    politeness = _ref$politeness === void 0 ? "polite" : _ref$politeness;
  var element = document.createElement("div");
  var id = "speak-" + Date.now();
  element.id = id;
  element.className = "sr-only";
  element.textContent = text;
  element.setAttribute("role", "alert");
  element.setAttribute("aria-live", politeness);
  container.appendChild(element);
  var timer = null;
  var isDone = false;
  var cleanupFn = function cleanupFn() {
    if (isDone) {
      return;
    }
    isDone = true;
    try {
      container.removeChild(element);
    } catch (e) {
      console.error(e);
    }
    if (timer) {
      window.clearTimeout(timer);
    }
  };
  timer = window.setTimeout(cleanupFn, expiry);
  return cleanupFn;
}