import { loadEntitiesDataRequest } from '../records_request';
import { dataServicesSelector } from '../../selectors';
import { createAction as baseCreateAction } from '../base';
export var CATEGORIES_STATE_CHANGE_ACTION = 'CATEGORIES_STATE_CHANGE';
export var createCategoriesAction = function createCategoriesAction(partialStateName, partialStateValue, options) {
  return baseCreateAction(CATEGORIES_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
};
export function loadCategoriesEntitiesData(options) {
  if (options === void 0) {
    options = {};
  }
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, CATEGORIES_STATE_CHANGE_ACTION, service, options);
  };
}