import _extends from "@babel/runtime/helpers/extends";
import { generateID } from './generateID';
export var regenerateID = function regenerateID(rule) {
  return JSON.parse(JSON.stringify(_extends({}, rule, {
    id: "r-" + generateID()
  })));
};
export var regenerateIDs = function regenerateIDs(ruleGroup) {
  if ('combinator' in ruleGroup) {
    var combinator = ruleGroup.combinator,
      _not = ruleGroup.not;
    var _rules = ruleGroup.rules.map(function (r) {
      return 'rules' in r ? regenerateIDs(r) : regenerateID(r);
    });
    return {
      id: "g-" + generateID(),
      combinator: combinator,
      rules: _rules,
      not: _not
    };
  }
  var not = ruleGroup.not;
  var rules = ruleGroup.rules.map(function (r) {
    return typeof r === 'string' ? r : 'rules' in r ? regenerateIDs(r) : regenerateID(r);
  });
  return {
    id: "g-" + generateID(),
    rules: rules,
    not: not
  };
};