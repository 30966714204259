import { PLUGIN_INSTANCE_RECEIVED_ACTION, PLUGIN_COMPONENT_RECEIVED_ACTION } from '../actions/plugin';
function updateState(oldState, newState) {
  return Object.assign({}, oldState, newState);
}
function transformInstanceState(oldState, newState) {
  var _Object$assign;
  var result = oldState.instances ? oldState.instances : {};
  result = Object.assign({}, result, (_Object$assign = {}, _Object$assign[newState.name] = newState.instance, _Object$assign));
  return updateState(oldState, {
    "instances": result
  });
}
function transformObjectHomeComponentState(oldState, newState) {
  var result = oldState.components ? oldState.components : {};
  var resultName = result[newState.name] ? [].concat(result[newState.name]) : [];
  if (resultName.find(function (n) {
    return newState.data && n.id === newState.data.id;
  })) {
    console.warn("The same plugin component " + newState.data.id + " is already exists,you need to check your repeated component id");
  }
  resultName.push(newState.data);
  result[newState.name] = resultName;
  return updateState(oldState, {
    "components": result
  });
}
function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === PLUGIN_INSTANCE_RECEIVED_ACTION) {
    var _Object$assign2;
    var payload = action.payload;
    switch (payload.partialStateName) {
      case "received":
        return transformInstanceState(state, payload.partialStateValue);
      default:
        break;
    }
    return Object.assign({}, state, (_Object$assign2 = {}, _Object$assign2[payload.partialStateName] = payload.partialStateValue, _Object$assign2));
  } else if (action.type === PLUGIN_COMPONENT_RECEIVED_ACTION) {
    var _Object$assign3;
    var _payload = action.payload;
    switch (_payload.partialStateName) {
      case "received":
        return transformObjectHomeComponentState(state, _payload.partialStateValue);
      default:
        break;
    }
    return Object.assign({}, state, (_Object$assign3 = {}, _Object$assign3[_payload.partialStateName] = _payload.partialStateValue, _Object$assign3));
  }
  return state;
}
;
export default reducer;