import { map } from "lodash";
import { useCallback } from "react";
import EditParameterSettingsDialog from "../../../components/EditParameterSettingsDialog";
import useImmutableCallback from "../../../lib/hooks/useImmutableCallback";
export default function useAddNewParameterDialog(query, onParameterAdded) {
  var handleParameterAdded = useImmutableCallback(onParameterAdded);
  return useCallback(function () {
    EditParameterSettingsDialog.showModal({
      parameter: {
        title: null,
        name: "",
        type: "text",
        value: null
      },
      existingParams: map(query.getParameters().get(), function (p) {
        return p.name;
      })
    }).onClose(function (param) {
      var newQuery = query.clone();
      param = newQuery.getParameters().add(param);
      handleParameterAdded(newQuery, param);
    });
  }, [query, handleParameterAdded]);
}