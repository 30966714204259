import { isFormEvent, compatibleLegaoSchema, getNodeSchemaById } from '@digiforce/dvd-utils';
import { isNodeSchema } from '@digiforce/dvd-types';
import { getConvertedExtraKey, getOriginalExtraKey } from '@digiforce/dvd-designer';
var utils = {
  isNodeSchema: isNodeSchema,
  isFormEvent: isFormEvent,
  compatibleLegaoSchema: compatibleLegaoSchema,
  getNodeSchemaById: getNodeSchemaById,
  getConvertedExtraKey: getConvertedExtraKey,
  getOriginalExtraKey: getOriginalExtraKey
};
export default utils;