import { connect } from 'react-redux';
import Filters from './filters';
import { makeNewID } from '../index';
import { createGridAction } from '../../actions';

// function makeMapStateToProps() {
//     return (state: any, ownProps: any) => {
//         let profileState = {profile: getProfile(state) || {}}
//         return Object.assign({}, profileState, {...profileState, ...ownProps});
//     };
// }

function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    return Object.assign({}, ownProps);
    ;
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSelect: function onSelect(partialStateName, partialStateValue, options) {
      return dispatch(createGridAction(partialStateName, partialStateValue, options));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Filters);