import { connect } from 'react-redux';
import { createTreeAction, removeViewAction as _removeViewAction } from '../../actions';
import DigiforceTree from './salesforce_tree';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return entityState;
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  var props = {
    removeViewAction: function removeViewAction(viewId) {
      return dispatch(_removeViewAction(viewId));
    },
    changeNode: function changeNode(partialStateValue, options) {
      return dispatch(createTreeAction("changeNode", partialStateValue, options));
    },
    changeNodes: function changeNodes(partialStateValue, options) {
      return dispatch(createTreeAction("changeNodes", partialStateValue, options));
    }
  };
  if (ownProps.init) {
    props.init = function (options) {
      return dispatch(ownProps.init(options));
    };
  }
  if (ownProps.onClick) {
    props.onExpandClick = function (event, data) {
      return dispatch(createTreeAction('expandClick', data, ownProps));
    };
    props.onClick = function (event, data) {
      return dispatch(ownProps.onClick(event, data));
    };
  }
  return props;
};
export default connect(mapStateToProps, mapDispatchToProps)(DigiforceTree);