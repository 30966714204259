var globalEventOn = true;
export function setGlobalEventFlag(flag) {
  globalEventOn = flag;
}
export function switchGlobalEventOn() {
  setGlobalEventFlag(true);
}
export function switchGlobalEventOff() {
  setGlobalEventFlag(false);
}
export function isGlobalEventOn() {
  return globalEventOn;
}
export function runWithGlobalEventOff(fn) {
  switchGlobalEventOff();
  fn();
  switchGlobalEventOn();
}
export function wrapWithEventSwitch(fn) {
  return function () {
    if (isGlobalEventOn()) fn.apply(void 0, arguments);
  };
}