import { useState, useEffect, useRef } from "react";
import { useDebouncedCallback } from "use-debounce";
export default function useSearchResults(fetch, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
    _ref$initialResults = _ref.initialResults,
    initialResults = _ref$initialResults === void 0 ? null : _ref$initialResults,
    _ref$debounceTimeout = _ref.debounceTimeout,
    debounceTimeout = _ref$debounceTimeout === void 0 ? 200 : _ref$debounceTimeout;
  var _useState = useState(initialResults),
    result = _useState[0],
    setResult = _useState[1];
  var _useState2 = useState(false),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  var currentSearchTerm = useRef(null);
  var isDestroyed = useRef(false);
  var _useDebouncedCallback = useDebouncedCallback(function (searchTerm) {
      setIsLoading(true);
      currentSearchTerm.current = searchTerm;
      fetch(searchTerm)["catch"](function () {
        return initialResults;
      }).then(function (data) {
        if (searchTerm === currentSearchTerm.current && !isDestroyed.current) {
          setResult(data);
          setIsLoading(false);
        }
      });
    }, debounceTimeout),
    doSearch = _useDebouncedCallback[0];
  useEffect(function () {
    return (
      // ignore all requests after component destruction
      function () {
        isDestroyed.current = true;
      }
    );
  }, []);
  return [doSearch, result, isLoading];
}