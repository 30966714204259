var PREFIX = "localOptions:";
function get(key, defaultValue) {
  if (defaultValue === void 0) {
    defaultValue = undefined;
  }
  var fullKey = PREFIX + key;
  if (fullKey in window.localStorage) {
    return JSON.parse(window.localStorage.getItem(fullKey));
  }
  return defaultValue;
}
function set(key, value) {
  var fullKey = PREFIX + key;
  window.localStorage.setItem(fullKey, JSON.stringify(value));
}
export default {
  get: get,
  set: set
};