// Example locale file for English, give this to your locale team to translate

export var AG_GRID_LOCALE_VI_VN = {
  // Set Filter
  selectAll: '(Chọn tất cả)',
  selectAllSearchResults: '(Chọn tất cả kết quả tìm kiếm)',
  searchOoo: 'Tìm kiếm...',
  blanks: '(Trống)',
  noMatches: 'Không có dòng nào phù hợp',
  // Number Filter & Text Filter
  filterOoo: 'Lọc...',
  equals: 'Bằng',
  notEqual: 'Không bằng',
  empty: 'Rỗng',
  // Number Filter
  lessThan: 'Nhỏ hơn',
  greaterThan: 'Lớn hơn',
  lessThanOrEqual: 'Nhỏ hơn hoặc bằng',
  greaterThanOrEqual: 'Lớn hơn hoặc bằng',
  inRange: 'Phạm vi',
  inRangeStart: 'Từ',
  inRangeEnd: 'Đến',
  // Text Filter
  contains: 'Chứa',
  notContains: 'Không chứa',
  startsWith: 'Bắt đầu với',
  endsWith: 'Kết thúc với',
  // Date Filter
  dateFormatOoo: 'dd-mm-yyyy',
  // Filter Conditions
  andCondition: 'VÀ',
  orCondition: 'HOẶC',
  // Filter Buttons
  applyFilter: 'Lọc',
  resetFilter: 'Đặt lại',
  clearFilter: 'Làm trống',
  cancelFilter: 'Hủy bỏ',
  // Filter Titles
  textFilter: 'Bộ lọc văn bản',
  numberFilter: 'Bộ lọc số',
  dateFilter: 'Bộ lọc ngày tháng',
  setFilter: 'Bộ lọc theo nhóm',
  // Side Bar
  columns: 'Cột',
  filters: 'Lọc',
  // columns tool panel
  pivotMode: 'Chế độ bảng tổng hợp (pivot)',
  groups: 'Các nhóm',
  rowGroupColumnsEmptyMessage: 'Kéo và thả các cột tại đây để thiết lập nhóm',
  values: 'Giá trị',
  valueColumnsEmptyMessage: 'Kéo và thả cột tại đây để tổng hợp giá trị theo nhóm',
  pivots: 'Tiêu đề cột',
  pivotColumnsEmptyMessage: 'Kéo và thả vào đây để đặt nhãn cột',
  // Header of the Default Group Column
  group: 'Nhóm',
  // Other
  loadingOoo: 'Đang tải...',
  noRowsToShow: 'Không có dữ liệu nào để hiển thị',
  enabled: 'Kích hoạt',
  // Menu
  pinColumn: 'Cột cố định',
  pinLeft: 'Ghim bên trái',
  pinRight: 'Ghim bên phải',
  noPin: 'Không cố định',
  valueAggregation: 'Tổng hợp giá trị',
  autosizeThiscolumn: 'Tự động điều chỉnh cột hiện tại',
  autosizeAllColumns: 'Tự động điều chỉnh Tất cả các cột',
  groupBy: 'Nhóm theo: ',
  ungroupBy: 'Không nhóm: ',
  resetColumns: 'Đặt lại cột',
  expandAll: 'Mở rộng tất cả',
  collapseAll: 'Đóng tất cả',
  copy: 'Sao chép',
  ctrlC: 'Ctrl+C',
  copyWithHeaders: 'Sao chép (cả tiêu đề)',
  paste: 'Dán',
  ctrlV: 'Ctrl+V',
  "export": 'Xuất dữ liệu',
  csvExport: 'Xuất dữ liệu CSV',
  excelExport: 'Xuất dữ liệu Excel',
  // Enterprise Menu Aggregation and Status Bar
  sum: 'Tổng (sum)',
  min: 'Nhỏ nhất (min)',
  max: 'Lớn nhất (max)',
  none: 'Không có',
  count: 'Đếm (count)',
  avg: 'Trung bình (avg)',
  filteredRows: 'Đã lọc',
  selectedRows: 'Đã chọn',
  totalRows: 'Tổng số dòng',
  totalAndFilteredRows: 'Số dòng',
  more: 'Thêm',
  to: 'đến',
  of: ' trong ',
  page: 'Trang',
  nextPage: 'Trang sau',
  lastPage: 'Trang cuối',
  firstPage: 'Trang đầu',
  previousPage: 'Trang trước',
  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Chế độ bảng tổng hợp (pivot)',
  pivotChart: 'Biểu đồ và bảng tổng hợp',
  chartRange: 'Biểu đồ tổng hợp (pivot chart)',
  columnChart: 'Cột',
  groupedColumn: 'Phân nhóm',
  stackedColumn: 'Xếp chồng',
  normalizedColumn: 'Xếp chồng (chuẩn hóa về 100%)',
  barChart: 'Biểu đồ thanh ngang (bar)',
  groupedBar: 'Phân nhóm',
  stackedBar: 'Xếp chồng',
  normalizedBar: 'Theo tỷ lệ 100%',
  pieChart: 'Biểu đồ Pie',
  pie: 'Hình tròn',
  doughnut: 'Vòng tròn',
  line: 'Đường',
  xyChart: 'Biểu đồ phân tán',
  scatter: 'Phân tán',
  bubble: 'Biểu đồ bong bóng',
  areaChart: 'Biểu đồ vùng',
  area: 'Vùng',
  stackedArea: 'Xếp chồng',
  normalizedArea: 'Xếp chồng (chuẩn hóa về 100%)',
  histogramChart: 'Biểu đồ tần suất',
  // Charts
  pivotChartTitle: 'Tiêu đề bảng tổng hợp',
  rangeChartTitle: 'Biểu đồ phạm vi',
  settings: 'Cài đặt',
  data: 'Dữ liệu',
  format: 'Định dạng',
  categories: 'Phân loại',
  defaultCategory: '(Không có)',
  series: 'Dãy',
  xyValues: 'Giá trị X Y',
  paired: 'Chế độ cặp đôi (paired)',
  axis: 'Trục',
  navigator: 'Điều hướng',
  color: 'Màu sắc',
  thickness: 'Độ dày',
  xType: 'Loại X',
  automatic: 'Tự động',
  category: 'Phân loại',
  number: 'Số',
  time: 'Thời gian',
  xRotation: 'Xoay X',
  yRotation: 'Xoay Y',
  ticks: 'Tỷ lệ',
  width: 'Rộng',
  height: 'Cao',
  length: 'Độ dài',
  padding: 'Lề trong',
  spacing: 'Lề',
  chart: 'Biểu đồ',
  title: 'Tiêu đề',
  titlePlaceholder: 'Nhấp đúp vào đây để chỉnh sửa biểu đồ',
  background: 'Nền',
  font: 'Font chữ',
  top: 'Trên',
  right: 'Phải',
  bottom: 'Dưới',
  left: 'Trái',
  labels: 'Nhãn',
  size: 'Kích thước',
  minSize: 'Kích thước tối thiểu',
  maxSize: 'Kích thước tối đa',
  legend: 'Chú giải biểu đồ ',
  position: 'Vị trí',
  markerSize: 'Kích thước Điểm nối',
  markerStroke: 'Độ nét của Điểm nối',
  markerPadding: 'Lề của Điểm nối',
  itemSpacing: 'Khoảng cách dòng',
  itemPaddingX: 'Lề trong của cột X',
  itemPaddingY: 'Lề trong của cột Y',
  layoutHorizontalSpacing: 'Khoảng cách ngang',
  layoutVerticalSpacing: 'Khoảng cách dọc',
  strokeWidth: 'Độ nét',
  offset: 'Offset',
  offsets: 'Offsets',
  tooltips: 'Gợi ý',
  callout: 'Chú thích',
  markers: 'Các Điểm',
  shadow: 'Bóng',
  blur: 'Mờ',
  xOffset: 'Offset X',
  yOffset: 'Offset Y',
  lineWidth: 'Chiều rộng đường kẻ',
  normal: 'Chữ thường',
  bold: 'Chữ in đậm',
  italic: 'Chữ in nghiêng',
  boldItalic: 'Chữ in đậm nghiêng',
  predefined: 'Định nghĩa trước',
  fillOpacity: 'Độ trong suốt vùng tô vẽ',
  strokeOpacity: 'Độ trong suốt của nét vẽ',
  histogramBinCount: 'Số bin tần suất',
  columnGroup: 'Cột',
  barGroup: 'Trục',
  pieGroup: 'Tròn',
  lineGroup: 'Đường',
  scatterGroup: 'Phân tán X Y',
  areaGroup: 'Vùng',
  histogramGroup: 'Tần suất',
  groupedColumnTooltip: 'Nhóm',
  stackedColumnTooltip: 'Xếp chồng',
  normalizedColumnTooltip: 'Xếp chồng (chuẩn hóa về 100%)',
  groupedBarTooltip: 'Nhóm',
  stackedBarTooltip: 'Xếp chồng',
  normalizedBarTooltip: 'Xếp chồng (chuẩn hóa về 100%)',
  pieTooltip: 'Hình tròn',
  doughnutTooltip: 'Vòng tròn',
  lineTooltip: 'Đường',
  groupedAreaTooltip: 'Khu vực',
  stackedAreaTooltip: 'Ngăn xếp',
  normalizedAreaTooltip: 'Xếp chồng (chuẩn hóa về 100%)',
  scatterTooltip: 'Biểu đồ Scatter',
  bubbleTooltip: 'Biểu đồ Bubble',
  histogramTooltip: 'Biểu đồ tần suất',
  noDataToChart: 'Không có dữ liệu để vẽ biểu đồ',
  pivotChartRequiresPivotMode: 'Cần chuyển sang chế độ xem bảng tổng hợp',
  chartSettingsToolbarTooltip: 'Menu',
  chartLinkToolbarTooltip: 'Đã liên kết với lưới',
  chartUnlinkToolbarTooltip: 'Bỏ liên kết với lưới',
  chartDownloadToolbarTooltip: 'Tải biểu đồ',
  // ARIA
  ariaHidden: 'Ẩn',
  ariaVisible: 'Hiển thị',
  ariaChecked: 'Chọn',
  ariaUnchecked: 'Bỏ chọn',
  ariaIndeterminate: 'không xác định',
  ariaColumnSelectAll: 'Chuyển đổi Chọn tất cả các cột',
  ariaInputEditor: 'Trình chỉnh sửa đầu vào',
  ariaDateFilterInput: 'Đầu vào bộ lọc ngày',
  ariaFilterInput: 'Đầu vào bộ lọc',
  ariaFilterColumnsInput: 'Các cột đầu vào bộ lọc',
  ariaFilterValue: 'Giá trị bộ lọc',
  ariaFilterFromValue: 'Lọc từ giá trị',
  ariaFilterToValue: 'Lọc đến giá trị',
  ariaFilteringOperator: 'Phép toán lọc',
  ariaColumnToggleVisibility: 'Chuyển đổi hiển thị',
  ariaColumnGroupToggleVisibility: 'Nhóm cột chuyển đổi chế độ hiển thị',
  ariaRowSelect: 'Nhấn phím cách để chọn hàng này',
  ariaRowDeselect: 'Nhấn phím cách để bỏ chọn hàng này',
  ariaRowToggleSelection: 'Nhấn Dấu cách để chuyển đổi lựa chọn hàng',
  ariaRowSelectAll: 'Nhấn Dấu cách để chuyển đổi tất cả các lựa chọn hàng',
  ariaSearch: 'Tìm kiếm',
  ariaSearchFilterValues: 'Tìm kiếm giá trị bộ lọc'
};