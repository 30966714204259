import { isArray, findKey } from "lodash";
import tinycolor from "tinycolor2";
export function validateColor(value, fallback) {
  if (fallback === void 0) {
    fallback = null;
  }
  value = tinycolor(value);
  return value.isValid() ? "#" + value.toHex().toUpperCase() : fallback;
}
export function getColorName(color, presetColors) {
  if (isArray(presetColors)) {
    return color;
  }
  return findKey(presetColors, function (v) {
    return validateColor(v) === color;
  }) || color;
}