import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/extends";
var _excluded = ["combinator"];
import { isRuleGroupTypeIC } from './isRuleGroup';
var processRuleOrStringOrRuleGroupIC = function processRuleOrStringOrRuleGroupIC(r) {
  return typeof r === 'object' && 'rules' in r ? generateRuleGroupICWithConsistentCombinators(r) : r;
};
var generateRuleGroupICWithConsistentCombinators = function generateRuleGroupICWithConsistentCombinators(rg) {
  var returnArray = [];
  var push = function push(r) {
    return returnArray.push(processRuleOrStringOrRuleGroupIC(r));
  };
  var startIndex = 0;
  for (var i = 0; i < rg.rules.length; i += 2) {
    if (rg.rules.length === 1) {
      push(rg.rules[0]);
    } else if (rg.rules[i + 1] === 'and') {
      startIndex = i;
      var j = 1;
      while (rg.rules[startIndex + j] === 'and') {
        i += 2;
        j += 2;
      }
      returnArray.push({
        // TODO: @ts-expect-error once we don't support TS@<4.5
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS can't keep track of odd/even indexes here
        rules: rg.rules.slice(startIndex, i + 1).map(processRuleOrStringOrRuleGroupIC)
      });
      i -= 2;
    } else if (rg.rules[i + 1] === 'or') {
      if (i === 0 || i === rg.rules.length - 3) {
        if (i === 0 || rg.rules[i - 1] === 'or') {
          push(rg.rules[i]);
        }
        push(rg.rules[i + 1]);
        if (i === rg.rules.length - 3) {
          push(rg.rules[i + 2]);
        }
      } else {
        if (rg.rules[i - 1] === 'and') {
          push(rg.rules[i + 1]);
        } else {
          push(rg.rules[i]);
          push(rg.rules[i + 1]);
        }
      }
    }
  }
  if (
  // TODO: @ts-expect-error once we don't support TS@<4.5
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore TS still thinks returnArray has length 0
  returnArray.length === 1 && typeof returnArray[0] === 'object' && 'rules' in returnArray[0]) {
    // TODO: @ts-expect-error once we don't support TS@<4.5
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS still thinks returnArray has length 0
    return _extends({}, rg, returnArray[0]);
  }
  return _extends({}, rg, {
    rules: returnArray
  });
};
export var convertFromIC = function convertFromIC(rg) {
  var processedRG = generateRuleGroupICWithConsistentCombinators(rg);
  var rulesAsMixedList = processedRG.rules.map(function (r) {
    return typeof r === 'string' || !('rules' in r) ? r : convertFromIC(r);
  });
  var combinator = rulesAsMixedList.length < 2 ? 'and' : rulesAsMixedList[1];
  var rules = rulesAsMixedList.filter(function (r) {
    return typeof r !== 'string';
  });
  return _extends({}, processedRG, {
    combinator: combinator,
    rules: rules
  });
};
export var convertToIC = function convertToIC(rg) {
  var combinator = rg.combinator,
    queryWithoutCombinator = _objectWithoutPropertiesLoose(rg, _excluded);
  var rules = [];
  rg.rules.forEach(function (r, idx, arr) {
    if ('rules' in r) {
      rules.push(convertToIC(r));
    } else {
      rules.push(r);
    }
    if (idx < arr.length - 1) {
      rules.push(combinator);
    }
  });
  return _extends({}, queryWithoutCombinator, {
    rules: rules
  });
};
function convertQuery(query) {
  return isRuleGroupTypeIC(query) ? convertFromIC(query) : convertToIC(query);
}
export { convertQuery };