import { defaultRuleProcessorCEL } from './defaultRuleProcessorCEL';
import { defaultRuleProcessorMongoDB } from './defaultRuleProcessorMongoDB';
import { defaultRuleProcessorSpEL } from './defaultRuleProcessorSpEL';
import { defaultValueProcessorByRule } from './defaultValueProcessorByRule';
var internalValueProcessors = {
  "default": defaultValueProcessorByRule,
  mongodb: defaultRuleProcessorMongoDB,
  cel: defaultRuleProcessorCEL,
  spel: defaultRuleProcessorSpEL
};
var generateValueProcessor = function generateValueProcessor(format) {
  return function (field, operator, value, valueSource) {
    return internalValueProcessors[format]({
      field: field,
      operator: operator,
      value: value,
      valueSource: valueSource
    }, {
      parseNumbers: false
    });
  };
};
export var defaultValueProcessor = generateValueProcessor('default');
/**
 * @deprecated Prefer `defaultRuleProcessorMongoDB`.
 */
export var defaultMongoDBValueProcessor = generateValueProcessor('mongodb');
/**
 * @deprecated Prefer `defaultRuleProcessorCEL`.
 */
export var defaultCELValueProcessor = generateValueProcessor('cel');
/**
 * @deprecated Prefer `defaultRuleProcessorSpEL`.
 */
export var defaultSpELValueProcessor = generateValueProcessor('spel');
export { defaultRuleProcessorJsonLogic } from './defaultRuleProcessorJsonLogic';
export * from './formatQuery';
export { defaultValueProcessorByRule };
export { defaultRuleProcessorCEL };
export { defaultRuleProcessorMongoDB };
export { defaultRuleProcessorSpEL };
/**
 * @deprecated Renamed to "defaultRuleProcessorMongoDB".
 */
export var defaultValueProcessorCELByRule = defaultRuleProcessorCEL;
/**
 * @deprecated Renamed to "defaultRuleProcessorCEL".
 */
export var defaultValueProcessorMongoDBByRule = defaultRuleProcessorMongoDB;
/**
 * @deprecated Renamed to "defaultRuleProcessorSpEL".
 */
export var defaultValueProcessorSpELByRule = defaultRuleProcessorSpEL;