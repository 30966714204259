import { DXGRID_STATE_CHANGE_ACTION } from '../../actions/views/dx_grid';
function transformEntityState(state, payload) {
  return Object.assign({}, state, {
    rows: payload.partialStateValue.records,
    totalCount: payload.partialStateValue.totalCount
  });
}
function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === DXGRID_STATE_CHANGE_ACTION) {
    var _Object$assign;
    var payload = action.payload;
    switch (payload.partialStateName) {
      case 'loadDataSauce':
        return transformEntityState(state, payload);
      default:
        break;
    }
    return Object.assign({}, state, (_Object$assign = {}, _Object$assign[payload.partialStateName] = payload.partialStateValue, _Object$assign));
  }
  return state;
}
export default reducer;