import { useEffect } from 'react';
import { errorBothQueryDefaultQuery, errorControlledToUncontrolled, errorUncontrolledToControlled } from '../../messages';
import { usePrevious } from './usePrevious';
var didWarnBothQueryDefaultQuery = false;
var didWarnUncontrolledToControlled = false;
var didWarnControlledToUncontrolled = false;

/**
 * Log errors when the component changes from controlled to uncontrolled,
 * vice versa, or both query and defaultQuery are provided.
 */
export var useControlledOrUncontrolled = function useControlledOrUncontrolled(_ref) {
  var defaultQuery = _ref.defaultQuery,
    queryProp = _ref.queryProp,
    isFirstRender = _ref.isFirstRender;
  var prevQueryPresent = usePrevious(!!queryProp);
  useEffect(function () {
    // istanbul ignore else
    if (__RQB_DEV__) {
      if (!!queryProp && !!defaultQuery && !didWarnBothQueryDefaultQuery) {
        console.error(errorBothQueryDefaultQuery);
        didWarnBothQueryDefaultQuery = true;
      } else if (prevQueryPresent && !queryProp && !!defaultQuery && !didWarnControlledToUncontrolled) {
        console.error(errorControlledToUncontrolled);
        didWarnControlledToUncontrolled = true;
      } else if (!(prevQueryPresent || isFirstRender) && !!queryProp && !defaultQuery && !didWarnUncontrolledToControlled) {
        console.error(errorUncontrolledToControlled);
        didWarnUncontrolledToControlled = true;
      }
    }
  }, [defaultQuery, prevQueryPresent, queryProp, isFirstRender]);
};