import { isEmpty } from "lodash";
import { useState, useMemo } from "react";
import useUpdateQuery from "./useUpdateQuery";
// import navigateTo from "../../../components/ApplicationArea/navigateTo";

export default function useQuery(originalQuery) {
  var _useState = useState(originalQuery),
    query = _useState[0],
    setQuery = _useState[1];
  var _useState2 = useState(originalQuery.query),
    originalQuerySource = _useState2[0],
    setOriginalQuerySource = _useState2[1];
  var _useState3 = useState(query.options.apply_auto_limit),
    originalAutoLimit = _useState3[0],
    setOriginalAutoLimit = _useState3[1];
  var updateQuery = useUpdateQuery(query, function (updatedQuery) {
    // It's important to update URL first, and only then update state
    if (updatedQuery.id !== query.id) {
      // Don't reload page when saving new query
      // navigateTo(updatedQuery.getUrl(true), true);
    }
    setQuery(updatedQuery);
    setOriginalQuerySource(updatedQuery.query);
    setOriginalAutoLimit(updatedQuery.options.apply_auto_limit);
  });
  return useMemo(function () {
    return {
      query: query,
      setQuery: setQuery,
      isDirty: query.query !== originalQuerySource || !isEmpty(query.query) && query.options.apply_auto_limit !== originalAutoLimit,
      saveQuery: function saveQuery() {
        return updateQuery();
      }
    };
  }, [query, originalQuerySource, updateQuery, originalAutoLimit]);
}