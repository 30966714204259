import { dataServicesSelector, viewStateSelector } from '../../selectors';
import { loadEntitiesDataRequest } from '../records_request';
import { createAction as baseCreateAction } from '../base';
export var GRID_STATE_CHANGE_ACTION = 'GRID_STATE_CHANGE';
export function createGridAction(partialStateName, partialStateValue, options) {
  if (["currentPage", "pageSize", "filters", "search"].includes(partialStateName)) {
    return function (dispatch, getState) {
      var _Object$assign;
      var entityState = viewStateSelector(getState(), options.id);
      var service = dataServicesSelector(getState());
      var newOptions = Object.assign({}, options, entityState, (_Object$assign = {}, _Object$assign[partialStateName] = partialStateValue, _Object$assign));
      if (["filters", "search"].includes(partialStateName)) {
        newOptions = Object.assign({}, newOptions, {
          "currentPage": 0
        });
      }
      dispatch(createGridAction("loading", true, options));
      loadEntitiesDataRequest(dispatch, GRID_STATE_CHANGE_ACTION, service, newOptions);
      dispatch(baseCreateAction(GRID_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options));
    };
  } else {
    return baseCreateAction(GRID_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
  }
}
export function loadGridEntitiesData(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    dispatch(createGridAction("loading", true, options));
    return loadEntitiesDataRequest(dispatch, GRID_STATE_CHANGE_ACTION, service, options);
  };
}