// const fetch = require('node-fetch');
var data = {};
var etags = {};
export default (function (url, options) {
  if (url === void 0) {
    url = null;
  }
  if (options === void 0) {
    options = {
      headers: {}
    };
  }
  url = url || options.url; // eslint-disable-line no-param-reassign

  if (options.method) {
    options.method = options.method.toUpperCase();
  }
  if (options.method === 'GET' || !options.method) {
    var etag = etags[url];
    var cachedResponse = data["" + url + etag]; // ensure etag is for url
    if (etag) {
      options.headers['If-None-Match'] = etag;
    }
    return fetch(url, options).then(function (response) {
      if (response.status === 304) {
        return cachedResponse.clone();
      }
      if (response.status === 200) {
        var responseEtag = response.headers.get('Etag');
        if (responseEtag) {
          data["" + url + responseEtag] = response.clone();
          etags[url] = responseEtag;
        }
      }
      return response;
    });
  }

  // all other requests go straight to fetch
  return Reflect.apply(fetch, undefined, [url, options]); //eslint-disable-line no-undefined
});