import getOptions from "./getOptions";
import Renderer from "./Renderer";
import Editor from "./Editor";
export default {
  type: "CHART",
  name: "Chart",
  isDefault: true,
  getOptions: getOptions,
  Renderer: Renderer,
  Editor: Editor,
  defaultColumns: 3,
  defaultRows: 8,
  minColumns: 1,
  minRows: 5
};