import _extends from "@babel/runtime/helpers/extends";
export var uniqByName = function uniqByName(originalArray) {
  var names = new Set();
  var newArray = [];
  originalArray.forEach(function (el) {
    if (!names.has(el.name)) {
      names.add(el.name);
      newArray.push(el);
    }
  });
  return newArray;
};
export var uniqOptGroups = function uniqOptGroups(originalArray) {
  var labels = new Set();
  var names = new Set();
  var newArray = [];
  originalArray.forEach(function (el) {
    if (!labels.has(el.label)) {
      labels.add(el.label);
      var optionsForThisGroup = [];
      el.options.forEach(function (opt) {
        if (!names.has(opt.name)) {
          names.add(opt.name);
          optionsForThisGroup.push(opt);
        }
      });
      newArray.push(_extends({}, el, {
        options: optionsForThisGroup
      }));
    }
  });
  return newArray;
};