import { isPojo } from './parserUtils';
export var findPath = function findPath(path, query) {
  var target = query;
  var level = 0;
  while (level < path.length && target && 'rules' in target) {
    var t = target.rules[path[level]];
    if (typeof t !== 'string') {
      target = t;
    } else {
      target = null;
    }
    level++;
  }
  return target;
};
export var getParentPath = function getParentPath(path) {
  return path.slice(0, path.length - 1);
};
export var pathsAreEqual = function pathsAreEqual(path1, path2) {
  return path1.length === path2.length && path1.every(function (val, idx) {
    return val === path2[idx];
  });
};
export var isAncestor = function isAncestor(maybeAncestor, path) {
  return maybeAncestor.length < path.length && RegExp("^" + maybeAncestor.join('-')).test(path.join('-'));
};
export var getCommonAncestorPath = function getCommonAncestorPath(path1, path2) {
  var commonAncestorPath = [];
  var parentPath1 = getParentPath(path1);
  var parentPath2 = getParentPath(path2);
  var i = 0;
  while (i < parentPath1.length && i < parentPath2.length && parentPath1[i] === parentPath2[i]) {
    commonAncestorPath.push(parentPath2[i]);
    i++;
  }
  return commonAncestorPath;
};
export var pathIsDisabled = function pathIsDisabled(path, query) {
  var disabled = !!query.disabled;
  var target = query;
  var level = 0;
  while (level < path.length && !disabled && 'rules' in target) {
    var t = target.rules[path[level]];
    if (isPojo(t) && ('rules' in t || 'field' in t)) {
      disabled = !!t.disabled;
      target = t;
    }
    level++;
  }
  return disabled;
};