var fileGeneric = {
  name: 'Upload',
  icon: 'https://static.thenounproject.com/png/1217275-200.png',
  props: {
    multiple: false,
    directory: false,
    disabled: false,
    openFileDialogOnClick: true,
    listType: "picture-card",
    showUploadList: false,
    method: "post",
    withCredentials: false
  },
  attrs: {
    hidden: false,
    title: "",
    isLocked: false,
    condition: true,
    conditionGroup: ""
  },
  children: [{
    componentName: "Icon",
    props: {
      type: "FileAddTwoTone",
      size: 20,
      rotate: 0,
      spin: false
    },
    hidden: false,
    title: "",
    isLocked: false,
    condition: true,
    conditionGroup: ""
  }]
};
export var FieldMapComponent = {
  "text": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "textarea": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "html": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "select": {
    name: 'Select',
    icon: 'https://thewebfosters.com/wp-content/uploads/2017/05/select2.png',
    props: {}
  },
  "boolean": {
    name: 'Checkbox',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "toggle": {
    name: 'Switch',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "date": {
    name: 'DatePicker',
    icon: 'https://img.icons8.com/ios/250/000000/calendar-13.png',
    props: {
      picker: 'date'
    }
  },
  "datetime": {
    name: 'DatePicker',
    icon: 'https://img.icons8.com/ios/100/000000/overtime.png',
    props: {
      picker: 'datetime'
    }
  },
  "time": {
    name: 'TimePicker',
    icon: 'https://www.jqueryscript.net/images/jQuery-Plugin-To-Auto-Format-Time-Format-timepicker-js.jpg',
    props: {
      picker: 'time'
    }
  },
  "number": {
    name: 'InputNumber',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "currency": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "percent": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "password": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "lookup": {
    name: 'LookupSelect',
    icon: 'https://icon-library.com/images/dropdown-icon-png/dropdown-icon-png-21.jpg',
    props: {}
  },
  "master_detail": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "grid": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "url": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "email": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "avatar": {
    name: 'Upload',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "location": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "image": {
    name: 'Upload',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {
      multiple: false,
      directory: false,
      disabled: false,
      openFileDialogOnClick: true,
      listType: "picture-card",
      showUploadList: false,
      method: "post",
      withCredentials: false,
      accept: "image/jpeg,image/png,image/gif"
    },
    attrs: {
      hidden: false,
      title: "",
      isLocked: false,
      condition: true,
      conditionGroup: ""
    },
    children: [{
      componentName: "Icon",
      props: {
        type: "CameraTwoTone",
        size: 20,
        rotate: 0,
        spin: false
      },
      hidden: false,
      title: "",
      isLocked: false,
      condition: true,
      conditionGroup: ""
    }]
  },
  "object": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "[object]": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "[Object]": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "[grid]": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "[text]": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "selectCity": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "audio": fileGeneric,
  "filesize": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "file": fileGeneric,
  "code": {
    name: 'Input.TextArea',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "autonumber": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "markdown": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "formula": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "summary": {
    name: 'Input',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  },
  "color": {
    name: 'Color',
    icon: 'https://static.thenounproject.com/png/1217275-200.png',
    props: {}
  }
};