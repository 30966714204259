/**
 * 以下 symbol 均用于在 shell 层对外暴露的模型中存储相应内部模型的 key
 */
export var projectSymbol = Symbol('project');
export var designerSymbol = Symbol('designer');
export var skeletonSymbol = Symbol('skeleton');
export var documentSymbol = Symbol('document');
export var editorSymbol = Symbol('editor');
export var nodeSymbol = Symbol('node');
export var modalNodesManagerSymbol = Symbol('modalNodesManager');
export var nodeChildrenSymbol = Symbol('nodeChildren');
export var propSymbol = Symbol('prop');
export var settingPropEntrySymbol = Symbol('settingPropEntry');
export var settingTopEntrySymbol = Symbol('settingTopEntry');
export var propsSymbol = Symbol('props');
export var detectingSymbol = Symbol('detecting');
export var selectionSymbol = Symbol('selection');
export var historySymbol = Symbol('history');
export var canvasSymbol = Symbol('canvas');
export var dragonSymbol = Symbol('dragon');
export var componentMetaSymbol = Symbol('componentMeta');
export var dropLocationSymbol = Symbol('dropLocation');
export var simulatorHostSymbol = Symbol('simulatorHost');
export var simulatorRendererSymbol = Symbol('simulatorRenderer');