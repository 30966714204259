import { toArray, trimIfString } from '../arrayUtils';
import { shouldRenderAsNumber } from './utils';
var shouldNegate = function shouldNegate(op) {
  return /^(does)?not/i.test(op);
};
export var defaultRuleProcessorCEL = function defaultRuleProcessorCEL(_ref, // istanbul ignore next
_temp) {
  var field = _ref.field,
    operator = _ref.operator,
    value = _ref.value,
    valueSource = _ref.valueSource;
  var _ref2 = _temp === void 0 ? {} : _temp,
    escapeQuotes = _ref2.escapeQuotes,
    parseNumbers = _ref2.parseNumbers;
  var escapeDoubleQuotes = function escapeDoubleQuotes(v) {
    return typeof v !== 'string' || !escapeQuotes ? v : v.replaceAll("\"", "\\\"");
  };
  var valueIsField = valueSource === 'field';
  var operatorTL = operator.replace(/^=$/, '==');
  var useBareValue = typeof value === 'number' || typeof value === 'boolean' || typeof value === 'bigint' || shouldRenderAsNumber(value, parseNumbers);
  if (operatorTL === '<' || operatorTL === '<=' || operatorTL === '==' || operatorTL === '!=' || operatorTL === '>' || operatorTL === '>=') {
    return field + " " + operatorTL + " " + (valueIsField || useBareValue ? trimIfString(value) : "\"" + escapeDoubleQuotes(value) + "\"");
  } else if (operatorTL === 'contains' || operatorTL === 'doesNotContain') {
    var negate = shouldNegate(operatorTL) ? '!' : '';
    return "" + negate + field + ".contains(" + (valueIsField ? trimIfString(value) : "\"" + escapeDoubleQuotes(value) + "\"") + ")";
  } else if (operatorTL === 'beginsWith' || operatorTL === 'doesNotBeginWith') {
    var _negate = shouldNegate(operatorTL) ? '!' : '';
    return "" + _negate + field + ".startsWith(" + (valueIsField ? trimIfString(value) : "\"" + escapeDoubleQuotes(value) + "\"") + ")";
  } else if (operatorTL === 'endsWith' || operatorTL === 'doesNotEndWith') {
    var _negate2 = shouldNegate(operatorTL) ? '!' : '';
    return "" + _negate2 + field + ".endsWith(" + (valueIsField ? trimIfString(value) : "\"" + escapeDoubleQuotes(value) + "\"") + ")";
  } else if (operatorTL === 'null') {
    return field + " == null";
  } else if (operatorTL === 'notNull') {
    return field + " != null";
  } else if (operatorTL === 'in' || operatorTL === 'notIn') {
    var _negate3 = shouldNegate(operatorTL);
    var valArray = toArray(value);
    if (valArray.length > 0) {
      return "" + (_negate3 ? '!(' : '') + field + " in [" + valArray.map(function (val) {
        return valueIsField || shouldRenderAsNumber(val, parseNumbers) ? "" + trimIfString(val) : "\"" + escapeDoubleQuotes(val) + "\"";
      }).join(', ') + "]" + (_negate3 ? ')' : '');
    } else {
      return '';
    }
  } else if (operatorTL === 'between' || operatorTL === 'notBetween') {
    var _valArray = toArray(value);
    if (_valArray.length >= 2 && !!_valArray[0] && !!_valArray[1]) {
      var first = _valArray[0],
        second = _valArray[1];
      var firstNum = shouldRenderAsNumber(first, true) ? parseFloat(first) : NaN;
      var secondNum = shouldRenderAsNumber(second, true) ? parseFloat(second) : NaN;
      var firstValue = isNaN(firstNum) ? valueIsField ? "" + first : "\"" + escapeDoubleQuotes(first) + "\"" : firstNum;
      var secondValue = isNaN(secondNum) ? valueIsField ? "" + second : "\"" + escapeDoubleQuotes(second) + "\"" : secondNum;
      if (firstValue === firstNum && secondValue === secondNum && secondNum < firstNum) {
        var tempNum = secondNum;
        secondValue = firstNum;
        firstValue = tempNum;
      }
      if (operator === 'between') {
        return "(" + field + " >= " + firstValue + " && " + field + " <= " + secondValue + ")";
      } else {
        return "(" + field + " < " + firstValue + " || " + field + " > " + secondValue + ")";
      }
    } else {
      return '';
    }
  }
  return '';
};