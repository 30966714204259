import _extends from "@babel/runtime/helpers/extends";
import { isOptionGroupArray } from '../utils/optGroupUtils';
export var filterFieldsByComparator = function filterFieldsByComparator(field, fields, operator) {
  if (!field.comparator) {
    var filterOutSameName = function filterOutSameName(f) {
      return f.name !== field.name;
    };
    if (isOptionGroupArray(fields)) {
      return fields.map(function (og) {
        return _extends({}, og, {
          options: og.options.filter(filterOutSameName)
        });
      });
    }
    return fields.filter(filterOutSameName);
  }
  var filterByComparator = function filterByComparator(fieldToCompare) {
    if (field.name === fieldToCompare.name) {
      return false;
    }
    if (typeof field.comparator === 'string') {
      return field[field.comparator] === fieldToCompare[field.comparator];
    }
    return field.comparator(fieldToCompare, operator);
  };
  if (isOptionGroupArray(fields)) {
    return fields.map(function (og) {
      return _extends({}, og, {
        options: og.options.filter(filterByComparator)
      });
    }).filter(function (og) {
      return og.options.length > 0;
    });
  }
  return fields.filter(filterByComparator);
};