import { extend, filter, find } from "lodash";
import { useCallback } from "react";
import EditVisualizationDialog from "../../../components/visualizations/EditVisualizationDialog";
import useImmutableCallback from "../../../lib/hooks/useImmutableCallback";
export default function useEditVisualizationDialog(query, queryResult, onChange) {
  var handleChange = useImmutableCallback(onChange);
  return useCallback(function (visualizationId) {
    if (visualizationId === void 0) {
      visualizationId = null;
    }
    var visualization = find(query.visualizations, {
      id: visualizationId
    }) || null;
    EditVisualizationDialog.showModal({
      query: query,
      visualization: visualization,
      queryResult: queryResult
    }).onClose(function (updatedVisualization) {
      var filteredVisualizations = filter(query.visualizations, function (v) {
        return v.id !== updatedVisualization.id;
      });
      handleChange(extend(query.clone(), {
        visualizations: [].concat(filteredVisualizations, [updatedVisualization])
      }), updatedVisualization);
    });
  }, [query, queryResult, handleChange]);
}