import { createAction } from './base';
export var PLUGIN_INSTANCE_RECEIVED_ACTION = 'PLUGIN_INSTANCE_RECEIVED';
export var PLUGIN_COMPONENT_RECEIVED_ACTION = 'PLUGIN_COMPONENT_RECEIVED';
export function receivePluginInstance(name, instance) {
  return createAction(PLUGIN_INSTANCE_RECEIVED_ACTION, 'received', {
    name: name,
    instance: instance
  }, null);
}
export function receivePluginComponent(name, data) {
  return createAction(PLUGIN_COMPONENT_RECEIVED_ACTION, 'received', {
    name: name,
    data: data
  }, null);
}