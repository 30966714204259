import { merge } from "lodash";
import Renderer from "./Renderer";
import Editor from "./Editor";
var DEFAULT_OPTIONS = {
  column: "",
  frequenciesColumn: "",
  wordLengthLimit: {
    min: null,
    max: null
  },
  wordCountLimit: {
    min: null,
    max: null
  }
};
export default {
  type: "WORD_CLOUD",
  name: "Word Cloud",
  getOptions: function getOptions(options) {
    return merge({}, DEFAULT_OPTIONS, options);
  },
  Renderer: Renderer,
  Editor: Editor,
  defaultRows: 8
};