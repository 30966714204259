import _extends from "@babel/runtime/helpers/extends";
import { has } from "lodash";
import { axios } from "./axios";
import DataSource from "./data-source";
import { fetchDataFromJob } from "./query-result";
function fetchDataFromJobOrReturnData(data) {
  return has(data, "job.id") ? fetchDataFromJob(data.job.id, 1000) : data;
}
function rejectErrorResponse(data) {
  return has(data, "error") ? Promise.reject(new Error(data.error.message)) : data;
}
export default _extends({}, DataSource, {
  getDatabases: function getDatabases(_ref, refresh) {
    var id = _ref.id;
    if (refresh === void 0) {
      refresh = false;
    }
    var params = {};
    if (refresh) {
      params.refresh = true;
    }
    return axios.get("api/databricks/databases/" + id, {
      params: params
    }).then(fetchDataFromJobOrReturnData).then(rejectErrorResponse);
  },
  getDatabaseTables: function getDatabaseTables(data, databaseName, refresh) {
    if (refresh === void 0) {
      refresh = false;
    }
    var params = {};
    if (refresh) {
      params.refresh = true;
    }
    return axios.get("api/databricks/databases/" + data.id + "/" + databaseName + "/tables", {
      params: params
    }).then(fetchDataFromJobOrReturnData).then(rejectErrorResponse);
  },
  getTableColumns: function getTableColumns(data, databaseName, tableName) {
    return axios.get("api/databricks/databases/" + data.id + "/" + databaseName + "/columns/" + tableName).then(fetchDataFromJobOrReturnData).then(rejectErrorResponse);
  }
});