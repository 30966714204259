export var getRegExStr = function getRegExStr(re) {
  return typeof re === 'string' ? re : re.source;
};
export var isPrimitive = function isPrimitive(v) {
  return typeof v === 'string' || typeof v === 'number' || typeof v === 'boolean';
};
export var mongoDbToRqbOperatorMap = {
  $eq: '=',
  $ne: '!=',
  $gt: '>',
  $gte: '>=',
  $lt: '<',
  $lte: '<='
};