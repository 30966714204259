import getOptions from "./getOptions";
import Renderer from "./Renderer";
import Editor from "./Editor";
export default {
  type: "MAP",
  name: "Map (Markers)",
  getOptions: getOptions,
  Renderer: Renderer,
  Editor: Editor,
  defaultColumns: 3,
  defaultRows: 8,
  minColumns: 2
};