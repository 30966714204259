import { isNil, isUndefined, isFunction, isObject, trimStart, mapValues, omitBy, extend } from "lodash";
import qs from "query-string";
import { createBrowserHistory } from "history";
var history = createBrowserHistory();
function normalizeLocation(rawLocation) {
  var pathname = rawLocation.pathname,
    search = rawLocation.search,
    hash = rawLocation.hash;
  var result = {};
  result.path = pathname;
  result.search = mapValues(qs.parse(search), function (value) {
    return isNil(value) ? true : value;
  });
  result.hash = trimStart(hash, "#");
  result.url = "" + pathname + search + hash;
  return result;
}
var location = {
  listen: function listen(handler) {
    if (isFunction(handler)) {
      var listener = function listener(_unused, action) {
        return handler(location, action);
      };
      return history.listen(listener);
    } else {
      return function () {};
    }
  },
  confirmChange: function confirmChange(handler) {
    return function () {}; // DO NOT REMOVE COMMENT CODE BELOW
    // if (isFunction(handler) && false) {
    //   return history.block(nextLocation => {
    //     return handler(normalizeLocation(nextLocation), location);
    //   });
    // } else {
    //   return () => { };
    // }
  },
  update: function update(newLocation, replace) {
    if (replace === void 0) {
      replace = false;
    }
    if (typeof window.Meteor != 'undefined') {
      return;
    }
    if (isObject(newLocation)) {
      // remap fields and remove undefined ones
      newLocation = omitBy({
        pathname: newLocation.path,
        search: newLocation.search,
        hash: newLocation.hash
      }, isUndefined);
      // keep existing fields (!)
      newLocation = extend({
        pathname: location.path,
        search: location.search,
        hash: location.hash
      }, newLocation);

      // serialize search and keep existing search parameters (!)
      if (isObject(newLocation.search)) {
        newLocation.search = omitBy(extend({}, location.search, newLocation.search), isNil);
        newLocation.search = mapValues(newLocation.search, function (value) {
          return value === true ? null : value;
        });
        newLocation.search = qs.stringify(newLocation.search);
      }
    }
    if (replace) {
      history.replace(newLocation);
    } else {
      history.push(newLocation);
    }
  },
  url: undefined,
  path: undefined,
  setPath: function setPath(path, replace) {
    if (replace === void 0) {
      replace = false;
    }
    location.update({
      path: path
    }, replace);
  },
  search: undefined,
  setSearch: function setSearch(search, replace) {
    if (replace === void 0) {
      replace = false;
    }
    location.update({
      search: search
    }, replace);
  },
  hash: undefined,
  setHash: function setHash(hash, replace) {
    if (replace === void 0) {
      replace = false;
    }
    location.update({
      hash: hash
    }, replace);
  }
};
function locationChanged() {
  extend(location, normalizeLocation(history.location));
}
history.listen(locationChanged);
locationChanged(); // init service
window.__location = location;
export default location;