import { API } from './API';
import { Forms } from './Form';
import { Tables } from './Table';
import { Objects } from './Object';
import { Settings } from './Settings';
import { Apps } from './Apps';
import { User } from './User';
import { Queries } from './Queries';
import { ComponentRegistry } from './ComponentRegistry';
import { Pages } from './Pages';
import { configure } from 'mobx';
configure({
  isolateGlobalState: true
});
var uiStore = {
  Forms: Forms,
  Tables: Tables,
  Objects: Objects,
  Settings: Settings,
  API: API,
  Apps: Apps,
  User: User,
  Queries: Queries,
  ComponentRegistry: ComponentRegistry,
  Pages: Pages
};
var stores = Object.assign({}, uiStore);
if (typeof window.stores == 'undefined') window.stores = stores;
export { Forms, Tables, Objects, Settings, API, Apps, User, Queries, ComponentRegistry, Pages };
export default stores;
if (!window.Meteor) {
  window.DGPlatform = {};
  window.DGPlatform.Objects = {};
}