import { produce } from 'immer';
import { defaultCombinators } from '../defaults';
import { regenerateID, regenerateIDs } from '../internal/regenerateIDs';
import { getFirstOption } from './optGroupUtils';
import { findPath, getCommonAncestorPath, getParentPath, pathsAreEqual } from './pathUtils';
import { prepareRuleOrGroup } from './prepareQueryObjects';
export var add = function add(query, ruleOrGroup, parentPath, _temp) {
  var _ref = _temp === void 0 ? {} : _temp,
    _ref$combinators = _ref.combinators,
    combinators = _ref$combinators === void 0 ? defaultCombinators : _ref$combinators;
  return produce(query, function (draft) {
    var parent = findPath(parentPath, draft);
    if (!('combinator' in parent) && parent.rules.length > 0) {
      var prevCombinator = parent.rules[parent.rules.length - 2];
      // TODO: Instead of just getting the first custom/default combinator,
      // we could search the query for the first combinator we find and
      // use that in case custom combinator names are being used. Would
      // still need to fall back to custom/default combinators in case there
      // are no combinators defined in the query yet.
      parent.rules.push(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore This is technically a type violation until the next push, but
      // that happens immediately and unconditionally so there's no actual risk
      typeof prevCombinator === 'string' ? prevCombinator : getFirstOption(combinators));
    }
    parent.rules.push(prepareRuleOrGroup(ruleOrGroup));
  });
};
export var update = function update(query, prop, value, path, _temp2) {
  var _ref2 = _temp2 === void 0 ? {} : _temp2,
    _ref2$resetOnFieldCha = _ref2.resetOnFieldChange,
    resetOnFieldChange = _ref2$resetOnFieldCha === void 0 ? true : _ref2$resetOnFieldCha,
    _ref2$resetOnOperator = _ref2.resetOnOperatorChange,
    resetOnOperatorChange = _ref2$resetOnOperator === void 0 ? false : _ref2$resetOnOperator,
    _ref2$getRuleDefaultO = _ref2.getRuleDefaultOperator,
    getRuleDefaultOperator = _ref2$getRuleDefaultO === void 0 ? function () {
      return '=';
    } : _ref2$getRuleDefaultO,
    _ref2$getValueSources = _ref2.getValueSources,
    getValueSources = _ref2$getValueSources === void 0 ? function () {
      return ['value'];
    } : _ref2$getValueSources,
    _ref2$getRuleDefaultV = _ref2.getRuleDefaultValue,
    getRuleDefaultValue = _ref2$getRuleDefaultV === void 0 ? function () {
      return '';
    } : _ref2$getRuleDefaultV;
  return produce(query, function (draft) {
    if (prop === 'combinator' && !('combinator' in draft)) {
      // Independent combinators
      var parentRules = findPath(getParentPath(path), draft).rules;
      // Only update an independent combinator if it occupies an odd index
      if (path[path.length - 1] % 2 === 1) {
        parentRules[path[path.length - 1]] = value;
      }
      return;
    } else {
      var ruleOrGroup = findPath(path, draft);
      var isGroup = ('rules' in ruleOrGroup);
      // Only update if there is actually a change
      // @ts-expect-error prop can refer to rule or group properties
      if (ruleOrGroup[prop] !== value) {
        // Handle valueSource updates later
        if (prop !== 'valueSource') {
          // @ts-expect-error prop can refer to rule or group properties
          ruleOrGroup[prop] = value;
        }
        if (!isGroup) {
          var resetValueSource = false;
          var resetValue = false;

          // Set default operator, valueSource, and value for field change
          if (resetOnFieldChange && prop === 'field') {
            ruleOrGroup.operator = getRuleDefaultOperator(value);
            resetValueSource = true;
            resetValue = true;
          }

          // Set default valueSource and value for operator change
          if (resetOnOperatorChange && prop === 'operator') {
            resetValueSource = true;
            resetValue = true;
          }
          var defaultValueSource = getValueSources(ruleOrGroup.field, ruleOrGroup.operator)[0];
          if (resetValueSource && ruleOrGroup.valueSource && defaultValueSource !== ruleOrGroup.valueSource || prop === 'valueSource' && value !== ruleOrGroup.valueSource) {
            // Only reset the value if we're changing the valueSource either
            // 1) from `undefined` to something that is _not_ the default, or
            // 2) from the current (defined) value to something else
            resetValue = !!ruleOrGroup.valueSource || !ruleOrGroup.valueSource && value !== defaultValueSource;
            ruleOrGroup.valueSource = resetValueSource ? defaultValueSource : value;
          }
          if (resetValue) {
            // The default value should be a valid field name if defaultValueSource is 'field'
            ruleOrGroup.value = getRuleDefaultValue(ruleOrGroup);
          }
        }
      }
    }
  });
};
export var remove = function remove(query, path) {
  if (path.length === 0 || !('combinator' in query) && !findPath(path, query)) {
    return query;
  }
  return produce(query, function (draft) {
    var index = path[path.length - 1];
    var parent = findPath(getParentPath(path), draft);
    if (!('combinator' in parent) && parent.rules.length > 1) {
      var idxStartDelete = index === 0 ? 0 : index - 1;
      parent.rules.splice(idxStartDelete, 2);
    } else {
      parent.rules.splice(index, 1);
    }
  });
};
export var move = function move(query, oldPath, newPath, _temp3) {
  var _ref3 = _temp3 === void 0 ? {} : _temp3,
    _ref3$clone = _ref3.clone,
    clone = _ref3$clone === void 0 ? false : _ref3$clone,
    _ref3$combinators = _ref3.combinators,
    combinators = _ref3$combinators === void 0 ? defaultCombinators : _ref3$combinators;
  if (pathsAreEqual(oldPath, newPath)) {
    return query;
  }
  var ruleOrGroupOriginal = findPath(oldPath, query);
  if (!ruleOrGroupOriginal) {
    return query;
  }
  var ruleOrGroup = clone ? 'rules' in ruleOrGroupOriginal ? regenerateIDs(ruleOrGroupOriginal) : regenerateID(ruleOrGroupOriginal) : ruleOrGroupOriginal;
  return produce(query, function (draft) {
    var independentCombinators = !('combinator' in draft);
    var parentOfRuleToRemove = findPath(getParentPath(oldPath), draft);
    var ruleToRemoveIndex = oldPath[oldPath.length - 1];
    var oldPrevCombinator = independentCombinators && ruleToRemoveIndex > 0 ? parentOfRuleToRemove.rules[ruleToRemoveIndex - 1] : null;
    var oldNextCombinator = independentCombinators && ruleToRemoveIndex < parentOfRuleToRemove.rules.length - 1 ? parentOfRuleToRemove.rules[ruleToRemoveIndex + 1] : null;

    // Remove the source item if not cloning
    if (!clone) {
      var idxStartDelete = independentCombinators ? Math.max(0, ruleToRemoveIndex - 1) : ruleToRemoveIndex;
      var deleteLength = independentCombinators ? 2 : 1;
      parentOfRuleToRemove.rules.splice(idxStartDelete, deleteLength);
    }
    var newNewPath = [].concat(newPath);
    var commonAncestorPath = getCommonAncestorPath(oldPath, newPath);
    if (!clone && oldPath.length === commonAncestorPath.length + 1 && newPath[commonAncestorPath.length] > oldPath[commonAncestorPath.length]) {
      // Getting here means there will be a shift of paths upward at the common
      // ancestor level because the object at `oldPath` will be spliced out. The
      // real new path should therefore be one or two higher than `newPath`.
      newNewPath[commonAncestorPath.length] -= independentCombinators ? 2 : 1;
    }
    var newNewParentPath = getParentPath(newNewPath);
    var parentToInsertInto = findPath(newNewParentPath, draft);
    var newIndex = newNewPath[newNewPath.length - 1];

    /**
     * This function 1) glosses over the need for type assertions to splice directly
     * into `parentToInsertInto.rules`, and 2) shortens the actual insertion code.
     */
    var insertRuleOrGroup = function insertRuleOrGroup() {
      var _parentToInsertInto$r;
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return (_parentToInsertInto$r = parentToInsertInto.rules).splice.apply(_parentToInsertInto$r, [newIndex, 0].concat(args));
    };

    // Insert the source item at the target path
    if (parentToInsertInto.rules.length === 0 || !independentCombinators) {
      insertRuleOrGroup(ruleOrGroup);
    } else {
      if (newIndex === 0) {
        if (ruleToRemoveIndex === 0 && oldNextCombinator) {
          insertRuleOrGroup(ruleOrGroup, oldNextCombinator);
        } else {
          var newNextCombinator = parentToInsertInto.rules[1] || oldPrevCombinator || getFirstOption(combinators);
          insertRuleOrGroup(ruleOrGroup, newNextCombinator);
        }
      } else {
        if (oldPrevCombinator) {
          insertRuleOrGroup(oldPrevCombinator, ruleOrGroup);
        } else {
          var newPrevCombinator = parentToInsertInto.rules[newIndex - 2] || oldNextCombinator || getFirstOption(combinators);
          insertRuleOrGroup(newPrevCombinator, ruleOrGroup);
        }
      }
    }
  });
};