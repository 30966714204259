import { toArray, trimIfString } from '../arrayUtils';
import { isValidValue, shouldRenderAsNumber } from './utils';
export var defaultValueProcessorByRule = function defaultValueProcessorByRule(_ref, // istanbul ignore next
_temp) {
  var operator = _ref.operator,
    value = _ref.value,
    valueSource = _ref.valueSource;
  var _ref2 = _temp === void 0 ? {} : _temp,
    escapeQuotes = _ref2.escapeQuotes,
    parseNumbers = _ref2.parseNumbers;
  var escapeSingleQuotes = function escapeSingleQuotes(v) {
    return typeof v !== 'string' || !escapeQuotes ? v : v.replaceAll("'", "''");
  };
  var valueIsField = valueSource === 'field';
  var operatorLowerCase = operator.toLowerCase();
  if (operatorLowerCase === 'null' || operatorLowerCase === 'notnull') {
    return '';
  } else if (operatorLowerCase === 'in' || operatorLowerCase === 'notin') {
    var valArray = toArray(value);
    if (valArray.length > 0) {
      return "(" + valArray.map(function (v) {
        return valueIsField || shouldRenderAsNumber(v, parseNumbers) ? "" + trimIfString(v) : "'" + escapeSingleQuotes(v) + "'";
      }).join(', ') + ")";
    }
    return '';
  } else if (operatorLowerCase === 'between' || operatorLowerCase === 'notbetween') {
    var _valArray = toArray(value);
    if (_valArray.length >= 2 && isValidValue(_valArray[0]) && isValidValue(_valArray[1])) {
      var first = _valArray[0],
        second = _valArray[1];
      return valueIsField || shouldRenderAsNumber(first, parseNumbers) && shouldRenderAsNumber(second, parseNumbers) ? trimIfString(first) + " and " + trimIfString(second) : "'" + escapeSingleQuotes(first) + "' and '" + escapeSingleQuotes(second) + "'";
    }
    return '';
  } else if (operatorLowerCase === 'contains' || operatorLowerCase === 'doesnotcontain') {
    return valueIsField ? "'%' || " + value + " || '%'" : "'%" + escapeSingleQuotes(value) + "%'";
  } else if (operatorLowerCase === 'beginswith' || operatorLowerCase === 'doesnotbeginwith') {
    return valueIsField ? value + " || '%'" : "'" + escapeSingleQuotes(value) + "%'";
  } else if (operatorLowerCase === 'endswith' || operatorLowerCase === 'doesnotendwith') {
    return valueIsField ? "'%' || " + value : "'%" + escapeSingleQuotes(value) + "'";
  } else if (typeof value === 'boolean') {
    return ("" + value).toUpperCase();
  }
  return valueIsField || shouldRenderAsNumber(value, parseNumbers) ? "" + trimIfString(value) : "'" + escapeSingleQuotes(value) + "'";
};