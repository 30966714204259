import { useCallback, useMemo, useState } from "react";
import { reduce } from "lodash";
import localOptions from "../../../lib/localOptions";
function calculateTokensCount(schema) {
  return reduce(schema, function (totalLength, table) {
    return totalLength + table.columns.length;
  }, 0);
}
export default function useAutocompleteFlags(schema) {
  var isAvailable = useMemo(function () {
    return calculateTokensCount(schema) <= 5000;
  }, [schema]);
  var _useState = useState(localOptions.get("liveAutocomplete", true)),
    isEnabled = _useState[0],
    setIsEnabled = _useState[1];
  var toggleAutocomplete = useCallback(function (state) {
    setIsEnabled(state);
    localOptions.set("liveAutocomplete", state);
  }, []);
  return useMemo(function () {
    return [isAvailable, isEnabled, toggleAutocomplete];
  }, [isAvailable, isEnabled, toggleAutocomplete]);
}