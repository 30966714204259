import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import { viewStateSelector } from '../../selectors';
import WidgetObject from './widget_object';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    init: function init(options) {
      // dispatch(loadEntitiesData(options))
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetObject);