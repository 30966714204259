import { isI18NObject } from './is-object';
import { get } from 'lodash';
export function isVariable(obj) {
  return obj && obj.type === 'variable';
}
export function isUseI18NSetter(prototype, propName) {
  var _prototype$options;
  var configure = prototype === null || prototype === void 0 ? void 0 : (_prototype$options = prototype.options) === null || _prototype$options === void 0 ? void 0 : _prototype$options.configure;
  if (Array.isArray(configure)) {
    return configure.some(function (c) {
      var _c$setter, _c$setter$type;
      return c.name === propName && (c === null || c === void 0 ? void 0 : (_c$setter = c.setter) === null || _c$setter === void 0 ? void 0 : (_c$setter$type = _c$setter.type) === null || _c$setter$type === void 0 ? void 0 : _c$setter$type.displayName) === 'I18nSetter';
    });
  }
  return false;
}
export function convertToI18NObject(v, locale) {
  var _ref;
  if (locale === void 0) {
    locale = 'zh_CN';
  }
  if (isI18NObject(v)) return v;
  return _ref = {
    type: 'i18n',
    use: locale
  }, _ref[locale] = v, _ref;
}
export function isString(v) {
  return typeof v === 'string';
}
function _innerWaitForThing(obj, path) {
  var timeGap = 200;
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      var thing = get(obj, path);
      if (thing) {
        return resolve(thing);
      }
      reject();
    }, timeGap);
  })["catch"](function () {
    return _innerWaitForThing(obj, path);
  });
}
export function waitForThing(obj, path) {
  var thing = get(obj, path);
  if (thing) {
    return Promise.resolve(thing);
  }
  return _innerWaitForThing(obj, path);
}
export function arrShallowEquals(arr1, arr2) {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) return false;
  if (arr1.length !== arr2.length) return false;
  return arr1.every(function (item) {
    return arr2.includes(item);
  });
}

/**
 * 判断当前 meta 是否从 vc prototype 转换而来
 * @param meta
 */
export function isFromVC(meta) {
  var _meta$getMetadata$con;
  return !!(meta !== null && meta !== void 0 && (_meta$getMetadata$con = meta.getMetadata().configure) !== null && _meta$getMetadata$con !== void 0 && _meta$getMetadata$con.advanced);
}
export function executePendingFn(fn, timeout) {
  if (timeout === void 0) {
    timeout = 2000;
  }
  return setTimeout(fn, timeout);
}
var stageList = ['render', 'serilize', 'save', 'clone', 'init', 'upgrade'];
/**
 * 兼容原来的数字版本的枚举对象
 * @param stage
 * @returns
 */
export function compatStage(stage) {
  if (typeof stage === 'number') {
    console.warn('The way of specifying stage directly as a number is outdated and will be removed in the next version, please use it directly TransformStage.Render|Serilize|Save|Clone|Init|Upgrade');
    return stageList[stage - 1];
  }
  return stage;
}
export function invariant(check, message, thing) {
  if (!check) {
    throw new Error("Invariant failed: " + message + (thing ? " in '" + thing + "'" : ''));
  }
}
export function deprecate(fail, message, alterative) {
  if (fail) {
    console.warn("Deprecation: " + message + (alterative ? ", use " + alterative + " instead." : ''));
  }
}
export function isRegExp(obj) {
  return obj && obj.test && obj.exec && obj.compile;
}