import { useState, useEffect, useMemo } from "react";
import { first, orderBy, find } from "lodash";
import location from "../../../services/location";
export default function useVisualizationTabHandler(visualizations) {
  var firstVisualization = useMemo(function () {
    return first(orderBy(visualizations, ["id"])) || {};
  }, [visualizations]);
  var _useState = useState(+location.hash || firstVisualization.id),
    selectedTab = _useState[0],
    setSelectedTab = _useState[1];
  useEffect(function () {
    var hashValue = selectedTab !== firstVisualization.id ? "" + selectedTab : null;
    if (location.hash !== hashValue) {
      location.setHash(hashValue);
    }
    var unlisten = location.listen(function () {
      if (location.hash !== hashValue) {
        setSelectedTab(+location.hash || firstVisualization.id);
      }
    });
    return unlisten;
  }, [firstVisualization.id, selectedTab]);

  // make sure selectedTab is in visualizations
  useEffect(function () {
    if (!find(visualizations, {
      id: selectedTab
    })) {
      setSelectedTab(firstVisualization.id);
    }
  }, [firstVisualization.id, selectedTab, visualizations]);
  return useMemo(function () {
    return [selectedTab, setSelectedTab];
  }, [selectedTab]);
}