import { hotkey, Editor, globalContext } from '@digiforce/dvd-editor-core';
import { isFormEvent } from '@digiforce/dvd-utils';
import { focusing } from './focusing';
import { insertChildren, TransformStage } from '../document';
import clipboard from './clipboard';
export function isInLiveEditing() {
  if (globalContext.has(Editor)) {
    var _globalContext$get$ge, _globalContext$get$ge2, _globalContext$get$ge3, _globalContext$get$ge4;
    return Boolean((_globalContext$get$ge = globalContext.get(Editor).get('designer')) === null || _globalContext$get$ge === void 0 ? void 0 : (_globalContext$get$ge2 = _globalContext$get$ge.project) === null || _globalContext$get$ge2 === void 0 ? void 0 : (_globalContext$get$ge3 = _globalContext$get$ge2.simulator) === null || _globalContext$get$ge3 === void 0 ? void 0 : (_globalContext$get$ge4 = _globalContext$get$ge3.liveEditing) === null || _globalContext$get$ge4 === void 0 ? void 0 : _globalContext$get$ge4.editing);
  }
}

/* istanbul ignore next */
function getNextForSelect(next, head, parent) {
  if (next) {
    if (!head) {
      return next;
    }
    var ret;
    if (next.isContainer()) {
      var children = next.getChildren() || [];
      if (children && !children.isEmpty()) {
        ret = getNextForSelect(children.get(0));
        if (ret) {
          return ret;
        }
      }
    }
    ret = getNextForSelect(next.nextSibling);
    if (ret) {
      return ret;
    }
  }
  if (parent) {
    return getNextForSelect(parent.nextSibling, false, parent.getParent());
  }
  return null;
}

/* istanbul ignore next */
function getPrevForSelect(prev, head, parent) {
  if (prev) {
    var ret;
    if (!head && prev.isContainer()) {
      var children = prev.getChildren() || [];
      var lastChild = children && !children.isEmpty() ? children.get(children.size - 1) : null;
      ret = getPrevForSelect(lastChild);
      if (ret) {
        return ret;
      }
    }
    if (!head) {
      return prev;
    }
    ret = getPrevForSelect(prev.prevSibling);
    if (ret) {
      return ret;
    }
  }
  if (parent) {
    return parent;
  }
  return null;
}

// hotkey binding
hotkey.bind(['backspace', 'del'], function (e) {
  var _focusing$focusDesign;
  if (isInLiveEditing()) return;
  // TODO: use focus-tracker
  var doc = (_focusing$focusDesign = focusing.focusDesigner) === null || _focusing$focusDesign === void 0 ? void 0 : _focusing$focusDesign.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var sel = doc.selection;
  var topItems = sel.getTopNodes();
  // TODO: check can remove
  topItems.forEach(function (node) {
    if (node.canPerformAction('remove')) {
      doc.removeNode(node);
    }
  });
  sel.clear();
});
hotkey.bind('escape', function (e) {
  var _focusing$focusDesign2, _focusing$focusDesign3;
  // const currentFocus = focusing.current;
  if (isInLiveEditing()) return;
  var sel = (_focusing$focusDesign2 = focusing.focusDesigner) === null || _focusing$focusDesign2 === void 0 ? void 0 : (_focusing$focusDesign3 = _focusing$focusDesign2.currentDocument) === null || _focusing$focusDesign3 === void 0 ? void 0 : _focusing$focusDesign3.selection;
  if (isFormEvent(e) || !sel) {
    return;
  }
  e.preventDefault();
  sel.clear();
  // currentFocus.esc();
});

// command + c copy  command + x cut
hotkey.bind(['command+c', 'ctrl+c', 'command+x', 'ctrl+x'], function (e, action) {
  var _focusing$focusDesign4;
  if (isInLiveEditing()) return;
  var doc = (_focusing$focusDesign4 = focusing.focusDesigner) === null || _focusing$focusDesign4 === void 0 ? void 0 : _focusing$focusDesign4.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var selected = doc.selection.getTopNodes(true);
  selected = selected.filter(function (node) {
    return node.canPerformAction('copy');
  });
  if (!selected || selected.length < 1) {
    return;
  }
  var componentsMap = {};
  var componentsTree = selected.map(function (item) {
    return item["export"](TransformStage.Clone);
  });

  // FIXME: clear node.id

  var data = {
    type: 'nodeSchema',
    componentsMap: componentsMap,
    componentsTree: componentsTree
  };
  clipboard.setData(data);
  var cutMode = action && action.indexOf('x') > 0;
  if (cutMode) {
    selected.forEach(function (node) {
      var parentNode = node.getParent();
      parentNode === null || parentNode === void 0 ? void 0 : parentNode.select();
      node.remove();
    });
  }
});

// command + v paste
hotkey.bind(['command+v', 'ctrl+v'], function (e) {
  if (isInLiveEditing()) return;
  var designer = focusing.focusDesigner;
  var doc = designer === null || designer === void 0 ? void 0 : designer.currentDocument;
  if (isFormEvent(e) || !designer || !doc) {
    return;
  }
  /* istanbul ignore next */
  clipboard.waitPasteData(e, function (_ref) {
    var componentsTree = _ref.componentsTree;
    if (componentsTree) {
      var _ref2 = designer.getSuitableInsertion(componentsTree) || {},
        target = _ref2.target,
        index = _ref2.index;
      if (!target) {
        return;
      }
      var canAddComponentsTree = componentsTree.filter(function (i) {
        return doc.checkNestingUp(target, i);
      });
      if (canAddComponentsTree.length === 0) return;
      var nodes = insertChildren(target, canAddComponentsTree, index);
      if (nodes) {
        doc.selection.selectAll(nodes.map(function (o) {
          return o.id;
        }));
        setTimeout(function () {
          return designer.activeTracker.track(nodes[0]);
        }, 10);
      }
    }
  });
});

// command + z undo
hotkey.bind(['command+z', 'ctrl+z'], function (e) {
  var _focusing$focusDesign5, _focusing$focusDesign6;
  if (isInLiveEditing()) return;
  var his = (_focusing$focusDesign5 = focusing.focusDesigner) === null || _focusing$focusDesign5 === void 0 ? void 0 : _focusing$focusDesign5.currentHistory;
  if (isFormEvent(e) || !his) {
    return;
  }
  e.preventDefault();
  var selection = (_focusing$focusDesign6 = focusing.focusDesigner) === null || _focusing$focusDesign6 === void 0 ? void 0 : _focusing$focusDesign6.currentSelection;
  var curSelected = Array.from(selection === null || selection === void 0 ? void 0 : selection.selected);
  his.back();
  selection === null || selection === void 0 ? void 0 : selection.selectAll(curSelected);
});

// command + shift + z redo
hotkey.bind(['command+y', 'ctrl+y', 'command+shift+z'], function (e) {
  var _focusing$focusDesign7, _focusing$focusDesign8;
  if (isInLiveEditing()) return;
  var his = (_focusing$focusDesign7 = focusing.focusDesigner) === null || _focusing$focusDesign7 === void 0 ? void 0 : _focusing$focusDesign7.currentHistory;
  if (isFormEvent(e) || !his) {
    return;
  }
  e.preventDefault();
  var selection = (_focusing$focusDesign8 = focusing.focusDesigner) === null || _focusing$focusDesign8 === void 0 ? void 0 : _focusing$focusDesign8.currentSelection;
  var curSelected = Array.from(selection === null || selection === void 0 ? void 0 : selection.selected);
  his.forward();
  selection === null || selection === void 0 ? void 0 : selection.selectAll(curSelected);
});

// sibling selection
hotkey.bind(['left', 'right'], function (e, action) {
  if (isInLiveEditing()) return;
  var designer = focusing.focusDesigner;
  var doc = designer === null || designer === void 0 ? void 0 : designer.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var selected = doc.selection.getTopNodes(true);
  if (!selected || selected.length < 1) {
    return;
  }
  var firstNode = selected[0];
  var silbing = action === 'left' ? firstNode === null || firstNode === void 0 ? void 0 : firstNode.prevSibling : firstNode === null || firstNode === void 0 ? void 0 : firstNode.nextSibling;
  silbing === null || silbing === void 0 ? void 0 : silbing.select();
});
hotkey.bind(['up', 'down'], function (e, action) {
  if (isInLiveEditing()) return;
  var designer = focusing.focusDesigner;
  var doc = designer === null || designer === void 0 ? void 0 : designer.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var selected = doc.selection.getTopNodes(true);
  if (!selected || selected.length < 1) {
    return;
  }
  var firstNode = selected[0];
  if (action === 'down') {
    var next = getNextForSelect(firstNode, true, firstNode.getParent());
    next === null || next === void 0 ? void 0 : next.select();
  } else if (action === 'up') {
    var prev = getPrevForSelect(firstNode, true, firstNode.getParent());
    prev === null || prev === void 0 ? void 0 : prev.select();
  }
});
hotkey.bind(['option+left', 'option+right'], function (e, action) {
  if (isInLiveEditing()) return;
  var designer = focusing.focusDesigner;
  var doc = designer === null || designer === void 0 ? void 0 : designer.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var selected = doc.selection.getTopNodes(true);
  if (!selected || selected.length < 1) {
    return;
  }
  // TODO: 此处需要增加判断当前节点是否可被操作移动，原ve里是用 node.canOperating()来判断
  // TODO: 移动逻辑也需要重新梳理，对于移动目标位置的选择，是否可以移入，需要增加判断

  var firstNode = selected[0];
  var parent = firstNode.getParent();
  if (!parent) return;
  var isPrev = action && /(left)$/.test(action);
  var silbing = isPrev ? firstNode.prevSibling : firstNode.nextSibling;
  if (silbing) {
    if (isPrev) {
      parent.insertBefore(firstNode, silbing);
    } else {
      parent.insertAfter(firstNode, silbing);
    }
    firstNode === null || firstNode === void 0 ? void 0 : firstNode.select();
  }
});
hotkey.bind(['option+up'], function (e) {
  if (isInLiveEditing()) return;
  var designer = focusing.focusDesigner;
  var doc = designer === null || designer === void 0 ? void 0 : designer.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var selected = doc.selection.getTopNodes(true);
  if (!selected || selected.length < 1) {
    return;
  }
  // TODO: 此处需要增加判断当前节点是否可被操作移动，原ve里是用 node.canOperating()来判断
  // TODO: 移动逻辑也需要重新梳理，对于移动目标位置的选择，是否可以移入，需要增加判断

  var firstNode = selected[0];
  var parent = firstNode.getParent();
  if (!parent) {
    return;
  }
  var silbing = firstNode.prevSibling;
  if (silbing) {
    if (silbing.isContainer()) {
      var place = silbing.getSuitablePlace(firstNode, null);
      place.container.insertAfter(firstNode, place.ref);
    } else {
      parent.insertBefore(firstNode, silbing);
    }
    firstNode === null || firstNode === void 0 ? void 0 : firstNode.select();
  } else {
    var _place = parent.getSuitablePlace(firstNode, null); // upwards
    if (_place) {
      _place.container.insertBefore(firstNode, _place.ref);
      firstNode === null || firstNode === void 0 ? void 0 : firstNode.select();
    }
  }
});
hotkey.bind(['option+down'], function (e) {
  if (isInLiveEditing()) return;
  var designer = focusing.focusDesigner;
  var doc = designer === null || designer === void 0 ? void 0 : designer.currentDocument;
  if (isFormEvent(e) || !doc) {
    return;
  }
  e.preventDefault();
  var selected = doc.selection.getTopNodes(true);
  if (!selected || selected.length < 1) {
    return;
  }
  // TODO: 此处需要增加判断当前节点是否可被操作移动，原ve里是用 node.canOperating()来判断
  // TODO: 移动逻辑也需要重新梳理，对于移动目标位置的选择，是否可以移入，需要增加判断

  var firstNode = selected[0];
  var parent = firstNode.getParent();
  if (!parent) {
    return;
  }
  var silbing = firstNode.nextSibling;
  if (silbing) {
    if (silbing.isContainer()) {
      // const place = silbing.getSuitablePlace(firstNode, null);
      silbing.insertBefore(firstNode, undefined);
      // place.container.insertBefore(firstNode, place.ref);
    } else {
      parent.insertAfter(firstNode, silbing);
    }
    firstNode === null || firstNode === void 0 ? void 0 : firstNode.select();
  } else {
    var place = parent.getSuitablePlace(firstNode, null); // upwards
    if (place) {
      place.container.insertAfter(firstNode, place.ref);
      firstNode === null || firstNode === void 0 ? void 0 : firstNode.select();
    }
  }
});