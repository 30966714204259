import _extends from "@babel/runtime/helpers/extends";
import notification from 'antd/lib/notification';
var _notification = notification;
if (notification["default"]) {
  _notification = notification["default"];
}
_notification.config({
  placement: 'bottomRight',
  duration: 3
});
var simpleNotification = {};
['success', 'error', 'info', 'warning', 'warn'].forEach(function (action) {
  // eslint-disable-next-line arrow-body-style
  simpleNotification[action] = function (message, description, props) {
    if (description === void 0) {
      description = null;
    }
    if (props === void 0) {
      props = null;
    }
    return _notification[action](_extends({}, props, {
      message: message,
      description: description
    }));
  };
});
export default _extends({}, _notification, simpleNotification);