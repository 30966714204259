import _extends from "@babel/runtime/helpers/extends";
export var numericRegex = /^\s*[+-]?(\d+|\d*\.\d+|\d+\.\d*)([Ee][+-]?\d+)?\s*$/;
export var mapSQLOperator = function mapSQLOperator(op) {
  switch (op.toLowerCase()) {
    case 'null':
      return 'is null';
    case 'notnull':
      return 'is not null';
    case 'notin':
      return 'not in';
    case 'notbetween':
      return 'not between';
    case 'contains':
    case 'beginswith':
    case 'endswith':
      return 'like';
    case 'doesnotcontain':
    case 'doesnotbeginwith':
    case 'doesnotendwith':
      return 'not like';
    default:
      return op;
  }
};
export var mongoOperators = {
  '=': '$eq',
  '!=': '$ne',
  '<': '$lt',
  '<=': '$lte',
  '>': '$gt',
  '>=': '$gte',
  "in": '$in',
  notIn: '$nin'
};
export var celCombinatorMap = {
  and: '&&',
  or: '||'
};

/**
 * Register these operators with jsonLogic before applying the
 * result of formatQuery(query, 'jsonlogic').
 *
 * @example
 * for (const [op, func] of Object.entries(jsonLogicAdditionalOperators)) {
 *   jsonLogic.add_operation(op, func);
 * }
 * jsonLogic.apply({ "startsWith": [{ "var": "firstName" }, "Stev"] }, data);
 */
export var jsonLogicAdditionalOperators = {
  startsWith: function startsWith(a, b) {
    return a.startsWith(b);
  },
  endsWith: function endsWith(a, b) {
    return a.endsWith(b);
  }
};
export var numerifyValues = function numerifyValues(rg) {
  return _extends({}, rg, {
    // TODO: @ts-expect-error once we don't support TS@<4.5
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore TS can't keep track of odd/even indexes here
    rules: rg.rules.map(function (r) {
      if (typeof r === 'string') {
        return r;
      } else if ('rules' in r) {
        return numerifyValues(r);
      }
      var value = r.value;
      if (typeof value === 'string' && numericRegex.test(value)) {
        value = parseFloat(value);
      }
      // if (toArray(value).length > 1) {
      //   return { ...r, value };
      // }
      // if (typeof value === 'number' && !isNaN(value)) {
      //   return { ...r, value };
      // }
      return _extends({}, r, {
        value: value
      });
    })
  });
};
export var isValidValue = function isValidValue(v) {
  return typeof v === 'string' && v.length > 0 || typeof v === 'number' && !isNaN(v) || typeof v !== 'string' && typeof v !== 'number';
};
export var shouldRenderAsNumber = function shouldRenderAsNumber(v, parseNumbers) {
  return !!parseNumbers && (typeof v === 'number' || typeof v === 'bigint' || typeof v === 'string' && numericRegex.test(v));
};
export var isValueProcessorLegacy = function isValueProcessorLegacy(vp) {
  return vp.length >= 3;
};