import _extends from "@babel/runtime/helpers/extends";
import produce from 'immer';
export var transformQuery = function transformQuery(query, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options,
    _options$ruleProcesso = _options.ruleProcessor,
    ruleProcessor = _options$ruleProcesso === void 0 ? function (r) {
      return r;
    } : _options$ruleProcesso,
    _options$ruleGroupPro = _options.ruleGroupProcessor,
    ruleGroupProcessor = _options$ruleGroupPro === void 0 ? function (rg) {
      return rg;
    } : _options$ruleGroupPro,
    _options$propertyMap = _options.propertyMap,
    propertyMap = _options$propertyMap === void 0 ? {} : _options$propertyMap,
    _options$combinatorMa = _options.combinatorMap,
    combinatorMap = _options$combinatorMa === void 0 ? {} : _options$combinatorMa,
    _options$operatorMap = _options.operatorMap,
    operatorMap = _options$operatorMap === void 0 ? {} : _options$operatorMap,
    _options$deleteRemapp = _options.deleteRemappedProperties,
    deleteRemappedProperties = _options$deleteRemapp === void 0 ? true : _options$deleteRemapp;
  var remapProperties = function remapProperties(obj) {
    return produce(obj, function (draft) {
      for (var _i = 0, _Object$entries = Object.entries(propertyMap); _i < _Object$entries.length; _i++) {
        var _Object$entries$_i = _Object$entries[_i],
          k = _Object$entries$_i[0],
          v = _Object$entries$_i[1];
        draft[v] = draft[k];
        if (deleteRemappedProperties) {
          delete draft[k];
        }
      }
    });
  };
  var processGroup = function processGroup(rg) {
    var _combinatorMap$rg$com;
    return _extends({}, ruleGroupProcessor(remapProperties(_extends({}, rg, 'combinator' in rg ? {
      combinator: (_combinatorMap$rg$com = combinatorMap[rg.combinator]) !== null && _combinatorMap$rg$com !== void 0 ? _combinatorMap$rg$com : rg.combinator
    } : {}))), {
      rules: rg.rules.map(function (r, idx) {
        var _operatorMap$r$operat;
        if (typeof r === 'string') {
          var _combinatorMap$r;
          // independent combinators
          return (_combinatorMap$r = combinatorMap[r]) !== null && _combinatorMap$r !== void 0 ? _combinatorMap$r : r;
        } else if ('rules' in r) {
          // sub-groups
          return processGroup(_extends({}, r, {
            path: [].concat(rg.path, [idx])
          }));
        }
        // rules
        return ruleProcessor(remapProperties(_extends({}, _extends({}, r, {
          path: [].concat(rg.path, [idx])
        }), {
          operator: (_operatorMap$r$operat = operatorMap[r.operator]) !== null && _operatorMap$r$operat !== void 0 ? _operatorMap$r$operat : r.operator
        })));
      })
    });
  };
  return processGroup(_extends({}, query, {
    path: []
  }));
};