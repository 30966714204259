import _extends from "@babel/runtime/helpers/extends";
import { isArray, isNil, each } from "lodash";
var componentsRegistry = new Map();
export var QueryEditorComponents = {
  SCHEMA_BROWSER: "SchemaBrowser",
  QUERY_EDITOR: "QueryEditor"
};
export function registerEditorComponent(componentName, component, dataSourceTypes) {
  if (isNil(dataSourceTypes)) {
    dataSourceTypes = [null]; // use `null` entry for the default set of components
  }

  if (!isArray(dataSourceTypes)) {
    dataSourceTypes = [dataSourceTypes];
  }
  each(dataSourceTypes, function (dataSourceType) {
    var _extends2;
    componentsRegistry.set(dataSourceType, _extends({}, componentsRegistry.get(dataSourceType), (_extends2 = {}, _extends2[componentName] = component, _extends2)));
  });
}
export function getEditorComponents(dataSourceType) {
  return _extends({}, componentsRegistry.get(null), componentsRegistry.get(dataSourceType));
}