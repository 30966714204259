import getOptions from "./getOptions";
import Renderer from "./Renderer";
import Editor from "./Editor";
export default {
  type: "FUNNEL",
  name: "Funnel",
  getOptions: getOptions,
  Renderer: Renderer,
  Editor: Editor,
  defaultRows: 10
};