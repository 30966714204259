var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import DatePicker from '../date-picker';
import warning from '../_util/warning';
const { TimePicker: InternalTimePicker, RangePicker: InternalRangePicker } = DatePicker;
const RangePicker = React.forwardRef((props, ref) => {
    const { dropdownClassName, popupClassName } = props;
    return (React.createElement(InternalRangePicker, Object.assign({}, props, { dropdownClassName: popupClassName || dropdownClassName, picker: "time", mode: undefined, ref: ref })));
});
const TimePicker = React.forwardRef((_a, ref) => {
    var { addon, renderExtraFooter, popupClassName, dropdownClassName } = _a, restProps = __rest(_a, ["addon", "renderExtraFooter", "popupClassName", "dropdownClassName"]);
    const internalRenderExtraFooter = React.useMemo(() => {
        if (renderExtraFooter) {
            return renderExtraFooter;
        }
        if (addon) {
            warning(false, 'TimePicker', '`addon` is deprecated. Please use `renderExtraFooter` instead.');
            return addon;
        }
        return undefined;
    }, [addon, renderExtraFooter]);
    warning(!dropdownClassName, 'TimePicker', '`dropdownClassName` is deprecated which will be removed in next major version. Please use `popupClassName` instead.');
    return (React.createElement(InternalTimePicker, Object.assign({ dropdownClassName: popupClassName || dropdownClassName }, restProps, { mode: undefined, ref: ref, renderExtraFooter: internalRenderExtraFooter })));
});
if (process.env.NODE_ENV !== 'production') {
    TimePicker.displayName = 'TimePicker';
}
TimePicker.RangePicker = RangePicker;
export default TimePicker;
