import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Flows from './flow_list';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function makeMapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
export default connect(makeMapStateToProps)(Flows);