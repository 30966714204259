/**
 * declaration of plugin`s preference
 * when strictPluginMode === true， only declared preference can be obtained from inside plugin.
 *
 * @export
 * @interface ILowCodePluginPreferenceDeclaration
 */

export function isLowCodeRegisterOptions(opts) {
  return opts && ('autoInit' in opts || 'override' in opts);
}