import { getClosestNode, canClickNode } from '@digiforce/dvd-utils';
/**
 * 获取离当前节点最近的可点击节点
 * @param currentNode
 * @param event
 */
export var getClosestClickableNode = function getClosestClickableNode(currentNode, event) {
  var node = currentNode;
  while (node) {
    // 判断当前节点是否可点击
    var canClick = canClickNode(node, event);
    // eslint-disable-next-line no-loop-func
    var lockedNode = getClosestNode(node, function (n) {
      var _node, _n$parent;
      // 假如当前节点就是 locked 状态，要从当前节点的父节点开始查找
      return !!((_node = node) !== null && _node !== void 0 && _node.isLocked ? (_n$parent = n.parent) === null || _n$parent === void 0 ? void 0 : _n$parent.isLocked : n.isLocked);
    });
    if (lockedNode && lockedNode.getId() !== node.getId()) {
      canClick = false;
    }
    if (canClick) {
      break;
    }
    // 对于不可点击的节点, 继续向上找
    node = node.parent;
  }
  return node;
};