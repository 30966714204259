import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import HeaderProfile from './profile';
import { getProfile } from '../../selectors/profile';
function makeMapStateToProps() {
  return function (state, ownProps) {
    var profileState = {
      profile: getProfile(state) || {}
    };
    return Object.assign({}, profileState, _extends({}, profileState, ownProps));
  };
}
export default connect(makeMapStateToProps)(HeaderProfile);