import _extends from "@babel/runtime/helpers/extends";
import Renderer from "./Renderer";
import Editor from "./Editor";
export default {
  type: "BOXPLOT",
  name: "Boxplot (Deprecated)",
  isDeprecated: true,
  getOptions: function getOptions(options) {
    return _extends({}, options);
  },
  Renderer: Renderer,
  Editor: Editor,
  defaultRows: 8,
  minRows: 5
};