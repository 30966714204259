// import { toCSS, toJSON } from 'cssjson';
import { toJSON, toCSS } from '@digiforce/css-json-converter';
function getComputePropertyValue(node, property) {
  var nativeNode = node.getDOMNode();
  if (!nativeNode) return null;
  try {
    return window.getComputedStyle(nativeNode, null).getPropertyValue(property);
  } catch (e) {
    console.error(e);
    return null;
  }
}

/**
 * Get the prompt attribute value
 * @param field
 * @param property
 */
export function getPlaceholderPropertyValue(field, property) {
  var propertyValue = getComputePropertyValue(field.getNode(), toLine(property));
  if (propertyValue != 'auto' && propertyValue != '') {
    if (property != 'backgroundColor') {
      return removeUnit(propertyValue);
    } else {
      return hexify(propertyValue);
    }
  }
  return propertyValue;
}
export function removeUnit(value) {
  if (value != undefined && value != null) {
    return parseInt(value);
  }
  return null;
}
export function addUnit(value, unit) {
  if (value != undefined && value != null) {
    return value + unit;
  } else {
    return null;
  }
}
export function isEmptyValue(value) {
  if (value == undefined || value == null) {
    return true;
  }
  return false;
}

/**
 * Change the hump writing to XX-XX's CSS naming writing
 * @param styleKey
 */
export function toLine(styleKey) {
  return styleKey.replace(/([A-Z])/g, '-$1').toLowerCase();
}
export function toHump(name) {
  return name.replace(/\-(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
}

/**
 * RGBA to hexadecimal
 * @param color
 */
export function hexify(color) {
  var values = color.replace(/rgba?\(/, '').replace(/\)/, '').replace(/[\s+]/g, '').split(',');
  var a = parseFloat(values[3]);
  var r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255);
  var g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255);
  var b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
  return '#' + ('0' + r.toString(16)).slice(-2) + ('0' + g.toString(16)).slice(-2) + ('0' + b.toString(16)).slice(-2);
}
export function parseToCssCode(styleData) {
  var parseStyleData = {};
  for (var styleKey in styleData) {
    parseStyleData[toLine(styleKey)] = styleData[styleKey];
  }
  var cssJson = {
    children: {
      '#main': {
        children: {},
        attributes: parseStyleData
      }
    }
  };
  return toCSS(cssJson);
}
export function parseToStyleData(cssCode) {
  var styleData = {};
  try {
    var _cssJson$children, _cssJson$children$Ma;
    var cssJson = toJSON(cssCode);
    var cssJsonData = cssJson === null || cssJson === void 0 ? void 0 : (_cssJson$children = cssJson.children) === null || _cssJson$children === void 0 ? void 0 : (_cssJson$children$Ma = _cssJson$children['#main']) === null || _cssJson$children$Ma === void 0 ? void 0 : _cssJson$children$Ma.attributes;
    for (var key in cssJsonData) {
      styleData[toHump(key)] = cssJsonData[key];
    }
  } catch (e) {
    console.error(e.message);
  }
  return styleData;
}