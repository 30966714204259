import { isNaN, max, min } from "lodash";
import { useEffect, useState, useMemo } from "react";
import location from "../../../services/location";
import { policy } from "../../../services/policy";
import useImmutableCallback from "../../../lib/hooks/useImmutableCallback";
function getLimitedRefreshRate(refreshRate) {
  var allowedIntervals = policy.getDashboardRefreshIntervals();
  return max([30, min(allowedIntervals), refreshRate]);
}
function getRefreshRateFromUrl() {
  var refreshRate = parseFloat(location.search.refresh);
  return isNaN(refreshRate) ? null : getLimitedRefreshRate(refreshRate);
}
export default function useRefreshRateHandler(refreshDashboard) {
  var _useState = useState(getRefreshRateFromUrl()),
    refreshRate = _useState[0],
    setRefreshRate = _useState[1];

  // `refreshDashboard` may change quite frequently (on every update of `dashboard` instance), but we
  // have to keep the same timer running, because timer will restart when re-creating, and instead of
  // running refresh every N seconds - it will run refresh every N seconds after last dashboard update
  // (which is not right obviously)
  var doRefreshDashboard = useImmutableCallback(refreshDashboard);

  // URL and timer should be updated only when `refreshRate` changes
  useEffect(function () {
    location.setSearch({
      refresh: refreshRate || null
    }, true);
    if (refreshRate) {
      var refreshTimer = setInterval(doRefreshDashboard, refreshRate * 1000);
      return function () {
        return clearInterval(refreshTimer);
      };
    }
  }, [refreshRate, doRefreshDashboard]);
  return useMemo(function () {
    return [refreshRate, function (rate) {
      return setRefreshRate(getLimitedRefreshRate(rate));
    }, function () {
      return setRefreshRate(null);
    }];
  }, [refreshRate]);
}