import { map, isNil, compact, each, isArray, isString } from 'lodash';
var _DigiforceFilters = DigiforceFilters,
  formatFiltersToODataQuery = _DigiforceFilters.formatFiltersToODataQuery;
export var convertFormToFilters = function convertFormToFilters(objectSchema, formValues) {
  // console.log("===convertFormToFilters=objectSchema==", objectSchema);
  var result = map(formValues, function (v, k) {
    var field = objectSchema.fields[k];
    if (field && !isNil(v)) {
      if (["text", "textarea", "autonumber", "email", "url"].indexOf(field.type) > -1 && v) {
        return [k, "contains", v];
      } else if (["boolean", "lookup", "master_detail", "select", "toggle"].indexOf(field.type) > -1) {
        return [k, "=", v];
      } else if (["number", "currency"].indexOf(field.type) > -1) {
        return [k, "=", v];
      } else if (["number_range", "currency_range"].indexOf(field.type) > -1) {
        return [k, "between", v];
      } else if (["date", "datetime"].indexOf(field.type) > -1) {
        return [k, "=", v];
      } else if (["date_range", "datetime_range"].indexOf(field.type) > -1) {
        return [k, "between", v];
      } else {
        return null;
      }
    } else {
      return null;
    }
  });
  return compact(result);
};

/**
 *
 *
 * @param objectSchema 
 *
 */
export var getFilterFormSchema = function getFilterFormSchema(objectSchema, fields) {
  var schemaFields = {};
  each(objectSchema.fields, function (fieldItem, fieldKey) {
    if (fields && fields.length && fields.indexOf(fieldKey) < 0) {
      return;
    }
    var extendProps = {};
    switch (fieldItem.type) {
      case "date":
        extendProps.type = "date_range";
        break;
      case "datetime":
        extendProps.type = "datetime_range";
        break;
      case "number":
        extendProps.type = "number_range";
        break;
      case "currency":
        extendProps.type = "currency_range";
        break;
    }
    schemaFields[fieldKey] = Object.assign({}, fieldItem, extendProps);
  });
  return Object.assign({}, objectSchema, {
    fields: schemaFields
  });
};
export var concatFilters = function concatFilters(filtersA, filtersB) {
  if (filtersB && filtersB.length) {
    if (filtersA && filtersA.length) {
      if (isArray(filtersA) && isArray(filtersB)) {
        return [filtersA, filtersB];
      } else if (isString(filtersA) && isString(filtersB)) {
        return "(" + filtersA + ") and (" + filtersB + ")";
      } else {
        if (isArray(filtersA)) {
          filtersA = formatFiltersToODataQuery(filtersA);
        } else if (isArray(filtersB)) {
          filtersB = formatFiltersToODataQuery(filtersB);
        }
        return "(" + filtersA + ") and (" + filtersB + ")";
      }
    } else {
      return filtersB;
    }
  } else {
    return filtersA;
  }
};