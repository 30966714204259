import { closestCorners, getFirstCollision, KeyboardCode } from "@dnd-kit/core";
var directions = ["ArrowDown", "ArrowRight", "ArrowUp", "ArrowLeft"];
export var coordinateGetter = function coordinateGetter(event, _ref) {
  var _ref$context = _ref.context,
    active = _ref$context.active,
    droppableRects = _ref$context.droppableRects,
    droppableContainers = _ref$context.droppableContainers,
    collisionRect = _ref$context.collisionRect;
  if (directions.includes(event.code)) {
    event.preventDefault();
    if (!active || !collisionRect) {
      return;
    }
    var filteredContainers = [];
    droppableContainers.getEnabled().forEach(function (entry) {
      if (!entry || entry !== null && entry !== void 0 && entry.disabled) {
        return;
      }
      var rect = droppableRects.get(entry.id);
      if (!rect) {
        return;
      }
      var data = entry.data.current;
      if (data) {
        var type = data.type,
          children = data.children;
        if (type === "container" && (children === null || children === void 0 ? void 0 : children.length) > 0) {
          var _active$data$current;
          if (((_active$data$current = active.data.current) === null || _active$data$current === void 0 ? void 0 : _active$data$current.type) !== "container") {
            return;
          }
        }
      }
      switch (event.code) {
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            filteredContainers.push(entry);
          }
          break;
        case KeyboardCode.Up:
          if (collisionRect.top > rect.top) {
            filteredContainers.push(entry);
          }
          break;
        case KeyboardCode.Left:
          if (collisionRect.left >= rect.left + rect.width) {
            filteredContainers.push(entry);
          }
          break;
        case KeyboardCode.Right:
          if (collisionRect.left + collisionRect.width <= rect.left) {
            filteredContainers.push(entry);
          }
          break;
      }
    });
    var collisions = closestCorners({
      active: active,
      collisionRect: collisionRect,
      droppableRects: droppableRects,
      droppableContainers: filteredContainers,
      pointerCoordinates: null
    });
    var closestId = getFirstCollision(collisions, "id");
    if (closestId != null) {
      var newDroppable = droppableContainers.get(closestId);
      var newNode = newDroppable === null || newDroppable === void 0 ? void 0 : newDroppable.node.current;
      var newRect = newDroppable === null || newDroppable === void 0 ? void 0 : newDroppable.rect.current;
      if (newNode && newRect) {
        var _newDroppable$data$cu;
        if (newDroppable.id === "placeholder") {
          return {
            x: newRect.left + (newRect.width - collisionRect.width) / 2,
            y: newRect.top + (newRect.height - collisionRect.height) / 2
          };
        }
        if (((_newDroppable$data$cu = newDroppable.data.current) === null || _newDroppable$data$cu === void 0 ? void 0 : _newDroppable$data$cu.type) === "container") {
          return {
            x: newRect.left + 20,
            y: newRect.top + 74
          };
        }
        return {
          x: newRect.left,
          y: newRect.top
        };
      }
    }
  }
  return undefined;
};