import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import FlowsTree from './flows_tree';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
import { createTreeAction } from '../../actions';
function makeMapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    loadData: function loadData(options) {
      return dispatch(createTreeAction("setNodes", {
        nodes: ownProps.nodes
      }, {
        id: ownProps.treeId
      }));
    }
  };
};
export default connect(makeMapStateToProps, mapDispatchToProps)(FlowsTree);