import { find } from "lodash";
import debug from "debug";
import recordEvent from "./recordEvent";
// import redashIconUrl from "@/assets/images/redash_icon_small.png";

var logger = debug("redash:notifications");
var Notification = window.Notification || null;
if (!Notification) {
  logger("HTML5 notifications are not supported.");
}
var hidden = find(["hidden", "webkitHidden", "mozHidden", "msHidden"], function (prop) {
  return prop in document;
});
function isPageVisible() {
  return !document[hidden];
}
function getPermissions() {
  if (Notification && Notification.permission === "default") {
    Notification.requestPermission();
  }
}
function showNotification(title, content) {
  if (!Notification || isPageVisible() || Notification.permission !== "granted") {
    return;
  }

  // using the 'tag' to avoid showing duplicate notifications
  var notification = new Notification(title, {
    tag: title + content,
    body: content
    // icon: redashIconUrl,
  });

  notification.onclick = function onClick() {
    window.focus();
    this.close();
    recordEvent("click", "notification");
  };
}
export default {
  getPermissions: getPermissions,
  showNotification: showNotification
};