export var FilterType = {
  CONDITION: 'CONDITION',
  LOOP: 'LOOP',
  LOCKED: 'LOCKED',
  HIDDEN: 'HIDDEN'
};
export var FILTER_OPTIONS = [{
  value: FilterType.CONDITION,
  label: 'Condition'
}, {
  value: FilterType.LOOP,
  label: 'Loop'
}, {
  value: FilterType.LOCKED,
  label: 'Locked'
}, {
  value: FilterType.HIDDEN,
  label: 'Hidden'
}];
export var matchTreeNode = function matchTreeNode(treeNode, keywords, filterOps) {
  // 无效节点
  if (!treeNode || !treeNode.node) {
    return false;
  }

  // 过滤条件为空，重置过滤结果
  if (!keywords && filterOps.length === 0) {
    treeNode.setFilterReult({
      filterWorking: false,
      matchChild: false,
      matchSelf: false,
      keywords: ''
    });
    (treeNode.children || []).concat(treeNode.slots || []).forEach(function (childNode) {
      matchTreeNode(childNode, keywords, filterOps);
    });
    return false;
  }
  var node = treeNode.node;

  // 命中过滤选项
  var matchFilterOps = filterOps.length === 0 || !!filterOps.find(function (op) {
    switch (op) {
      case FilterType.CONDITION:
        return node.hasCondition();
      case FilterType.LOOP:
        return node.hasLoop();
      case FilterType.LOCKED:
        return treeNode.locked;
      case FilterType.HIDDEN:
        return treeNode.hidden;
      default:
        return false;
    }
  });

  // 命中节点名
  var matchKeywords = typeof treeNode.titleLabel === 'string' && treeNode.titleLabel.indexOf(keywords) > -1;

  // 同时命中才展示（根结点永远命中）
  var matchSelf = treeNode.isRoot() || matchFilterOps && matchKeywords;

  // 命中子节点
  var matchChild = !!(treeNode.children || []).concat(treeNode.slots || []).map(function (childNode) {
    return matchTreeNode(childNode, keywords, filterOps);
  }).find(Boolean);
  treeNode.setFilterReult({
    filterWorking: true,
    matchChild: matchChild,
    matchSelf: matchSelf,
    keywords: keywords
  });
  return matchSelf || matchChild;
};