import { capitalize, isNil, map, get } from "lodash";
import AceEditor from "react-ace";
import ace from "ace-builds";
// import 'ace-builds/webpack-resolver'
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-json";
// import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-textmate";
import "ace-builds/src-noconflict/ext-searchbox";
var langTools = ace.acequire("ace/ext/language_tools");
var snippetsModule = ace.acequire("ace/snippets");

// By default Ace will try to load snippet files for the different modes and fail.
// We don't need them, so we use these placeholders until we define our own.
function defineDummySnippets(mode) {
  ace.define("ace/snippets/" + mode, ["require", "exports", "module"], function (require, exports) {
    exports.snippetText = "";
    exports.scope = mode;
  });
}

// defineDummySnippets("python");
defineDummySnippets("sql");
defineDummySnippets("json");
defineDummySnippets("yaml");
function buildTableColumnKeywords(table) {
  var keywords = [];
  table.columns.forEach(function (column) {
    var columnName = get(column, "name");
    keywords.push({
      name: table.name + "." + columnName,
      value: table.name + "." + columnName,
      score: 100,
      meta: capitalize(get(column, "type", "Column"))
    });
  });
  return keywords;
}
function buildKeywordsFromSchema(schema) {
  var tableKeywords = [];
  var columnKeywords = {};
  var tableColumnKeywords = {};
  schema.forEach(function (table) {
    tableKeywords.push({
      name: table.name,
      value: table.name,
      score: 100,
      meta: "Table"
    });
    tableColumnKeywords[table.name] = buildTableColumnKeywords(table);
    table.columns.forEach(function (c) {
      var columnName = get(c, "name", c);
      columnKeywords[columnName] = capitalize(get(c, "type", "Column"));
    });
  });
  return {
    table: tableKeywords,
    column: map(columnKeywords, function (v, k) {
      return {
        name: k,
        value: k,
        score: 50,
        meta: v
      };
    }),
    tableColumn: tableColumnKeywords
  };
}
var schemaCompleterKeywords = {};
export function updateSchemaCompleter(editorKey, schema) {
  if (schema === void 0) {
    schema = null;
  }
  schemaCompleterKeywords[editorKey] = isNil(schema) ? null : buildKeywordsFromSchema(schema);
}
langTools.setCompleters([langTools.snippetCompleter, langTools.keyWordCompleter, langTools.textCompleter, {
  identifierRegexps: [/[a-zA-Z_0-9.\-\u00A2-\uFFFF]/],
  getCompletions: function getCompletions(editor, session, pos, prefix, callback) {
    var _ref = schemaCompleterKeywords[editor.id] || {
        table: [],
        column: [],
        tableColumn: []
      },
      table = _ref.table,
      column = _ref.column,
      tableColumn = _ref.tableColumn;
    if (prefix.length === 0 || table.length === 0) {
      callback(null, []);
      return;
    }
    if (prefix[prefix.length - 1] === ".") {
      var tableName = prefix.substring(0, prefix.length - 1);
      callback(null, table.concat(tableColumn[tableName]));
      return;
    }
    callback(null, table.concat(column));
  }
}]);
export { AceEditor, langTools, snippetsModule };