import { combineReducers } from 'redux';
import { initialRequestState, handleRequest } from './helpers';
import { BOOTSTRAP_STATE_CHANGE_ACTION } from '../../actions/views/bootstrap';
import { BootstrapTypes } from '../../action_types';
function getBootStrap(state, action) {
  if (state === void 0) {
    state = initialRequestState();
  }
  if (action.type === BOOTSTRAP_STATE_CHANGE_ACTION) {
    var payload = action.payload;
    var partialStateValue = payload.partialStateValue;
    var error = partialStateValue.error ? partialStateValue.error.toString() : '';
    var newState = handleRequest(BootstrapTypes.GET_BOOTSTRAP_REQUEST, BootstrapTypes.GET_BOOTSTRAP_SUCCESS, BootstrapTypes.GET_BOOTSTRAP_FAILURE, state, {
      type: payload.partialStateName,
      error: error,
      data: partialStateValue
    });
    return newState;
  }
  return state;
}
export default combineReducers({
  getBootStrap: getBootStrap
});