function propertyNameRequiresQuotes(propertyName) {
  try {
    var context = {
      worksWithoutQuotes: false
    };

    // eslint-disable-next-line no-new-func
    new Function('ctx', "ctx.worksWithoutQuotes = {" + propertyName + ": true}['" + propertyName + "']")();
    return !context.worksWithoutQuotes;
  } catch (ex) {
    return true;
  }
}
function quoteString(str, _ref) {
  var doubleQuote = _ref.doubleQuote;
  return doubleQuote ? "\"" + str.replace(/"/g, '\\"') + "\"" : "'" + str.replace(/'/g, "\\'") + "'";
}
export function valueToSource(value, _temp) {
  var _ref2 = _temp === void 0 ? {} : _temp,
    _ref2$circularReferen = _ref2.circularReferenceToken,
    circularReferenceToken = _ref2$circularReferen === void 0 ? 'CIRCULAR_REFERENCE' : _ref2$circularReferen,
    _ref2$doubleQuote = _ref2.doubleQuote,
    doubleQuote = _ref2$doubleQuote === void 0 ? true : _ref2$doubleQuote,
    _ref2$includeFunction = _ref2.includeFunctions,
    includeFunctions = _ref2$includeFunction === void 0 ? true : _ref2$includeFunction,
    _ref2$includeUndefine = _ref2.includeUndefinedProperties,
    includeUndefinedProperties = _ref2$includeUndefine === void 0 ? false : _ref2$includeUndefine,
    _ref2$indentLevel = _ref2.indentLevel,
    indentLevel = _ref2$indentLevel === void 0 ? 0 : _ref2$indentLevel,
    _ref2$indentString = _ref2.indentString,
    indentString = _ref2$indentString === void 0 ? '  ' : _ref2$indentString,
    _ref2$lineEnding = _ref2.lineEnding,
    lineEnding = _ref2$lineEnding === void 0 ? '\n' : _ref2$lineEnding,
    _ref2$visitedObjects = _ref2.visitedObjects,
    visitedObjects = _ref2$visitedObjects === void 0 ? new Set() : _ref2$visitedObjects;
  switch (typeof value) {
    case 'boolean':
      return value ? indentString.repeat(indentLevel) + "true" : indentString.repeat(indentLevel) + "false";
    case 'function':
      if (includeFunctions) {
        return "" + indentString.repeat(indentLevel) + value;
      }
      return null;
    case 'number':
      return "" + indentString.repeat(indentLevel) + value;
    case 'object':
      if (!value) {
        return indentString.repeat(indentLevel) + "null";
      }
      if (visitedObjects.has(value)) {
        return "" + indentString.repeat(indentLevel) + circularReferenceToken;
      }
      if (value instanceof Date) {
        return indentString.repeat(indentLevel) + "new Date(" + quoteString(value.toISOString(), {
          doubleQuote: doubleQuote
        }) + ")";
      }
      if (value instanceof Map) {
        return value.size ? indentString.repeat(indentLevel) + "new Map(" + valueToSource([].concat(value), {
          circularReferenceToken: circularReferenceToken,
          doubleQuote: doubleQuote,
          includeFunctions: includeFunctions,
          includeUndefinedProperties: includeUndefinedProperties,
          indentLevel: indentLevel,
          indentString: indentString,
          lineEnding: lineEnding,
          visitedObjects: new Set([value].concat(visitedObjects))
        }).slice(indentLevel * indentString.length) + ")" : indentString.repeat(indentLevel) + "new Map()";
      }
      if (value instanceof RegExp) {
        return indentString.repeat(indentLevel) + "/" + value.source + "/" + value.flags;
      }
      if (value instanceof Set) {
        return value.size ? indentString.repeat(indentLevel) + "new Set(" + valueToSource([].concat(value), {
          circularReferenceToken: circularReferenceToken,
          doubleQuote: doubleQuote,
          includeFunctions: includeFunctions,
          includeUndefinedProperties: includeUndefinedProperties,
          indentLevel: indentLevel,
          indentString: indentString,
          lineEnding: lineEnding,
          visitedObjects: new Set([value].concat(visitedObjects))
        }).slice(indentLevel * indentString.length) + ")" : indentString.repeat(indentLevel) + "new Set()";
      }
      if (Array.isArray(value)) {
        if (!value.length) {
          return indentString.repeat(indentLevel) + "[]";
        }
        var itemsStayOnTheSameLine = value.every(function (item) {
          return typeof item === 'object' && item && !(item instanceof Date) && !(item instanceof Map) && !(item instanceof RegExp) && !(item instanceof Set) && (Object.keys(item).length || value.length === 1);
        });
        var previousIndex = null;
        value = value.reduce(function (items, item, index) {
          if (previousIndex !== null) {
            for (var i = index - previousIndex - 1; i > 0; i -= 1) {
              items.push(indentString.repeat(indentLevel + 1));
            }
          }
          previousIndex = index;
          item = valueToSource(item, {
            circularReferenceToken: circularReferenceToken,
            doubleQuote: doubleQuote,
            includeFunctions: includeFunctions,
            includeUndefinedProperties: includeUndefinedProperties,
            indentLevel: itemsStayOnTheSameLine ? indentLevel : indentLevel + 1,
            indentString: indentString,
            lineEnding: lineEnding,
            visitedObjects: new Set([value].concat(visitedObjects))
          });
          if (item === null) {
            items.push(indentString.repeat(indentLevel + 1));
          } else if (itemsStayOnTheSameLine) {
            items.push(item.slice(indentLevel * indentString.length));
          } else {
            items.push(item);
          }
          return items;
        }, []);
        return itemsStayOnTheSameLine ? indentString.repeat(indentLevel) + "[" + value.join(', ') + "]" : indentString.repeat(indentLevel) + "[" + lineEnding + value.join("," + lineEnding) + lineEnding + indentString.repeat(indentLevel) + "]";
      }
      value = Object.keys(value).reduce(function (entries, propertyName) {
        var propertyValue = value[propertyName];
        var propertyValueString = typeof propertyValue !== 'undefined' || includeUndefinedProperties ? valueToSource(value[propertyName], {
          circularReferenceToken: circularReferenceToken,
          doubleQuote: doubleQuote,
          includeFunctions: includeFunctions,
          includeUndefinedProperties: includeUndefinedProperties,
          indentLevel: indentLevel + 1,
          indentString: indentString,
          lineEnding: lineEnding,
          visitedObjects: new Set([value].concat(visitedObjects))
        }) : null;
        if (propertyValueString) {
          var quotedPropertyName = propertyNameRequiresQuotes(propertyName) ? quoteString(propertyName, {
            doubleQuote: doubleQuote
          }) : propertyName;
          var trimmedPropertyValueString = propertyValueString.slice((indentLevel + 1) * indentString.length);
          if (typeof propertyValue === 'function' && trimmedPropertyValueString.startsWith(propertyName + "()")) {
            entries.push("" + indentString.repeat(indentLevel + 1) + quotedPropertyName + " " + trimmedPropertyValueString.slice(propertyName.length));
          } else {
            entries.push("" + indentString.repeat(indentLevel + 1) + quotedPropertyName + ": " + trimmedPropertyValueString);
          }
        }
        return entries;
      }, []);
      return value.length ? indentString.repeat(indentLevel) + "{" + lineEnding + value.join("," + lineEnding) + lineEnding + indentString.repeat(indentLevel) + "}" : indentString.repeat(indentLevel) + "{}";
    case 'string':
      return "" + indentString.repeat(indentLevel) + quoteString(value, {
        doubleQuote: doubleQuote
      });
    case 'symbol':
      {
        var key = Symbol.keyFor(value);
        if (typeof key === 'string') {
          return indentString.repeat(indentLevel) + "Symbol.for(" + quoteString(key, {
            doubleQuote: doubleQuote
          }) + ")";
        }
        key = value.toString().slice(7, -1);
        if (key) {
          return indentString.repeat(indentLevel) + "Symbol(" + quoteString(key, {
            doubleQuote: doubleQuote
          }) + ")";
        }
        return indentString.repeat(indentLevel) + "Symbol()";
      }
    case 'undefined':
      return indentString.repeat(indentLevel) + "undefined";
  }
}
export function getSource(value) {
  if (value && value.__source) {
    return value.__source;
  }
  var source = valueToSource(value);
  if (source === 'undefined') {
    source = '';
  }
  if (value) {
    try {
      value.__source = source;
    } catch (ex) {
      console.error(ex);
    }
  }
  return source;
}