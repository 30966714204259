import _extends from "@babel/runtime/helpers/extends";
import { filterFieldsByComparator } from '../internal/filterFieldsByComparator';
import { getValueSourcesUtil } from '../internal/getValueSourcesUtil';
import { uniqByName } from '../internal/uniq';
import { isOptionGroupArray } from './optGroupUtils';
export var isPojo = function isPojo(obj) {
  return obj === null || typeof obj !== 'object' ? false : Object.getPrototypeOf(obj) === Object.prototype;
};
export var getFieldsArray = function getFieldsArray(fields) {
  var fieldsFlat = [];
  var fieldsArray = !fields ? [] : Array.isArray(fields) ? fields : Object.keys(fields).map(function (fld) {
    return _extends({}, fields[fld], {
      name: fld
    });
  }).sort(function (a, b) {
    return a.label.localeCompare(b.label);
  });
  if (isOptionGroupArray(fieldsArray)) {
    var _fieldsFlat;
    fieldsFlat = uniqByName((_fieldsFlat = fieldsFlat).concat.apply(_fieldsFlat, fieldsArray.map(function (opt) {
      return opt.options;
    })));
  } else {
    fieldsFlat = uniqByName(fieldsArray);
  }
  return fieldsFlat;
};
export function fieldIsValidUtil(_ref) {
  var fieldsFlat = _ref.fieldsFlat,
    fieldName = _ref.fieldName,
    operator = _ref.operator,
    subordinateFieldName = _ref.subordinateFieldName,
    getValueSources = _ref.getValueSources;
  // If fields option was an empty array or undefined, then all identifiers
  // are considered valid.
  if (fieldsFlat.length === 0) return true;
  var valid = false;
  var primaryField = fieldsFlat.find(function (ff) {
    return ff.name === fieldName;
  });
  if (primaryField) {
    if (!subordinateFieldName && operator !== 'notNull' && operator !== 'null' && !getValueSourcesUtil(primaryField, operator, getValueSources).some(function (vs) {
      return vs === 'value';
    })) {
      valid = false;
    } else {
      valid = true;
    }
    if (valid && !!subordinateFieldName) {
      if (getValueSourcesUtil(primaryField, operator, getValueSources).some(function (vs) {
        return vs === 'field';
      }) && fieldName !== subordinateFieldName) {
        var validSubordinateFields = filterFieldsByComparator(primaryField, fieldsFlat, operator);
        if (!validSubordinateFields.find(function (vsf) {
          return vsf.name === subordinateFieldName;
        })) {
          valid = false;
        }
      } else {
        valid = false;
      }
    }
  }
  return valid;
}