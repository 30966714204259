import { GRIDMODAL_STATE_CHANGE_ACTION } from '../../actions/views/grid_modal';
// import _ from 'lodash'

function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === GRIDMODAL_STATE_CHANGE_ACTION) {
    var _Object$assign;
    var payload = action.payload;
    return Object.assign({}, state, (_Object$assign = {}, _Object$assign[payload.partialStateName] = payload.partialStateValue, _Object$assign));
  }
  return state;
}
;
export default reducer;