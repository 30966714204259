import { noop, extend, pick } from "lodash";
import { useCallback, useState } from "react";
import url from "url";
import qs from "query-string";
import { Query } from "../../../services/query";
function keepCurrentUrlParams(targetUrl) {
  var currentUrlParams = qs.parse(window.location.search);
  targetUrl = url.parse(targetUrl);
  var targetUrlParams = qs.parse(targetUrl.search);
  return url.format(extend(pick(targetUrl, ["protocol", "auth", "host", "pathname", "hash"]), {
    search: qs.stringify(extend(currentUrlParams, targetUrlParams))
  }));
}
export default function useDuplicateQuery(query) {
  var _useState = useState(false),
    isDuplicating = _useState[0],
    setIsDuplicating = _useState[1];
  var duplicateQuery = useCallback(function () {
    // To prevent opening the same tab, name must be unique for each browser
    var tabName = "duplicatedQueryTab/" + Math.random().toString();

    // We should open tab here because this moment is a part of user interaction;
    // later browser will block such attempts
    var tab = window.open("", tabName);
    setIsDuplicating(true);
    Query.fork({
      id: query.id
    }).then(function (newQuery) {
      tab.location = keepCurrentUrlParams(newQuery.getUrl(true));
    })["finally"](function () {
      setIsDuplicating(false);
    });
  }, [query.id]);
  return [isDuplicating, isDuplicating ? noop : duplicateQuery];
}