import { debounce, find, has, isMatch, map, pickBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import location from "../../../services/location";
import notification from "../../../components/notification";
export var DashboardStatusEnum = {
  SAVED: "saved",
  SAVING: "saving",
  SAVING_FAILED: "saving_failed"
};
function getChangedPositions(widgets, nextPositions) {
  if (nextPositions === void 0) {
    nextPositions = {};
  }
  return pickBy(nextPositions, function (nextPos, widgetId) {
    var widget = find(widgets, {
      _id: widgetId
    });
    var prevPos = widget.options.position;
    return !isMatch(prevPos, nextPos);
  });
}
export default function useEditModeHandler(canEditDashboard, widgets) {
  var _useState = useState(canEditDashboard && has(location.search, "edit")),
    editingLayout = _useState[0],
    setEditingLayout = _useState[1];
  var _useState2 = useState(DashboardStatusEnum.SAVED),
    dashboardStatus = _useState2[0],
    setDashboardStatus = _useState2[1];
  var _useState3 = useState([]),
    recentPositions = _useState3[0],
    setRecentPositions = _useState3[1];
  var _useState4 = useState(false),
    doneBtnClickedWhileSaving = _useState4[0],
    setDoneBtnClickedWhileSaving = _useState4[1];
  useEffect(function () {
    location.setSearch({
      edit: editingLayout ? true : null
    }, true);
  }, [editingLayout]);
  useEffect(function () {
    if (doneBtnClickedWhileSaving && dashboardStatus === DashboardStatusEnum.SAVED) {
      setDoneBtnClickedWhileSaving(false);
      setEditingLayout(false);
    }
  }, [doneBtnClickedWhileSaving, dashboardStatus]);
  var saveDashboardLayout = useCallback(function (positions) {
    if (!canEditDashboard) {
      setDashboardStatus(DashboardStatusEnum.SAVED);
      return;
    }
    var changedPositions = getChangedPositions(widgets, positions);
    setDashboardStatus(DashboardStatusEnum.SAVING);
    setRecentPositions(positions);
    var saveChangedWidgets = map(changedPositions, function (position, id) {
      // find widget
      var widget = find(widgets, {
        _id: id
      });

      // skip already deleted widget
      if (!widget) {
        return Promise.resolve();
      }
      return widget.save("options", {
        position: position
      });
    });
    return Promise.all(saveChangedWidgets).then(function () {
      return setDashboardStatus(DashboardStatusEnum.SAVED);
    })["catch"](function () {
      setDashboardStatus(DashboardStatusEnum.SAVING_FAILED);
      notification.error.call({}, "Error saving changes.");
    });
  }, [canEditDashboard, widgets]);
  var saveDashboardLayoutDebounced = useCallback(function (args) {
    setDashboardStatus(DashboardStatusEnum.SAVING);
    return debounce(function () {
      return saveDashboardLayout(args);
    }, 2000)();
  }, [saveDashboardLayout]);
  var retrySaveDashboardLayout = useCallback(function () {
    return saveDashboardLayout(recentPositions);
  }, [recentPositions, saveDashboardLayout]);
  var setEditing = useCallback(function (editing) {
    if (!editing && dashboardStatus !== DashboardStatusEnum.SAVED) {
      setDoneBtnClickedWhileSaving(true);
      return;
    }
    setEditingLayout(canEditDashboard && editing);
  }, [dashboardStatus, canEditDashboard]);
  return {
    editingLayout: canEditDashboard && editingLayout,
    setEditingLayout: setEditing,
    saveDashboardLayout: editingLayout ? saveDashboardLayoutDebounced : saveDashboardLayout,
    retrySaveDashboardLayout: retrySaveDashboardLayout,
    doneBtnClickedWhileSaving: doneBtnClickedWhileSaving,
    dashboardStatus: dashboardStatus
  };
}