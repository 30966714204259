import _extends from "@babel/runtime/helpers/extends";
import { isNil, extend, each, includes, map, sortBy, toString } from "lodash";
import chooseTextColorForBackground from "../../../lib/chooseTextColorForBackground";
import { ColorPaletteArray } from "../../ColorPalette";
import { cleanNumber, normalizeValue, getSeriesAxis } from "./utils";
function getSeriesColor(seriesOptions, seriesIndex) {
  return seriesOptions.color || ColorPaletteArray[seriesIndex % ColorPaletteArray.length];
}
function getHoverInfoPattern(options) {
  var hasX = /{{\s*@@x\s*}}/.test(options.textFormat);
  var hasName = /{{\s*@@name\s*}}/.test(options.textFormat);
  var result = "text";
  if (!hasX) result += "+x";
  if (!hasName) result += "+name";
  return result;
}
function prepareBarSeries(series, options, additionalOptions) {
  series.type = "bar";
  series.offsetgroup = toString(additionalOptions.index);
  if (options.showDataLabels) {
    series.textposition = "inside";
  }
  return series;
}
function prepareLineSeries(series, options) {
  series.mode = "lines" + (options.showDataLabels ? "+text" : "");
  return series;
}
function prepareAreaSeries(series, options) {
  series.mode = "lines" + (options.showDataLabels ? "+text" : "");
  series.fill = options.series.stacking ? "tonexty" : "tozeroy";
  return series;
}
function prepareScatterSeries(series, options) {
  series.type = "scatter";
  series.mode = "markers" + (options.showDataLabels ? "+text" : "");
  return series;
}
function prepareBubbleSeries(series, options, _ref) {
  var seriesColor = _ref.seriesColor,
    data = _ref.data;
  var coefficient = options.coefficient || 1;
  series.mode = "markers";
  series.marker = {
    color: seriesColor,
    size: map(data, function (i) {
      return i.size * coefficient;
    }),
    sizemode: options.sizemode || "diameter"
  };
  return series;
}
function prepareBoxSeries(series, options, _ref2) {
  var seriesColor = _ref2.seriesColor;
  series.type = "box";
  series.mode = "markers";
  series.boxpoints = "outliers";
  series.hoverinfo = false;
  series.marker = {
    color: seriesColor,
    size: 3
  };
  if (options.showpoints) {
    series.boxpoints = "all";
    series.jitter = 0.3;
    series.pointpos = -1.8;
  }
  return series;
}
function prepareSeries(series, options, additionalOptions) {
  var _additionalOptions = additionalOptions,
    hoverInfoPattern = _additionalOptions.hoverInfoPattern,
    index = _additionalOptions.index;
  var seriesOptions = extend({
    type: options.globalSeriesType,
    yAxis: 0
  }, options.seriesOptions[series.name]);
  var seriesColor = getSeriesColor(seriesOptions, index);
  var seriesYAxis = getSeriesAxis(series, options);

  // Sort by x - `Map` preserves order of items
  var data = options.sortX ? sortBy(series.data, function (d) {
    return normalizeValue(d.x, options.xAxis.type);
  }) : series.data;

  // For bubble/scatter charts `y` may be any (similar to `x`) - numeric is only bubble size;
  // for other types `y` is always number
  var cleanYValue = includes(["bubble", "scatter"], seriesOptions.type) ? normalizeValue : function (v) {
    v = cleanNumber(v);
    return options.missingValuesAsZero && isNil(v) ? 0.0 : v;
  };
  var sourceData = new Map();
  var xValues = [];
  var yValues = [];
  var yErrorValues = [];
  each(data, function (row) {
    var x = normalizeValue(row.x, options.xAxis.type); // number/datetime/category
    var y = cleanYValue(row.y, seriesYAxis === "y2" ? options.yAxis[1].type : options.yAxis[0].type); // depends on series type!
    var yError = cleanNumber(row.yError); // always number
    var size = cleanNumber(row.size); // always number
    sourceData.set(x, {
      x: x,
      y: y,
      yError: yError,
      size: size,
      yPercent: null,
      // will be updated later
      row: row
    });
    xValues.push(x);
    yValues.push(y);
    yErrorValues.push(yError);
  });
  var plotlySeries = {
    visible: true,
    hoverinfo: hoverInfoPattern,
    x: xValues,
    y: yValues,
    error_y: {
      array: yErrorValues,
      color: seriesColor
    },
    name: seriesOptions.name || series.name,
    marker: {
      color: seriesColor
    },
    insidetextfont: {
      color: chooseTextColorForBackground(seriesColor)
    },
    yaxis: seriesYAxis,
    sourceData: sourceData
  };
  additionalOptions = _extends({}, additionalOptions, {
    seriesColor: seriesColor,
    data: data
  });
  switch (seriesOptions.type) {
    case "column":
      return prepareBarSeries(plotlySeries, options, additionalOptions);
    case "line":
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      return prepareLineSeries(plotlySeries, options, additionalOptions);
    case "area":
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      return prepareAreaSeries(plotlySeries, options, additionalOptions);
    case "scatter":
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      return prepareScatterSeries(plotlySeries, options, additionalOptions);
    case "bubble":
      return prepareBubbleSeries(plotlySeries, options, additionalOptions);
    case "box":
      return prepareBoxSeries(plotlySeries, options, additionalOptions);
    default:
      return plotlySeries;
  }
}
export default function prepareDefaultData(seriesList, options) {
  var additionalOptions = {
    hoverInfoPattern: getHoverInfoPattern(options)
  };
  return map(seriesList, function (series, index) {
    return prepareSeries(series, options, _extends({}, additionalOptions, {
      index: index
    }));
  });
}