import { trim } from "lodash";
import sqlFormatter from "sql-formatter";
var QueryFormatters = {
  sql: function sql(queryText) {
    return sqlFormatter.format(trim(queryText));
  },
  json: function json(queryText) {
    return JSON.stringify(JSON.parse(queryText), null, 4);
  }
};
export function isFormatQueryAvailable(syntax) {
  return syntax in QueryFormatters;
}
export function formatQuery(queryText, syntax) {
  if (!isFormatQueryAvailable(syntax)) {
    return queryText;
  }
  var formatter = QueryFormatters[syntax];
  return formatter(queryText);
}