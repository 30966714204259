import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import DataSource from "../../../services/data-source";
import notification from "../../../components/notification";
function getSchema(dataSource, refresh) {
  if (refresh === void 0) {
    refresh = undefined;
  }
  if (!dataSource) {
    return Promise.resolve([]);
  }
  return DataSource.fetchSchema(dataSource, refresh)["catch"](function () {
    notification.error("Schema refresh failed.", "Please try again later.");
    return Promise.resolve([]);
  });
}
export default function useDataSourceSchema(dataSource) {
  var _useState = useState([]),
    schema = _useState[0],
    setSchema = _useState[1];
  var _useState2 = useState(true),
    loadingSchema = _useState2[0],
    setLoadingSchema = _useState2[1];
  var refreshSchemaTokenRef = useRef(null);
  var reloadSchema = useCallback(function (refresh) {
    if (refresh === void 0) {
      refresh = undefined;
    }
    setLoadingSchema(true);
    var refreshToken = Math.random().toString(36).substr(2);
    refreshSchemaTokenRef.current = refreshToken;
    getSchema(dataSource, refresh).then(function (data) {
      if (refreshSchemaTokenRef.current === refreshToken) {
        setSchema(data);
      }
    })["finally"](function () {
      if (refreshSchemaTokenRef.current === refreshToken) {
        setLoadingSchema(false);
      }
    });
  }, [dataSource]);
  useEffect(function () {
    reloadSchema();
  }, [reloadSchema]);
  useEffect(function () {
    return function () {
      // cancel pending operations
      refreshSchemaTokenRef.current = null;
    };
  }, []);
  return useMemo(function () {
    return [schema, loadingSchema, reloadSchema];
  }, [schema, loadingSchema, reloadSchema]);
}