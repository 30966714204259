import { loadEntitiesDataRequest } from '../records_request';
import { dataServicesSelector } from '../../selectors';
import { createAction as baseCreateAction } from '../base';
export var ORGANIZATIONS_STATE_CHANGE_ACTION = 'ORGANIZATIONS_STATE_CHANGE';
export var createOrganizationsAction = function createOrganizationsAction(partialStateName, partialStateValue, options) {
  return baseCreateAction(ORGANIZATIONS_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
};
export function loadOrganizationsEntitiesData(options) {
  if (options === void 0) {
    options = {};
  }
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, ORGANIZATIONS_STATE_CHANGE_ACTION, service, options);
  };
}