/**
 * Determines if this is either a RuleGroupType or RuleGroupTypeIC.
 * `'rules' in query` can be used as an alternative.
 */
export var isRuleGroup = function isRuleGroup(rg) {
  return typeof rg === 'object' && 'rules' in rg && Array.isArray(rg.rules);
};
export var isRuleGroupType = function isRuleGroupType(rg) {
  return isRuleGroup(rg) && 'combinator' in rg;
};
export var isRuleGroupTypeIC = function isRuleGroupTypeIC(rg) {
  return isRuleGroup(rg) && !('combinator' in rg);
};