import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Dashboard from './bootstrap';
import { loadBootstrapEntitiesData } from '../../actions';
import { entityStateSelector, isRequestStarted } from '../../selectors';
function mapStateToProps() {
  return function (state, ownProps) {
    var space = entityStateSelector(state, "space") || null;
    return Object.assign({
      isBootstrapLoaded: !!space
    }, _extends({}, ownProps, {
      isRequestStarted: isRequestStarted(state)
    }));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    // handleChanged: (event: any, data: any) => dispatch(createActionBootstrap('changeSpace', data.spaceId)),
    loadBootstrap: function loadBootstrap(options) {
      dispatch(loadBootstrapEntitiesData(options));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);