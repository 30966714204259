import { filter, find, toString } from "lodash";
import { useState, useMemo, useEffect } from "react";
import DataSource from "../../../services/data-source";
export default function useQueryDataSources(query) {
  var _useState = useState([]),
    allDataSources = _useState[0],
    setAllDataSources = _useState[1];
  var _useState2 = useState(false),
    dataSourcesLoaded = _useState2[0],
    setDataSourcesLoaded = _useState2[1];
  var dataSources = useMemo(function () {
    return filter(allDataSources, function (ds) {
      return !ds.view_only || ds.id === query.data_source_id;
    });
  }, [allDataSources, query.data_source_id]);
  var dataSource = useMemo(function () {
    return find(dataSources, function (ds) {
      return toString(ds.id) === toString(query.data_source_id);
    }) || null;
  }, [query.data_source_id, dataSources]);
  useEffect(function () {
    var cancelDataSourceLoading = false;
    DataSource.query().then(function (data) {
      if (!cancelDataSourceLoading) {
        setDataSourcesLoaded(true);
        setAllDataSources(data);
      }
    });
    return function () {
      cancelDataSourceLoading = true;
    };
  }, []);
  return useMemo(function () {
    return {
      dataSourcesLoaded: dataSourcesLoaded,
      dataSources: dataSources,
      dataSource: dataSource
    };
  }, [dataSourcesLoaded, dataSources, dataSource]);
}