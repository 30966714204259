export function includeSlot(node, slotName) {
  var _node$slots = node.slots,
    slots = _node$slots === void 0 ? [] : _node$slots;
  return slots.some(function (slot) {
    var _slot$getExtraProp;
    return slotName && slotName === (slot === null || slot === void 0 ? void 0 : (_slot$getExtraProp = slot.getExtraProp('name')) === null || _slot$getExtraProp === void 0 ? void 0 : _slot$getExtraProp.getAsString());
  });
}
export function removeSlot(node, slotName) {
  var _node$slots2 = node.slots,
    slots = _node$slots2 === void 0 ? [] : _node$slots2;
  return slots.some(function (slot, idx) {
    var _slot$getExtraProp2;
    if (slotName && slotName === (slot === null || slot === void 0 ? void 0 : (_slot$getExtraProp2 = slot.getExtraProp('name')) === null || _slot$getExtraProp2 === void 0 ? void 0 : _slot$getExtraProp2.getAsString())) {
      slot.remove();
      slots.splice(idx, 1);
      return true;
    }
    return false;
  });
}