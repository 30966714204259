import _extends from "@babel/runtime/helpers/extends";
export function legacyIssues(metadata) {
  var devMode = metadata.devMode;
  return _extends({}, metadata, {
    devMode: devMode === null || devMode === void 0 ? void 0 : devMode.replace(/(low|pro)code/, '$1Code')
  });
}
export function componentDefaults(metadata) {
  var configure = metadata.configure,
    componentName = metadata.componentName;
  var _configure$component = configure.component,
    component = _configure$component === void 0 ? {} : _configure$component;
  if (!component.nestingRule) {
    var m;
    // uri match xx.Group set subcontrolling: true, childWhiteList
    // eslint-disable-next-line no-cond-assign
    if (m = /^(.+)\.Group$/.exec(componentName)) {
      // component.subControlling = true;
      component.nestingRule = {
        childWhitelist: ["" + m[1]]
      };
      // eslint-disable-next-line no-cond-assign
    } else if (m = /^(.+)\.Node$/.exec(componentName)) {
      // uri match xx.Node set selfControlled: false, parentWhiteList
      // component.selfControlled = false;
      component.nestingRule = {
        parentWhitelist: ["" + m[1], componentName]
      };
      // eslint-disable-next-line no-cond-assign
    } else if (m = /^(.+)\.(Item|Node|Option)$/.exec(componentName)) {
      // uri match .Item .Node .Option set parentWhiteList
      component.nestingRule = {
        parentWhitelist: ["" + m[1]]
      };
    }
  }
  // if (component.isModal == null && /Dialog/.test(componentName)) {
  //   component.isModal = true;
  // }
  return _extends({}, metadata, {
    configure: _extends({}, configure, {
      component: component
    })
  });
}