import { FAVORITES_STATE_CHANGE_ACTION } from '../../actions/views/favorites';
import { FavoritesTypes } from '../../action_types';
function transformEntityState(state, payload) {
  return Object.assign({}, state, {
    records: payload.partialStateValue.records,
    totalCount: payload.partialStateValue.totalCount
  });
}
function changeActionSelected(state, payload) {
  return Object.assign({}, state, {
    actionSelected: payload.partialStateValue.actionSelected
  });
}
function changeActionDisabled(state, payload) {
  return Object.assign({}, state, {
    actionDisabled: payload.partialStateValue.actionDisabled
  });
}
function changeRecords(state, payload) {
  return Object.assign({}, state, {
    records: payload.partialStateValue.records,
    totalCount: payload.partialStateValue.records.length
  });
}
function changeAssistiveText(state, payload) {
  return Object.assign({}, state, {
    assistiveText: payload.partialStateValue.assistiveText
  });
}
function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === FAVORITES_STATE_CHANGE_ACTION) {
    var payload = action.payload;
    switch (payload.partialStateName) {
      case FavoritesTypes.GET_FAVORITES_SUCCESS:
        return transformEntityState(state, payload);
      case FavoritesTypes.CHANGE_FAVORITES_ACTIONSELECTED:
        return changeActionSelected(state, payload);
      case FavoritesTypes.CHANGE_FAVORITES_ACTIONDISABLED:
        return changeActionDisabled(state, payload);
      case FavoritesTypes.CHANGE_FAVORITES_RECORDS:
        return changeRecords(state, payload);
      case FavoritesTypes.CHANGE_FAVORITES_ASSISTIVETEXT:
        return changeAssistiveText(state, payload);
      default:
        break;
    }
    return state;
  }
  return state;
}
export default reducer;