export var configTable = {
  name: 'Digiforce:Table',
  inputs: [{
    name: 'columns',
    friendlyName: "Column configuration",
    type: 'list',
    required: true,
    helperText: 'Table column configuration description',
    subFields: [{
      name: 'title',
      type: 'string',
      helperText: 'Title'
    }, {
      name: 'dataIndex',
      type: 'string',
      helperText: 'Display field of the data record, support nest path by string array'
    }, {
      name: 'key',
      type: 'string',
      helperText: 'Unique key of this column, you can ignore this prop if you\'ve set a unique dataIndex'
    }, {
      name: 'width',
      type: 'number',
      defaultValue: 60,
      helperText: 'Width of this column'
    }, {
      name: 'align',
      type: 'string',
      defaultValue: 'left',
      helperText: 'Alignment',
      "enum": ['left', 'right', 'center']
    }, {
      name: 'valueType',
      type: 'string',
      "enum": ['password', 'money', 'textarea', 'option', 'date', 'dateWeek', 'dateMonth', 'dateQuarter', 'dateYear', 'dateRange', 'dateTimeRange', 'dateTime', 'time', 'timeRange', 'text', 'select', 'checkbox', 'rate', 'radio', 'radioButton', 'index', 'indexBorder', 'progress', 'percent', 'digit', 'second', 'avatar', 'code', 'switch', 'fromNow', 'image', 'jsonCode']
    }, {
      name: 'sorter',
      type: 'boolean',
      defaultValue: false,
      helperText: 'The weight in the query form, the weight is ranked first'
    }, {
      name: 'search',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Configuration column search related, default is false (disable)'
    }, {
      name: 'ellipsis',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Whether to abbreviate automatically'
    }, {
      name: 'copyable',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Whether to support copying'
    }, {
      name: 'filters',
      type: 'list',
      subFields: [{
        name: 'text',
        type: 'string'
      }, {
        name: 'value',
        type: 'string'
      }],
      helperText: 'Screening menu item with head'
    }, {
      name: 'tip',
      type: 'string',
      helperText: 'An icon will be displayed after the title, and some information will be prompted after hover'
    }, {
      name: 'formItemProps',
      type: 'object',
      subFields: [{
        name: 'required',
        type: 'boolean',
        defaultValue: false
      }],
      helperText: 'The configuration passed to Form.Item can be configured with rules, but the default query form rules does not take effect. Need to configure ignoreRules'
    }],
    defaultValue: [{
      title: 'Name',
      dataIndex: 'name'
    }]
  }, {
    name: 'rowKey',
    friendlyName: "Row key",
    type: 'string',
    required: true,
    defaultValue: 'key',
    helperText: 'Row\'s unique key, could be a string or function that returns a string'
  }, {
    name: 'request',
    friendlyName: "Data source",
    type: 'code',
    required: true,
    language: 'javascript',
    defaultValue: "async (params, sorter, filter) => {\n  return {\n    data: [{name: 'Hello World'}],\n    success: true,\n    total: 1,\n  }\n}",
    helperText: 'The object must have data and success in it, and total is also required if manual paging is needed. request takes over the loading settings and re-executes them when the query form is queried and the params parameters are modified. Also the query form values and params parameters are brought in.'
  },
  // { //default dataSource
  //   name: 'defaultData', type: 'list', subFields: [
  //     { name: 'col1', type: 'string', defaultValue: 'a1' },
  //     { name: 'col2', type: 'string', defaultValue: 'b1' },
  //     { name: 'col3', type: 'string', defaultValue: 'c1' },
  //   ], 
  //   advanced: true
  // },

  // {
  //   name: 'expandable', type: 'object', subFields: [
  // },

  {
    name: 'headerTitle',
    type: 'string',
    helperText: 'Table name',
    advanced: true
  }, {
    name: 'size',
    type: 'string',
    defaultValue: 'default',
    "enum": ['default', 'middle', 'small'],
    advanced: true
  },
  // {
  //   name: 'dateFormatter', type: 'string',
  // },
  {
    name: 'options',
    type: 'object',
    subFields: [{
      name: 'fullScreen',
      type: 'boolean',
      defaultValue: true
    }, {
      name: 'reload',
      type: 'boolean',
      defaultValue: true
    }, {
      name: 'setting',
      type: 'boolean',
      defaultValue: true
    }, {
      name: 'density',
      type: 'boolean'
    }],
    helperText: 'Table toolbar, not displayed when set to false',
    advanced: true
  }, {
    name: 'search',
    type: 'object',
    subFields: [{
      name: 'filterType',
      type: 'string',
      "enum": ['query', 'light'],
      defaultValue: 'query',
      helperText: 'Filter form type'
    }, {
      name: 'searchText',
      type: 'string',
      helperText: 'Search button text'
    }, {
      name: 'resetText',
      type: 'string',
      helperText: 'Reset button text'
    }, {
      name: 'submitText',
      type: 'string',
      helperText: 'The text of the submit button'
    }, {
      name: 'labelWidth',
      type: 'number',
      defaultValue: 80,
      helperText: 'Label width'
    }, {
      name: 'span',
      type: 'number',
      defaultValue: 80,
      helperText: 'Configure the number of columns in the query form'
    }, {
      name: 'defaultCollapsed',
      type: 'boolean',
      defaultValue: true,
      helperText: 'Whether to collapse by default'
    }],
    helperText: 'Whether to display the search form, when the object is passed in, it is the configuration of the search form',
    advanced: true
  },
  // { name: 'bordered', type: 'boolean', defaultValue: true, advanced: true },
  {
    name: 'loading',
    type: 'boolean',
    defaultValue: false,
    advanced: true
  }],
  canHaveChildren: true
};