var placeholderName = '~';
var placeholderLabel = '------';
export var defaultPlaceholderFieldName = placeholderName;
export var defaultPlaceholderFieldLabel = placeholderLabel;
export var defaultPlaceholderFieldGroupLabel = placeholderLabel;
export var defaultPlaceholderOperatorName = placeholderName;
export var defaultPlaceholderOperatorLabel = placeholderLabel;
export var defaultPlaceholderOperatorGroupLabel = placeholderLabel;
export var defaultJoinChar = ',';
export var defaultTranslations = {
  fields: {
    title: 'Fields',
    placeholderName: defaultPlaceholderFieldName,
    placeholderLabel: defaultPlaceholderFieldLabel,
    placeholderGroupLabel: defaultPlaceholderFieldGroupLabel
  },
  operators: {
    title: 'Operators',
    placeholderName: defaultPlaceholderOperatorName,
    placeholderLabel: defaultPlaceholderOperatorLabel,
    placeholderGroupLabel: defaultPlaceholderOperatorGroupLabel
  },
  value: {
    title: 'Value'
  },
  removeRule: {
    label: 'x',
    title: 'Remove rule'
  },
  removeGroup: {
    label: 'x',
    title: 'Remove group'
  },
  addRule: {
    label: '+Rule',
    title: 'Add rule'
  },
  addGroup: {
    label: '+Group',
    title: 'Add group'
  },
  combinators: {
    title: 'Combinators'
  },
  notToggle: {
    label: 'Not',
    title: 'Invert this group'
  },
  cloneRule: {
    label: '⧉',
    title: 'Clone rule'
  },
  cloneRuleGroup: {
    label: '⧉',
    title: 'Clone group'
  },
  dragHandle: {
    label: '⁞⁞',
    title: 'Drag handle'
  },
  lockRule: {
    label: '🔓',
    title: 'Lock rule'
  },
  lockGroup: {
    label: '🔓',
    title: 'Lock group'
  },
  lockRuleDisabled: {
    label: '🔒',
    title: 'Unlock rule'
  },
  lockGroupDisabled: {
    label: '🔒',
    title: 'Unlock group'
  },
  valueSourceSelector: {
    title: 'Value source'
  }
};
export var defaultOperators = [{
  name: '=',
  label: '='
}, {
  name: '!=',
  label: '!='
}, {
  name: '<',
  label: '<'
}, {
  name: '>',
  label: '>'
}, {
  name: '<=',
  label: '<='
}, {
  name: '>=',
  label: '>='
}, {
  name: 'contains',
  label: 'contains'
}, {
  name: 'beginsWith',
  label: 'begins with'
}, {
  name: 'endsWith',
  label: 'ends with'
}, {
  name: 'doesNotContain',
  label: 'does not contain'
}, {
  name: 'doesNotBeginWith',
  label: 'does not begin with'
}, {
  name: 'doesNotEndWith',
  label: 'does not end with'
}, {
  name: 'null',
  label: 'is null'
}, {
  name: 'notNull',
  label: 'is not null'
}, {
  name: 'in',
  label: 'in'
}, {
  name: 'notIn',
  label: 'not in'
}, {
  name: 'between',
  label: 'between'
}, {
  name: 'notBetween',
  label: 'not between'
}];
export var defaultOperatorNegationMap = {
  '=': '!=',
  '!=': '=',
  '<': '>=',
  '<=': '>',
  '>': '<=',
  '>=': '<',
  beginsWith: 'doesNotBeginWith',
  doesNotBeginWith: 'beginsWith',
  endsWith: 'doesNotEndWith',
  doesNotEndWith: 'endsWith',
  contains: 'doesNotContain',
  doesNotContain: 'contains',
  between: 'notBetween',
  notBetween: 'between',
  "in": 'notIn',
  notIn: 'in',
  notNull: 'null',
  "null": 'notNull'
};
export var defaultCombinators = [{
  name: 'and',
  label: 'AND'
}, {
  name: 'or',
  label: 'OR'
}];
export var defaultCombinatorsExtended = [].concat(defaultCombinators, [{
  name: 'xor',
  label: 'XOR'
}]);
export var standardClassnames = {
  queryBuilder: 'queryBuilder',
  ruleGroup: 'ruleGroup',
  header: 'ruleGroup-header',
  body: 'ruleGroup-body',
  combinators: 'ruleGroup-combinators',
  addRule: 'ruleGroup-addRule',
  addGroup: 'ruleGroup-addGroup',
  cloneRule: 'rule-cloneRule',
  cloneGroup: 'ruleGroup-cloneGroup',
  removeGroup: 'ruleGroup-remove',
  notToggle: 'ruleGroup-notToggle',
  rule: 'rule',
  fields: 'rule-fields',
  operators: 'rule-operators',
  value: 'rule-value',
  removeRule: 'rule-remove',
  betweenRules: 'betweenRules',
  valid: 'queryBuilder-valid',
  invalid: 'queryBuilder-invalid',
  dndDragging: 'dndDragging',
  dndOver: 'dndOver',
  dndCopy: 'dndCopy',
  dragHandle: 'queryBuilder-dragHandle',
  disabled: 'queryBuilder-disabled',
  lockRule: 'rule-lock',
  lockGroup: 'ruleGroup-lock',
  valueSource: 'rule-valueSource',
  valueListItem: 'rule-value-list-item'
};
export var defaultControlClassnames = {
  queryBuilder: '',
  ruleGroup: '',
  header: '',
  body: '',
  combinators: '',
  addRule: '',
  addGroup: '',
  cloneRule: '',
  cloneGroup: '',
  removeGroup: '',
  notToggle: '',
  rule: '',
  fields: '',
  operators: '',
  value: '',
  removeRule: '',
  dragHandle: '',
  lockRule: '',
  lockGroup: '',
  valueSource: ''
};
export var groupInvalidReasons = {
  empty: 'empty',
  invalidCombinator: 'invalid combinator',
  invalidIndependentCombinators: 'invalid independent combinators'
};
export var TestID = {
  rule: 'rule',
  ruleGroup: 'rule-group',
  inlineCombinator: 'inline-combinator',
  addGroup: 'add-group',
  removeGroup: 'remove-group',
  cloneGroup: 'clone-group',
  cloneRule: 'clone-rule',
  addRule: 'add-rule',
  removeRule: 'remove-rule',
  combinators: 'combinators',
  fields: 'fields',
  operators: 'operators',
  valueEditor: 'value-editor',
  notToggle: 'not-toggle',
  dragHandle: 'drag-handle',
  lockRule: 'lock-rule',
  lockGroup: 'lock-group',
  valueSourceSelector: 'value-source-selector'
};
export var LogType = {
  parentPathDisabled: 'action aborted: parent path disabled',
  pathDisabled: 'action aborted: path is disabled',
  queryUpdate: 'query updated',
  onAddRuleFalse: 'onAddRule callback returned false',
  onAddGroupFalse: 'onAddGroup callback returned false',
  onRemoveFalse: 'onRemove callback returned false'
};