import { dataServicesSelector } from '../../selectors';
import { loadEntitiesDataRequest } from '../records_request';
import { createAction as baseCreateAction } from '../base';
export var LOOKUP_STATE_CHANGE_ACTION = 'LOOKUP_STATE_CHANGE';
export var createLookupAction = function createLookupAction(partialStateName, partialStateValue, options) {
  return baseCreateAction(LOOKUP_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
};
export function loadLookupEntitiesData(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, LOOKUP_STATE_CHANGE_ACTION, service, options);
  };
}