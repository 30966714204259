import { each, filter, map, toLower, toString, trim, upperFirst, without } from "lodash";
import Mousetrap from "mousetrap";
import "mousetrap/plugins/global-bind/mousetrap-global-bind";
var modKey = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? "Cmd" : "Ctrl";
var altKey = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? "Option" : "Alt";
export function humanReadableShortcut(shortcut, limit) {
  if (limit === void 0) {
    limit = Infinity;
  }
  var modifiers = {
    mod: upperFirst(modKey),
    alt: upperFirst(altKey)
  };
  shortcut = toLower(toString(shortcut));
  shortcut = filter(map(shortcut.split(","), trim), function (s) {
    return s !== "";
  }).slice(0, limit);
  shortcut = map(shortcut, function (sc) {
    sc = filter(map(sc.split("+")), function (s) {
      return s !== "";
    });
    return map(sc, function (s) {
      return modifiers[s] || upperFirst(s);
    }).join(" + ");
  }).join(", ");
  return shortcut !== "" ? shortcut : null;
}
var handlers = {};
function onShortcut(event, shortcut) {
  event.preventDefault();
  event.retunValue = false;
  each(handlers[shortcut], function (fn) {
    return fn();
  });
}
var KeyboardShortcuts = {
  modKey: modKey,
  altKey: altKey,
  bind: function bind(keymap) {
    each(keymap, function (fn, key) {
      var keys = key.toLowerCase().split(",").map(trim);
      each(keys, function (k) {
        handlers[k] = [].concat(without(handlers[k], fn), [fn]);
        Mousetrap.bindGlobal(k, onShortcut);
      });
    });
  },
  unbind: function unbind(keymap) {
    each(keymap, function (fn, key) {
      var keys = key.toLowerCase().split(",").map(trim);
      each(keys, function (k) {
        handlers[k] = without(handlers[k], fn);
        if (handlers[k].length === 0) {
          handlers[k] = undefined;
          Mousetrap.unbind(k);
        }
      });
    });
  }
};
export default KeyboardShortcuts;