export function buildQueryString(parameters) {
  var keys = Object.keys(parameters);
  if (keys.length === 0) {
    return '';
  }
  var query = '?';
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    query += key + '=' + encodeURIComponent(parameters[key]);
    if (i < keys.length - 1) {
      query += '&';
    }
  }
  return query;
}