import _extends from "@babel/runtime/helpers/extends";
import { IconSlot } from '../icons/slot';
import { getConvertedExtraKey } from '@digiforce/dvd-designer';
export default function (metadata) {
  var componentName = metadata.componentName,
    _metadata$configure = metadata.configure,
    configure = _metadata$configure === void 0 ? {} : _metadata$configure;

  // 如果已经处理过，不再重新执行一遍
  if (configure.combined) {
    return metadata;
  }
  if (componentName === 'Leaf') {
    return _extends({}, metadata, {
      configure: _extends({}, configure, {
        combined: [{
          name: 'children',
          title: {
            type: 'i18n',
            'zh-CN': 'Content',
            'en-US': 'Content'
          },
          setter: {
            componentName: 'MixedSetter',
            props: {
              // TODO:
              setters: [{
                componentName: 'StringSetter',
                props: {
                  // TODO: textarea mode
                  multiline: true
                },
                initialValue: ''
              }, {
                componentName: 'ExpressionSetter',
                initialValue: {
                  type: 'JSExpression',
                  value: ''
                }
              }]
            }
          }
        }]
      })
    });
  }
  var _ref = configure,
    props = _ref.props,
    _ref$supports = _ref.supports,
    supports = _ref$supports === void 0 ? {} : _ref$supports;
  var isRoot = componentName === 'Page' || componentName === 'Component';
  var eventsDefinition = [];
  var supportedLifecycles = supports.lifecycles || (isRoot ?
  /* [
    {
      description: 'When initialization',
      name: 'constructor',
    },
    {
      description: 'After component loading',
      name: 'componentDidMount',
    },
    {
      description: 'After component updated',
      name: 'componentDidUpdate',
    },
    {
      description: 'When component unmount',
      name: 'componentWillUnmount',
    },
  ] */
  null : null);
  if (supportedLifecycles) {
    eventsDefinition.push({
      type: 'lifeCycleEvent',
      title: 'The life cycle',
      list: supportedLifecycles.map(function (event) {
        return typeof event === 'string' ? {
          name: event
        } : event;
      })
    });
  }
  if (supports.events) {
    eventsDefinition.push({
      type: 'events',
      title: 'Event',
      list: (supports.events || []).map(function (event) {
        return typeof event === 'string' ? {
          name: event
        } : event;
      })
    });
  }
  //  通用设置
  var propsGroup = props ? [].concat(props) : [];
  var basicInfo = {};
  if (componentName === 'Slot') {
    if (!configure.component) {
      configure.component = {
        isContainer: true
      };
    } else if (typeof configure.component === 'object') {
      configure.component.isContainer = true;
    }
    basicInfo.icon = IconSlot;
    propsGroup = [{
      name: getConvertedExtraKey('title'),
      title: {
        type: 'i18n',
        'en-US': 'Slot Title',
        'zh-CN': 'Slot Title'
      },
      setter: 'StringSetter',
      defaultValue: 'Slot Container'
    }];
  }
  /*
  propsGroup.push({
    name: '#generals',
    title: { type: 'i18n', 'zh-CN': 'General', 'en-US': 'General' },
    items: [
      {
        name: 'id',
        title: 'ID',
        setter: 'StringSetter',
      },
      {
        name: 'key',
        title: 'Key',
        // todo: use Mixin
        setter: 'StringSetter',
      },
      {
        name: 'ref',
        title: 'Ref',
        setter: 'StringSetter',
      },
      {
        name: '!more',
        title: 'More',
        setter: 'PropertiesSetter',
      },
    ],
  });
  */
  var stylesGroup = [];
  var advancedGroup = [];
  if (propsGroup) {
    var l = propsGroup.length;
    while (l-- > 0) {
      var item = propsGroup[l];
      // if (item.type === 'group' && (item.title === 'Advanced' || item.title?.label === 'Advanced')) {
      //   advancedGroup = item.items || [];
      //   propsGroup.splice(l, 1);
      // }
      if (item.name === '__style__' || item.name === 'style' || item.name === 'containerStyle' || item.name === 'pageStyle') {
        var _item$extraProps;
        propsGroup.splice(l, 1);
        stylesGroup.push(item);
        if ((_item$extraProps = item.extraProps) !== null && _item$extraProps !== void 0 && _item$extraProps.defaultCollapsed && item.name !== 'containerStyle') {
          item.extraProps.defaultCollapsed = false;
        }
      }
    }
  }
  var combined = [{
    title: {
      type: 'i18n',
      'zh-CN': 'Props',
      'en-US': 'Props'
    },
    name: '#props',
    items: propsGroup
  }];
  if (supports.className) {
    stylesGroup.push({
      name: 'className',
      title: {
        type: 'i18n',
        'zh-CN': 'Class Name',
        'en-US': 'Class Name'
      },
      setter: 'ClassNameSetter'
    });
  }
  if (supports.style) {
    stylesGroup.push({
      name: 'style',
      title: {
        type: 'i18n',
        'zh-CN': 'Style',
        'en-US': 'Style'
      },
      setter: 'StyleSetter',
      extraProps: {
        display: 'block'
      }
    });
  }
  if (stylesGroup.length > 0) {
    combined.push({
      name: '#styles',
      title: {
        type: 'i18n',
        'zh-CN': 'Styles',
        'en-US': 'Styles'
      },
      items: stylesGroup
    });
  }
  if (eventsDefinition.length > 0) {
    combined.push({
      name: '#events',
      title: {
        type: 'i18n',
        'zh-CN': 'Events',
        'en-US': 'Events'
      },
      items: [{
        name: '__events',
        title: {
          type: 'i18n',
          'zh-CN': 'Events',
          'en-US': 'Events'
        },
        setter: {
          componentName: 'EventsSetter',
          props: {
            definition: eventsDefinition
          }
        },
        getValue: function getValue(field, val) {
          return val;
        },
        setValue: function setValue(field, eventData) {
          var eventDataList = eventData.eventDataList,
            eventList = eventData.eventList;
          Array.isArray(eventList) && eventList.map(function (item) {
            field.parent.clearPropValue(item.name);
            return item;
          });
          Array.isArray(eventDataList) && eventDataList.map(function (item) {
            field.parent.setPropValue(item.name, {
              type: 'JSFunction',
              // 需要传下入参
              value: "function(){this." + item.relatedEventName + ".apply(this,Array.prototype.slice.call(arguments).concat([" + (item.paramStr ? item.paramStr : '') + "])) }"
            });
            return item;
          });
        }
      }]
    });
  }
  if (!isRoot) {
    if (supports.condition !== false) {
      advancedGroup.push({
        name: getConvertedExtraKey('condition'),
        title: {
          type: 'i18n',
          'zh-CN': 'Condition',
          'en-US': 'Condition'
        },
        defaultValue: true,
        setter: [{
          componentName: 'BoolSetter'
        }, {
          componentName: 'VariableSetter'
        }],
        extraProps: {
          display: 'block'
        }
      });
    }
    if (supports.loop !== false) {
      advancedGroup.push({
        name: '#loop',
        title: {
          type: 'i18n',
          'zh-CN': 'Loop',
          'en-US': 'Loop'
        },
        items: [{
          name: getConvertedExtraKey('loop'),
          title: {
            type: 'i18n',
            'zh-CN': 'Loop Data',
            'en-US': 'Loop Data'
          },
          setter: [{
            componentName: 'JsonSetter',
            props: {
              label: {
                type: 'i18n',
                'zh-CN': 'Edit Data',
                'en-US': 'Edit Data'
              },
              defaultValue: '[]'
            }
          }, {
            componentName: 'VariableSetter'
          }]
        }, {
          name: getConvertedExtraKey('loopArgs.0'),
          title: {
            type: 'i18n',
            'zh-CN': 'Loop Item',
            'en-US': 'Loop Item'
          },
          setter: {
            componentName: 'StringSetter',
            props: {
              placeholder: {
                type: 'i18n',
                'zh-CN': 'Defaults: item',
                'en-US': 'Defaults: item'
              }
            }
          }
        }, {
          name: getConvertedExtraKey('loopArgs.1'),
          title: {
            type: 'i18n',
            'zh-CN': 'Loop Index',
            'en-US': 'Loop Index'
          },
          setter: {
            componentName: 'StringSetter',
            props: {
              placeholder: {
                type: 'i18n',
                'zh-CN': 'Defaults: index',
                'en-US': 'Defaults: index'
              }
            }
          }
        }, {
          name: 'key',
          title: 'Key',
          setter: [{
            componentName: 'StringSetter'
          }, {
            componentName: 'VariableSetter'
          }]
        }],
        extraProps: {
          display: 'accordion'
        }
      });
    }
    if (supports.condition !== false || supports.loop !== false) {
      advancedGroup.push({
        name: 'key',
        title: {
          label: 'Render unique identifier (key',
          tip: 'Used with "Conditional Rendering" or "Cycle Rendering", the same principle as the key in the react component, click to view the help'
        },
        setter: [{
          componentName: 'StringSetter'
        }, {
          componentName: 'VariableSetter'
        }],
        extraProps: {
          display: 'block'
        }
      });
    }
  }
  if (advancedGroup.length > 0) {
    combined.push({
      name: '#advanced',
      title: {
        type: 'i18n',
        'zh-CN': 'Advanced',
        'en-US': 'Advanced'
      },
      items: advancedGroup
    });
  }
  return _extends({}, metadata, basicInfo, {
    configure: _extends({}, configure, {
      combined: combined
    })
  });
}