export function isElementNode(domNode) {
  return domNode.nodeType === Node.ELEMENT_NODE;
}

/**
 * 判断节点是否在 viewport 内，判断依据：只要节点有一部分在 viewport 内，都算 true，其余情况 false
 * @param domNode 待检测的节点
 * @param viewport 画布 viewport
 * @returns 是否在 viewport 内
 */
export function isDOMNodeVisible(domNode, viewport) {
  var domNodeRect = domNode.getBoundingClientRect();
  var _viewport$contentBoun = viewport.contentBounds,
    width = _viewport$contentBoun.width,
    height = _viewport$contentBoun.height;
  var left = domNodeRect.left,
    right = domNodeRect.right,
    top = domNodeRect.top,
    bottom = domNodeRect.bottom,
    nodeWidth = domNodeRect.width,
    nodeHeight = domNodeRect.height;
  return left >= -nodeWidth && top >= -nodeHeight && bottom <= height + nodeHeight && right <= width + nodeWidth;
}

/**
 * normalize triggers
 * @param triggers
 */
export function normalizeTriggers(triggers) {
  return triggers.map(function (trigger) {
    return trigger === null || trigger === void 0 ? void 0 : trigger.toUpperCase();
  });
}

/**
 * make a handler that listen all sensors:document, avoid frame lost
 */
export function makeEventsHandler(boostEvent, sensors) {
  var _boostEvent$view;
  var topDoc = window.document;
  var sourceDoc = ((_boostEvent$view = boostEvent.view) === null || _boostEvent$view === void 0 ? void 0 : _boostEvent$view.document) || topDoc;
  var docs = new Set();
  docs.add(topDoc);
  docs.add(sourceDoc);
  sensors.forEach(function (sim) {
    var sdoc = sim.contentDocument;
    if (sdoc) {
      docs.add(sdoc);
    }
  });
  return function (handle) {
    docs.forEach(function (doc) {
      return handle(doc);
    });
  };
}