import { toArray, trimIfString } from '../arrayUtils';
import { isValidValue, mongoOperators, shouldRenderAsNumber } from './utils';
export var defaultRuleProcessorMongoDB = function defaultRuleProcessorMongoDB(_ref, // istanbul ignore next
_temp) {
  var field = _ref.field,
    operator = _ref.operator,
    value = _ref.value,
    valueSource = _ref.valueSource;
  var _ref2 = _temp === void 0 ? {} : _temp,
    parseNumbers = _ref2.parseNumbers;
  var escapeDoubleQuotes = function escapeDoubleQuotes(v) {
    return typeof v !== 'string' ? v : v.replaceAll('\\', '\\\\').replaceAll("\"", "\\\"");
  };
  var valueIsField = valueSource === 'field';
  var useBareValue = typeof value === 'number' || typeof value === 'boolean' || typeof value === 'bigint' || shouldRenderAsNumber(value, parseNumbers);
  if (operator === '=' && !valueIsField) {
    return "{\"" + field + "\":" + (useBareValue ? trimIfString(value) : "\"" + escapeDoubleQuotes(value) + "\"") + "}";
  } else if (operator === '<' || operator === '<=' || operator === '=' || operator === '!=' || operator === '>' || operator === '>=') {
    var mongoOperator = mongoOperators[operator];
    return valueIsField ? "{\"$expr\":{\"" + mongoOperator + "\":[\"$" + field + "\",\"$" + value + "\"]}}" : "{\"" + field + "\":{\"" + mongoOperator + "\":" + (useBareValue ? trimIfString(value) : "\"" + escapeDoubleQuotes(value) + "\"") + "}}";
  } else if (operator === 'contains') {
    return valueIsField ? "{\"$where\":\"this." + field + ".includes(this." + value + ")\"}" : "{\"" + field + "\":{\"$regex\":\"" + escapeDoubleQuotes(value) + "\"}}";
  } else if (operator === 'beginsWith') {
    return valueIsField ? "{\"$where\":\"this." + field + ".startsWith(this." + value + ")\"}" : "{\"" + field + "\":{\"$regex\":\"^" + escapeDoubleQuotes(value) + "\"}}";
  } else if (operator === 'endsWith') {
    return valueIsField ? "{\"$where\":\"this." + field + ".endsWith(this." + value + ")\"}" : "{\"" + field + "\":{\"$regex\":\"" + escapeDoubleQuotes(value) + "$\"}}";
  } else if (operator === 'doesNotContain') {
    return valueIsField ? "{\"$where\":\"!this." + field + ".includes(this." + value + ")\"}" : "{\"" + field + "\":{\"$not\":{\"$regex\":\"" + escapeDoubleQuotes(value) + "\"}}}";
  } else if (operator === 'doesNotBeginWith') {
    return valueIsField ? "{\"$where\":\"!this." + field + ".startsWith(this." + value + ")\"}" : "{\"" + field + "\":{\"$not\":{\"$regex\":\"^" + escapeDoubleQuotes(value) + "\"}}}";
  } else if (operator === 'doesNotEndWith') {
    return valueIsField ? "{\"$where\":\"!this." + field + ".endsWith(this." + value + ")\"}" : "{\"" + field + "\":{\"$not\":{\"$regex\":\"" + escapeDoubleQuotes(value) + "$\"}}}";
  } else if (operator === 'null') {
    return "{\"" + field + "\":null}";
  } else if (operator === 'notNull') {
    return "{\"" + field + "\":{\"$ne\":null}}";
  } else if (operator === 'in' || operator === 'notIn') {
    var valArray = toArray(value);
    if (valArray.length > 0) {
      return valueIsField ? "{\"$where\":\"" + (operator === 'notIn' ? '!' : '') + "[" + valArray.map(function (val) {
        return "this." + val;
      }).join(',') + "].includes(this." + field + ")\"}" : "{\"" + field + "\":{\"" + mongoOperators[operator] + "\":[" + valArray.map(function (val) {
        return shouldRenderAsNumber(val, parseNumbers) ? "" + trimIfString(val) : "\"" + escapeDoubleQuotes(val) + "\"";
      }).join(',') + "]}}";
    } else {
      return '';
    }
  } else if (operator === 'between' || operator === 'notBetween') {
    var _valArray = toArray(value);
    if (_valArray.length >= 2 && isValidValue(_valArray[0]) && isValidValue(_valArray[1])) {
      var first = _valArray[0],
        second = _valArray[1];
      var firstNum = shouldRenderAsNumber(first, true) ? parseFloat(first) : NaN;
      var secondNum = shouldRenderAsNumber(second, true) ? parseFloat(second) : NaN;
      var firstValue = valueIsField || !isNaN(firstNum) ? "" + first : "\"" + escapeDoubleQuotes(first) + "\"";
      var secondValue = valueIsField || !isNaN(secondNum) ? "" + second : "\"" + escapeDoubleQuotes(second) + "\"";
      if (operator === 'between') {
        return valueIsField ? "{\"$and\":[{\"$expr\":{\"$gte\":[\"$" + field + "\",\"$" + firstValue + "\"]}},{\"$expr\":{\"$lte\":[\"$" + field + "\",\"$" + secondValue + "\"]}}]}" : "{\"" + field + "\":{\"$gte\":" + firstValue + ",\"$lte\":" + secondValue + "}}";
      } else {
        return valueIsField ? "{\"$or\":[{\"$expr\":{\"$lt\":[\"$" + field + "\",\"$" + firstValue + "\"]}},{\"$expr\":{\"$gt\":[\"$" + field + "\",\"$" + secondValue + "\"]}}]}" : "{\"$or\":[{\"" + field + "\":{\"$lt\":" + firstValue + "}},{\"" + field + "\":{\"$gt\":" + secondValue + "}}]}";
      }
    } else {
      return '';
    }
  }
  return '';
};