import { dataServicesSelector, entityStateSelector } from '../../selectors';
import { loadEntitiesDataRequest } from '../records_request';
import { createAction as baseCreateAction } from '../base';
export var DXGRID_STATE_CHANGE_ACTION = 'DXGRID_STATE_CHANGE';
export function createDXGridAction(partialStateName, partialStateValue, options) {
  if (["currentPage", "pageSize", "filters"].includes(partialStateName)) {
    return function (dispatch, getState) {
      var _Object$assign;
      var entityState = entityStateSelector(getState(), options.objectName);
      var service = dataServicesSelector(getState());
      var newOptions = Object.assign({}, entityState, (_Object$assign = {}, _Object$assign[partialStateName] = partialStateValue, _Object$assign));
      loadEntitiesDataRequest(dispatch, DXGRID_STATE_CHANGE_ACTION, service, newOptions);
      dispatch(baseCreateAction(DXGRID_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options));
    };
  } else {
    return baseCreateAction(DXGRID_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
  }
}
export function loadDXGridEntitiesData(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, DXGRID_STATE_CHANGE_ACTION, service, options);
  };
}