import { NOTIFICATIONS_STATE_CHANGE_ACTION, NOTIFICATIONS_INTERVAL_CHANGE_ACTION, NOTIFICATIONS_COUNT_CHANGE_ACTION } from '../../actions/views/notifications';
function transformEntityState(state, payload, options) {
  return Object.assign({}, state, {
    rows: payload.partialStateValue.records,
    totalCount: payload.partialStateValue.totalCount
  }, options);
}
function transformEntityStateForCount(state, payload, options) {
  return Object.assign({}, state, {
    unreadCount: payload.partialStateValue.totalCount
  }, options);
}
function transformEntityStateForMark(state, payload, options) {
  return Object.assign({}, state, {
    rows: payload.partialStateValue.records,
    unreadCount: 0
  }, options);
}
function reducer(state, action) {
  var _defaultPayLoad;
  if (state === void 0) {
    state = {};
  }
  var payload = action.payload;
  var defaultPayLoad = (_defaultPayLoad = {}, _defaultPayLoad[payload.partialStateName] = payload.partialStateValue, _defaultPayLoad);
  if (action.type === NOTIFICATIONS_STATE_CHANGE_ACTION) {
    switch (payload.partialStateName) {
      case 'loadDataSauce':
        return transformEntityState(state, payload, {
          loading: false,
          itemsLoaded: true
        });
      case 'loadDataError':
        return Object.assign({}, state, defaultPayLoad, {
          loading: false
        });
      case 'executeApiSauce':
        return Object.assign({}, state, defaultPayLoad, {
          methodLoading: false
        });
      case 'executeApiError':
        return Object.assign({}, state, defaultPayLoad, {
          methodLoading: false
        });
      case 'markReadAll':
        return transformEntityStateForMark(state, payload, {});
      default:
        break;
    }
    return Object.assign({}, state, defaultPayLoad);
  } else if (action.type === NOTIFICATIONS_COUNT_CHANGE_ACTION) {
    switch (payload.partialStateName) {
      case 'loadDataSauce':
        return transformEntityStateForCount(state, payload, {
          countLoading: false
        });
      case 'loadDataError':
        return Object.assign({}, state, defaultPayLoad, {
          countLoading: false
        });
      default:
        break;
    }
    return Object.assign({}, state, defaultPayLoad);
  } else if (action.type === NOTIFICATIONS_INTERVAL_CHANGE_ACTION) {
    return Object.assign({}, state, {
      intervalId: payload.partialStateValue.intervalId,
      intervalCount: payload.partialStateValue.intervalCount,
      intervalTime: payload.partialStateValue.intervalTime
    });
  }
  return state;
}
export default reducer;