import { isPojo } from '../parserUtils';
// Standard JsonLogic operations
export var isJsonLogicVar = function isJsonLogicVar(logic) {
  return isPojo(logic) && 'var' in logic;
};
export var isRQBJsonLogicVar = function isRQBJsonLogicVar(logic) {
  return isJsonLogicVar(logic) && typeof logic["var"] === 'string';
};
export var isJsonLogicEqual = function isJsonLogicEqual(logic) {
  return isPojo(logic) && '==' in logic;
};
export var isJsonLogicStrictEqual = function isJsonLogicStrictEqual(logic) {
  return isPojo(logic) && '===' in logic;
};
export var isJsonLogicNotEqual = function isJsonLogicNotEqual(logic) {
  return isPojo(logic) && '!=' in logic;
};
export var isJsonLogicStrictNotEqual = function isJsonLogicStrictNotEqual(logic) {
  return isPojo(logic) && '!==' in logic;
};
export var isJsonLogicNegation = function isJsonLogicNegation(logic) {
  return isPojo(logic) && '!' in logic;
};
export var isJsonLogicDoubleNegation = function isJsonLogicDoubleNegation(logic) {
  return isPojo(logic) && '!!' in logic;
};
export var isJsonLogicOr = function isJsonLogicOr(logic) {
  return isPojo(logic) && 'or' in logic;
};
export var isJsonLogicAnd = function isJsonLogicAnd(logic) {
  return isPojo(logic) && 'and' in logic;
};
export var isJsonLogicGreaterThan = function isJsonLogicGreaterThan(logic) {
  return isPojo(logic) && '>' in logic;
};
export var isJsonLogicGreaterThanOrEqual = function isJsonLogicGreaterThanOrEqual(logic) {
  return isPojo(logic) && '>=' in logic;
};
export var isJsonLogicLessThan = function isJsonLogicLessThan(logic) {
  return isPojo(logic) && '<' in logic && logic['<'].length === 2;
};
export var isJsonLogicLessThanOrEqual = function isJsonLogicLessThanOrEqual(logic) {
  return isPojo(logic) && '<=' in logic && logic['<='].length === 2;
};
export var isJsonLogicInArray = function isJsonLogicInArray(logic) {
  return isPojo(logic) && 'in' in logic && Array.isArray(logic["in"][1]);
};
export var isJsonLogicInString = function isJsonLogicInString(logic) {
  return isPojo(logic) && 'in' in logic && !Array.isArray(logic["in"][1]);
};

// "Between" operations are special cases of '<' and '<='
export var isJsonLogicBetweenExclusive = function isJsonLogicBetweenExclusive(logic) {
  return isPojo(logic) && '<' in logic && Array.isArray(logic['<']) && logic['<'].length === 3;
};
export var isJsonLogicBetweenInclusive = function isJsonLogicBetweenInclusive(logic) {
  return isPojo(logic) && '<=' in logic && Array.isArray(logic['<=']) && logic['<='].length === 3;
};

// RQB extensions
export var isRQBJsonLogicStartsWith = function isRQBJsonLogicStartsWith(logic) {
  return isPojo(logic) && 'startsWith' in logic;
};
export var isRQBJsonLogicEndsWith = function isRQBJsonLogicEndsWith(logic) {
  return isPojo(logic) && 'endsWith' in logic;
};

// Type guards for unused JsonLogic operations

// import type {
//   JsonLogicAll,
//   JsonLogicCat,
//   JsonLogicDifference,
//   JsonLogicFilter,
//   JsonLogicIf,
//   JsonLogicLog,
//   JsonLogicMap,
//   JsonLogicMax,
//   JsonLogicMerge,
//   JsonLogicMin,
//   JsonLogicMissing,
//   JsonLogicMissingSome,
//   JsonLogicNone,
//   JsonLogicProduct,
//   JsonLogicQuotient,
//   JsonLogicReduce,
//   JsonLogicRemainder,
//   JsonLogicSome,
//   JsonLogicSubstr,
//   JsonLogicSum,
// } from '@react-querybuilder/ts/src/index.noReact';
//
// export const isJsonLogicMissing = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicMissing<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'missing' in logic;
// export const isJsonLogicMissingSome = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicMissingSome<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'missing_some' in logic;
// export const isJsonLogicIf = (logic: RQBJsonLogic): logic is JsonLogicIf =>
//   isPojo(logic) && 'if' in logic;
// export const isJsonLogicMax = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicMax<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'max' in logic;
// export const isJsonLogicMin = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicMin<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'min' in logic;
// export const isJsonLogicSum = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicSum<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && '+' in logic;
// export const isJsonLogicDifference = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicDifference<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && '-' in logic;
// export const isJsonLogicProduct = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicProduct<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && '*' in logic;
// export const isJsonLogicQuotient = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicQuotient<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && '/' in logic;
// export const isJsonLogicRemainder = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicRemainder<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && '%' in logic;
// export const isJsonLogicMap = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicMap<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'map' in logic;
// export const isJsonLogicFilter = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicFilter<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'filter' in logic;
// export const isJsonLogicReduce = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicReduce<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'reduce' in logic;
// export const isJsonLogicAll = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicAll<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'all' in logic;
// export const isJsonLogicNone = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicNone<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'none' in logic;
// export const isJsonLogicSome = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicSome<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'some' in logic;
// export const isJsonLogicMerge = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicMerge<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'merge' in logic;
// export const isJsonLogicCat = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicCat<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'cat' in logic;
// export const isJsonLogicSubstr = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicSubstr<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'substr' in logic;
// export const isJsonLogicLog = (
//   logic: RQBJsonLogic
// ): logic is JsonLogicLog<RQBJsonLogicStartsWith | RQBJsonLogicEndsWith> =>
//   isPojo(logic) && 'log' in logic;