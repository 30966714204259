import { isUndefined } from "lodash";
import { useEffect, useMemo, useState, useCallback } from "react";
export default function useQueryParameters(query) {
  var parameters = useMemo(function () {
    return query.getParametersDefs();
  }, [query]);
  var _useState = useState(query.getParameters().hasPendingValues()),
    dirtyFlag = _useState[0],
    setDirtyFlag = _useState[1];
  var updateDirtyFlag = useCallback(function (flag) {
    flag = isUndefined(flag) ? query.getParameters().hasPendingValues() : flag;
    setDirtyFlag(flag);
  }, [query]);
  useEffect(function () {
    var updatedDirtyParameters = query.getParameters().hasPendingValues();
    if (updatedDirtyParameters !== dirtyFlag) {
      setDirtyFlag(updatedDirtyParameters);
    }
  }, [query, parameters, dirtyFlag]);
  return useMemo(function () {
    return [parameters, dirtyFlag, updateDirtyFlag];
  }, [parameters, dirtyFlag, updateDirtyFlag]);
}