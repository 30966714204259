import { each, isArray, forEach, isObject, isString, keys, isFunction, isNil } from 'lodash';
import { BASE_FIELDNAMES_FOR_SYSTEM } from '@digiforce-ui/builder-sdk';
export function safeEval(js) {
  try {
    return eval(js.replace(/_\./g, "window._.").replace(/\bt\(/g, "window.t("));
  } catch (e) {
    console.error(e, js);
  }
}
;

/**
 * @param express 
 */
var getCompatibleDefaultValueExpression = function getCompatibleDefaultValueExpression(express, multiple) {
  var reg = /^\{\w+(\.*\w+)*\}$/;
  var result = express;
  if (reg.test(express)) {
    if (express.indexOf("userId") > -1 || express.indexOf("spaceId") > -1 || express.indexOf("user.") > -1 || express.indexOf("now") > -1) {
      result = ("{" + express + "}").replace("{{", "{{global.");
    } else {
      result = ("{" + express + "}").replace("{{", "{{formData.");
    }
    if (multiple) {
      result = result.replace(/\{\{(.+)\}\}/, "{{[$1]}}");
    }
  }
  return result;
};
var getFieldSchema = function getFieldSchema(fieldName, objectConfig) {
  var fieldSchema = {};
  var field = objectConfig.fields[fieldName];
  var fieldType = field && field.type;
  var sub_fields;
  var fieldNameForReg = fieldName.replace(/\$/g, "\\$").replace(/\./g, "\\.");
  var precision = field.precision || 18;
  if (fieldType === 'object') {
    sub_fields = {};
    each(objectConfig.fields, function (fieldItem, key) {
      var reg = new RegExp("^" + fieldNameForReg + "\\.\\w+$");
      // if(key.startsWith(`${fieldName}.`)){
      if (reg.test(key)) {
        var matches = key.match(/.(\w+)$/g);
        var lastFieldKey = matches && matches[0].replace(".", "");
        if (lastFieldKey) {
          sub_fields[lastFieldKey] = getFieldSchema(key, objectConfig);
          ;
        }
      }
    });
    fieldSchema = Object.assign({}, field, {
      sub_fields: sub_fields
    });
  } else if (fieldType === 'grid') {
    sub_fields = {};
    each(objectConfig.fields, function (fieldItem, key) {
      var reg = new RegExp("^" + fieldNameForReg + "\\.\\$\\.\\w+$");
      // if(key.startsWith(`${fieldName}.$.`)){
      if (reg.test(key)) {
        var matches = key.match(/.(\w+)$/g);
        var lastFieldKey = matches && matches[0].replace(".", "");
        if (lastFieldKey) {
          sub_fields[lastFieldKey] = getFieldSchema(key, objectConfig);
        }
      }
    });
    fieldSchema = Object.assign({}, field, {
      sub_fields: sub_fields
    });
  } else if (['lookup', 'master_detail'].indexOf(fieldType) > -1) {
    if (field.reference_to === "users") {
      fieldSchema = Object.assign({}, field, {
        reference_to: "space_users",
        reference_to_field: "user"
      });
    } else if (field._reference_to) {
      var reference_to = safeEval("(" + field._reference_to + ")");
      fieldSchema = Object.assign({}, field, {
        reference_to: reference_to
      });
    } else {
      fieldSchema = field;
    }
    if (field._filtersFunction || isString(field.filtersFunction)) {
      var filtersFunction = safeEval("(" + (field._filtersFunction || field.filtersFunction) + ")");
      fieldSchema = Object.assign({}, fieldSchema, {
        filtersFunction: filtersFunction
      });
    }
    if (field._optionsFunction || isString(field.optionsFunction)) {
      var optionsFunction = safeEval("(" + (field._optionsFunction || field.optionsFunction) + ")");
      fieldSchema = Object.assign({}, fieldSchema, {
        optionsFunction: optionsFunction
      });
    }
    if (field._options) {
      var options = safeEval("(" + field._options + ")");
      fieldSchema = Object.assign({}, fieldSchema, {
        options: options
      });
    }
  } else if (fieldType === 'select') {
    if (field._optionsFunction) {
      var _optionsFunction = safeEval("(" + field._optionsFunction + ")");
      fieldSchema = Object.assign({}, field, {
        optionsFunction: _optionsFunction
      });
    } else {
      fieldSchema = field;
    }
    if (field._options) {
      var _options = safeEval("(" + field._options + ")");
      fieldSchema = Object.assign({}, fieldSchema, {
        options: _options
      });
    }
  } else if (fieldType === 'currency') {
    if (isNil(field.scale)) {
      fieldSchema = Object.assign({}, field, {
        scale: 2,
        precision: precision
      });
    } else {
      fieldSchema = Object.assign({}, field, {
        precision: precision
      });
    }
  } else if (fieldType === 'number') {
    fieldSchema = Object.assign({}, field, {
      precision: precision
    });
  } else {
    fieldSchema = field;
  }
  if (field._defaultValue) {
    var defaultValue = safeEval("(" + field._defaultValue + ")");
    fieldSchema = Object.assign({}, fieldSchema, {
      defaultValue: defaultValue
    });
  } else if (typeof field.defaultValue === "string") {
    fieldSchema = Object.assign({}, fieldSchema, {
      defaultValue: getCompatibleDefaultValueExpression(field.defaultValue, field.multiple)
    });
  }
  if (['boolean', 'toggle'].indexOf(fieldType) > -1 && (field.defaultValue === undefined || field.defaultValue === null)) {
    fieldSchema = Object.assign({}, field, {
      defaultValue: false
    });
  }
  if (["autonumber", "formula", "summary"].indexOf(fieldType) > -1) {
    fieldSchema.readonly = true;
    fieldSchema.required = false;
  }
  if (field.omit) {
    fieldSchema = Object.assign({}, fieldSchema, {
      hidden: true
    });
  }
  if (BASE_FIELDNAMES_FOR_SYSTEM.indexOf(field.name) > -1) {
    fieldSchema = Object.assign({}, fieldSchema, {
      readonly: true
    });
  }
  return fieldSchema;
};
export function getFieldsSchema(objectConfig) {
  var fieldsSchema = {};
  // console.log("convertFieldsSchema===", objectConfig.name, JSON.stringify(objectConfig.fields));
  each(objectConfig.fields, function (field, fieldName) {
    if (/\w+\.($\.)?(\w+)?/.test(fieldName)) {
      return;
    }
    var fieldSchema = getFieldSchema(fieldName, objectConfig);
    if (fieldSchema) {
      fieldsSchema[fieldName] = fieldSchema;
    }
  });
  // console.log("convertFieldsSchema====fieldsSchema===", objectConfig.name, fieldsSchema);
  return fieldsSchema;
}
var getListViewSchema = function getListViewSchema(listView) {
  if (listView._filters) {
    var filters = safeEval("(" + listView._filters + ")");
    return Object.assign({}, listView, {
      filters: filters
    });
  } else if (isArray(listView.filters)) {
    forEach(listView.filters, function (filter, _index) {
      if (isArray(filter)) {
        if (filter.length === 4 && isString(filter[2]) && filter[3] === "FUNCTION") {
          filter[2] = safeEval("(" + filter[2] + ")");
          filter.pop();
        }
        if (filter.length === 4 && isString(filter[2]) && filter[3] === "DATE") {
          filter[2] = new Date(filter[2]);
          return filter.pop();
        }
      } else if (isObject(filter)) {
        if (isString(filter && filter._value)) {
          return filter.value = safeEval("(" + filter._value + ")");
        } else if (filter._is_date === true) {
          return filter.value = new Date(filter.value);
        }
      }
    });
    return listView;
  } else {
    return listView;
  }
};
export function getListViewsSchema(objectConfig) {
  var listViewsSchema = {};
  each(objectConfig.list_views, function (listView, listName) {
    var listViewSchema = getListViewSchema(listView);
    if (listViewSchema) {
      listViewsSchema[listName] = listViewSchema;
    }
  });
  return listViewsSchema;
}
export function getFormSchema(objectConfig) {
  if (!objectConfig.form) {
    return;
  }
  var form = safeEval("(" + objectConfig.form + ")");
  var regFunction = /^[\s]*function\b/;
  forEach(form, function (value, key) {
    if (regFunction.test(value)) {
      form[key] = safeEval("(" + value + ")");
    }
  });
  return form;
}
export function convertObjectSchema(objectConfig) {
  objectConfig.fields = getFieldsSchema(objectConfig);
  // console.log("convertObjectSchema====objectConfig.fields===", objectConfig.name, objectConfig.fields);
  objectConfig.list_views = getListViewsSchema(objectConfig);
  objectConfig.form = getFormSchema(objectConfig);
  // console.log("convertObjectSchema====objectConfig.form===", objectConfig.name, objectConfig.form);
}

export function getObjectOdataExpandFields(object, columns) {
  var expand_fields, fields;
  expand_fields = [];
  fields = object.fields;
  if (!columns || columns.length == 0) {
    columns = keys(fields);
  }
  each(columns, function (n) {
    var ref1, ref2;
    if (fields) {
      if (((ref1 = fields[n]) != null ? ref1.type : void 0) === "master_detail" || ((ref2 = fields[n]) != null ? ref2.type : void 0) === "lookup") {
        return expand_fields.push(n);
      }
    }
  });
  return expand_fields.join(",");
}
export function convertRecordsForLookup(data, fieldsSchema) {
  var recoreds = data && data.value;
  if (recoreds && recoreds.length) {
    data.value = recoreds.map(function (record) {
      each(record, function (fieldValue, key) {
        if (fieldValue) {
          var fieldSchema = fieldsSchema && fieldsSchema[key];
          if (fieldSchema && ['lookup', 'master_detail'].indexOf(fieldSchema.type) > -1 && fieldSchema.reference_to) {
            var fieldReferenceTo;
            if (fieldSchema.reference_to) {
              fieldReferenceTo = fieldSchema.reference_to;
              if (isFunction(fieldReferenceTo)) {
                fieldReferenceTo = fieldReferenceTo();
              }
            }
            if (fieldReferenceTo && fieldReferenceTo.length) {
              if (!isArray(fieldValue)) {
                fieldValue = [fieldValue];
              }
              var referenceTo,
                ids = [],
                labels = [];
              forEach(fieldValue, function (val) {
                referenceTo = val['reference_to.o'];
                if (referenceTo) {
                  var id = val["_id"];
                  var label = val["_NAME_FIELD_VALUE"];
                  if (id) {
                    ids.push(id);
                  }
                  if (label) {
                    labels.push(label);
                  }
                }
              });
              if (referenceTo) {
                if (isArray(fieldReferenceTo)) {
                  record[key].o = referenceTo;
                  if (ids && ids.length) {
                    record[key].ids = ids;
                  }
                  if (labels && labels.length) {
                    record[key].labels = labels;
                  }
                  delete record[key]["reference_to._o"];
                  delete record[key]["reference_to.o"];
                  delete record[key]._NAME_FIELD_VALUE;
                  delete record[key]._id;
                } else {
                  record[key] = fieldSchema.multiple ? ids : ids[0];
                }
              }
            }
          }
        }
      });
      return record;
    });
  }
  return data;
}