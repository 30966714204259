(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("PropTypes"));
	else if(typeof define === 'function' && define.amd)
		define(["PropTypes"], factory);
	else if(typeof exports === 'object')
		exports["DigiforceDesign"] = factory(require("PropTypes"));
	else
		root["DigiforceDesign"] = factory(root["PropTypes"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__6__) {
return 