import { SettingField, isSettingField, TransformStage, LiveEditing, isDragNodeDataObject, DragObjectType, isNode } from '@digiforce/dvd-designer';
import { Dragon } from '@digiforce/dvd-shell';
export default function getDesignerCabin(editor) {
  var designer = editor.get('designer');
  return {
    SettingField: SettingField,
    isSettingField: isSettingField,
    dragon: Dragon.create(designer.dragon),
    TransformStage: TransformStage,
    LiveEditing: LiveEditing,
    DragObjectType: DragObjectType,
    isDragNodeDataObject: isDragNodeDataObject,
    isNode: isNode
  };
}