import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/extends";
var _excluded = ["controlClassnames", "controlElements", "debugMode", "enableDragAndDrop", "enableMountQueryChange", "translations"];
import { QueryBuilderContext } from '@react-querybuilder/ctx';
import { useContext, useMemo } from 'react';
import { defaultControlElements } from '../../controls';
import { defaultControlClassnames, defaultTranslations } from '../../defaults';
import { mergeClassnames } from '../mergeClassnames';
import { objectKeys } from '../objectKeys';
import { usePreferProp } from './usePreferProp';
export var useMergedContext = function useMergedContext(props) {
  // Inherit context, but props take precedence
  var rqbContext = useContext(QueryBuilderContext);
  var enableMountQueryChange = usePreferProp(true, props.enableMountQueryChange, rqbContext.enableMountQueryChange);

  // Drag-and-drop should be disabled if context sets it to false because
  // QueryBuilderDnD might not have loaded react-dnd yet. Therefore we prefer
  // the prop here only if context is true or undefined.
  var enableDragAndDrop = usePreferProp(false, props.enableDragAndDrop, rqbContext.enableDragAndDrop) && rqbContext.enableDragAndDrop !== false;
  var debugMode = usePreferProp(false, props.debugMode, rqbContext.debugMode);
  var controlClassnames = useMemo(function () {
    return mergeClassnames(defaultControlClassnames, rqbContext.controlClassnames, props.controlClassnames);
  }, [rqbContext.controlClassnames, props.controlClassnames]);
  var controlElements = useMemo(function () {
    return _extends({}, defaultControlElements, rqbContext.controlElements, props.controlElements);
  }, [props.controlElements, rqbContext.controlElements]);
  var translations = useMemo(function () {
    var translationsTemp = {};
    objectKeys(props.translations).forEach(function (t) {
      var contextTranslations = rqbContext.translations;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Different keys have different requirements
      translationsTemp[t] = _extends({}, defaultTranslations[t], contextTranslations, props.translations[t]);
    });
    return _extends({}, defaultTranslations, translationsTemp);
  }, [rqbContext.translations, props.translations]);
  var _controlClassnames = rqbContext.controlClassnames,
    _controlElements = rqbContext.controlElements,
    _debugMode = rqbContext.debugMode,
    _enableDragAndDrop = rqbContext.enableDragAndDrop,
    _enableMountQueryChange = rqbContext.enableMountQueryChange,
    _translations = rqbContext.translations,
    otherContext = _objectWithoutPropertiesLoose(rqbContext, _excluded);
  return _extends({
    controlClassnames: controlClassnames,
    controlElements: controlElements,
    debugMode: debugMode,
    enableDragAndDrop: enableDragAndDrop,
    enableMountQueryChange: enableMountQueryChange,
    translations: translations
  }, otherContext);
};