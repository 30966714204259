import _extends from "@babel/runtime/helpers/extends";
import { dataServicesSelector, viewStateSelector } from '../../selectors';
import { loadEntitiesDataRequest } from '../records_request';
import { executeApiRequest } from '../api_request';
import { createAction as baseCreateAction } from '../base';
export var NOTIFICATIONS_STATE_CHANGE_ACTION = 'NOTIFICATIONS_STATE_CHANGE';
export var NOTIFICATIONS_COUNT_CHANGE_ACTION = 'NOTIFICATIONS_COUNT_CHANGE';
export var NOTIFICATIONS_INTERVAL_CHANGE_ACTION = 'NOTIFICATIONS_INTERVAL_CHANGE';
export function loadNotificationsData(options) {
  options = Object.assign({}, options, {
    objectName: "notifications",
    columns: [{
      field: "name"
    }, {
      field: "body"
    }, {
      field: "related_to"
    }, {
      field: "related_name"
    }, {
      field: "url"
    }, {
      field: "owner"
    }, {
      field: "is_read"
    }, {
      field: "from"
    }, {
      field: "created"
    }]
  });
  if (!options.pageSize) {
    options.pageSize = 10;
  }
  if (!options.filters) {
    options.filters = [['owner', '=', '{userId}']];
  }
  if (!options.sort) {
    options.sort = "created desc, name";
  }
  return function (dispatch, getState) {
    dispatch(loadNotificationsItems(options));
    dispatch(loadNotificationsCount(options));
  };
}
export function loadNotificationsItems(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    dispatch(baseCreateAction(NOTIFICATIONS_STATE_CHANGE_ACTION, "loading", true, options));
    loadEntitiesDataRequest(dispatch, NOTIFICATIONS_STATE_CHANGE_ACTION, service, options);
  };
}
export function loadNotificationsCount(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    // options.count = true;
    options = _extends({}, options, {
      count: true,
      pageSize: 0
    });
    options.filters = [].concat(options.filters);
    options.filters.push([['is_read', '=', null], 'or', ['is_read', '=', false]]);
    dispatch(baseCreateAction(NOTIFICATIONS_COUNT_CHANGE_ACTION, "countLoading", true, options));
    loadEntitiesDataRequest(dispatch, NOTIFICATIONS_COUNT_CHANGE_ACTION, service, options);
  };
}
export function loadNotificationsDataInterval(options) {
  return function (dispatch, getState) {
    var interval = options.interval ? options.interval : 5 * 60;
    var intervalCount = 1;
    var entityState = viewStateSelector(getState(), options.id);
    if (entityState && entityState.intervalId) {
      intervalCount = entityState.intervalCount + 1;
      clearTimeout(entityState.intervalId);
    }
    var intervalId = setTimeout(function () {
      dispatch(loadNotificationsDataInterval(options));
    }, interval * 1000);
    var intervalTime = new Date();
    dispatch(baseCreateAction(NOTIFICATIONS_INTERVAL_CHANGE_ACTION, 'startInterval', {
      intervalId: intervalId,
      intervalCount: intervalCount,
      intervalTime: intervalTime
    }, options));
    if (entityState && entityState.loading) {
      return;
    }
    dispatch(loadNotificationsData(options));
  };
}
export function clearNotificationsInterval(options) {
  return function (dispatch, getState) {
    var entityState = viewStateSelector(getState(), options.id);
    if (entityState) {
      clearTimeout(entityState.intervalId);
    }
    dispatch(baseCreateAction(NOTIFICATIONS_INTERVAL_CHANGE_ACTION, 'clearInterval', {
      intervalId: null,
      intervalCount: 0,
      intervalTime: null
    }, options));
  };
}
export function postNotificationsMethod(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    dispatch(baseCreateAction(NOTIFICATIONS_STATE_CHANGE_ACTION, "methodLoading", true, options));
    if (!options.url) {
      options.url = "/api/v4/notifications/" + options.methodRecordId + "/" + options.methodName;
    }
    return executeApiRequest(dispatch, NOTIFICATIONS_STATE_CHANGE_ACTION, service, options).then(function (sauce) {
      var entityState = viewStateSelector(getState(), options.id);
      var partialStateName = sauce.payload.partialStateName;
      var partialStateValue = sauce.payload.partialStateValue;
      if (partialStateName === "executeApiSauce" && partialStateValue.success) {
        if (options.methodName === "markReadAll") {
          var records = [].concat(entityState.rows);
          records = records.map(function (item) {
            var re = _extends({}, item);
            re.is_read = true;
            return re;
          });
          dispatch(baseCreateAction(NOTIFICATIONS_STATE_CHANGE_ACTION, options.methodName, {
            records: records
          }, options));
        }
      } else if (partialStateName === "executeApiError" || !partialStateValue.success) {
        dispatch(baseCreateAction(NOTIFICATIONS_STATE_CHANGE_ACTION, "executeApiError", partialStateValue, options));
      }
    });
  };
}