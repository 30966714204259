import { ORGANIZATIONS_STATE_CHANGE_ACTION } from '../../actions/views/organizations';
// import _ from 'lodash'

function getRootNodes(records) {
  if (records.length > 0) {
    return _.map(records, function (record) {
      return {
        expanded: true,
        id: record._id,
        label: record.name,
        selected: true,
        type: 'branch',
        nodes: record.children || []
      };
    });
  }
  return _.map(records, '_id');
}
function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === ORGANIZATIONS_STATE_CHANGE_ACTION) {
    var _Object$assign;
    var payload = action.payload;
    switch (payload.partialStateName) {
      case 'loadDataSauce':
        return Object.assign({}, state, {
          rootNodes: getRootNodes(payload.partialStateValue.records)
        });
      default:
        break;
    }
    return Object.assign({}, state, (_Object$assign = {}, _Object$assign[payload.partialStateName] = payload.partialStateValue, _Object$assign));
  }
  return state;
}
;
export default reducer;