import { useRef, useEffect } from "react";
import location from "../../../services/location";
export default function useUnsavedChangesAlert(shouldShowAlert) {
  if (shouldShowAlert === void 0) {
    shouldShowAlert = false;
  }
  var shouldShowAlertRef = useRef();
  shouldShowAlertRef.current = shouldShowAlert;
  useEffect(function () {
    var unloadMessage = "You will lose your changes if you leave";
    var confirmMessage = unloadMessage + "\n\nAre you sure you want to leave this page?";
    // store original handler (if any)
    var savedOnBeforeUnload = window.onbeforeunload;
    window.onbeforeunload = function onbeforeunload() {
      return shouldShowAlertRef.current ? unloadMessage : undefined;
    };
    var unsubscribe = location.confirmChange(function (nextLocation, currentLocation) {
      if (shouldShowAlertRef.current && nextLocation.path !== currentLocation.path) {
        return confirmMessage;
      }
    });
    return function () {
      window.onbeforeunload = savedOnBeforeUnload;
      unsubscribe();
    };
  }, []);
}