import { useEffect } from 'react';
import { errorDeprecatedRuleGroupProps, errorDeprecatedRuleProps } from '../../messages';
var didWarnUsingDeprecatedRuleProps = false;
var didWarnUsingDeprecatedRuleGroupProps = false;
export var useDeprecatedProps = function useDeprecatedProps(type, newPropPresent) {
  useEffect(function () {
    if (__RQB_DEV__ && type === 'rule' && !newPropPresent && !didWarnUsingDeprecatedRuleProps) {
      console.error(errorDeprecatedRuleProps);
      didWarnUsingDeprecatedRuleProps = true;
    }
    if (__RQB_DEV__ && type === 'ruleGroup' && !newPropPresent && !didWarnUsingDeprecatedRuleGroupProps) {
      console.error(errorDeprecatedRuleGroupProps);
      didWarnUsingDeprecatedRuleGroupProps = true;
    }
  }, [newPropPresent, type]);
};