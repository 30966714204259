import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Lookup from './salesforce_comboboxes';
import { loadLookupEntitiesData } from '../../actions';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
    ;
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  var mapDispatch = {
    onSearch: function onSearch(event, data) {
      return dispatch(ownProps.onSearch(event, data));
    },
    init: function init(options) {
      return dispatch(loadLookupEntitiesData(options));
    }
  };
  if (ownProps.onRequestRemoveSelectedOption) {
    mapDispatch.onRequestRemoveSelectedOption = function (event, data) {
      return dispatch(ownProps.onRequestRemoveSelectedOption(event, Object.assign({
        column: ownProps.column
      }, data)));
    };
  }
  return mapDispatch;
};
export default connect(mapStateToProps, mapDispatchToProps)(Lookup);