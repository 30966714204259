import { useEffect } from 'react';
import { errorEnabledDndWithoutReactDnD } from '../../messages';
var didWarnEnabledDndWithoutReactDnD = false;
export var useReactDndWarning = function useReactDndWarning(enableDragAndDrop, dndRefs) {
  useEffect(function () {
    if (__RQB_DEV__ && !didWarnEnabledDndWithoutReactDnD && enableDragAndDrop && !dndRefs) {
      console.error(errorEnabledDndWithoutReactDnD);
      didWarnEnabledDndWithoutReactDnD = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};