import { types } from "mobx-state-tree";
export var FormFieldModel = types.model({
  id: types.identifier,
  name: types.string,
  required: types["boolean"],
  readonly: types["boolean"]
});
export var FormModel = types.model({
  id: types.identifier,
  // objectApiName: types.string,
  // fields: types.array(FormFieldModel),
  mode: types.union(types.string, types.undefined),
  // 'read' | 'edit'
  value: types.frozen(),
  convertedFilters: types.frozen()
}).actions(function (self) {
  return {
    // note the `({`, we are returning an object literal
    setMode: function setMode(newMode) {
      self.mode = newMode;
    },
    getMode: function getMode() {
      return self.mode;
    },
    setValue: function setValue(value) {
      self.value = value;
    },
    setConvertedFilters: function setConvertedFilters(filters) {
      self.convertedFilters = filters;
    }
  };
});
export var Forms = types.model({
  items: types.optional(types.map(FormModel), {})
}).actions(function (self) {
  var loadById = function loadById(id) {
    if (!id) return null;
    var form = self.items.get(id);
    if (form) {
      return form;
    }
    var newForm = FormModel.create({
      id: id,
      mode: 'read'
    });
    self.items.put(newForm);
    return newForm;
  };
  var getById = function getById(id) {
    if (!id) return null;
    return self.items.get(id);
  };
  return {
    loadById: loadById,
    getById: getById
  };
}).create();