import WidgetObject from '../widget_object';
import WidgetConnect from './widget_connect';
import { getWidgetReductsConfig } from '../../utils';
var config = getWidgetReductsConfig();
var dealColumnsLabelAssistiveText = function dealColumnsLabelAssistiveText(assistiveText, columns) {
  var assistiveTextColumns = assistiveText.columns;
  if (!assistiveTextColumns) {
    return;
  }
  columns.forEach(function (column) {
    var field = column.field;
    var assistiveColumnLabelText = assistiveTextColumns[field];
    if (assistiveColumnLabelText) {
      column.label = assistiveColumnLabelText;
    }
  });
};
export var WidgetInstancesPendings = WidgetConnect(function (props) {
  var adapted = {};
  if (props.position === "RIGHT") {
    adapted.columns = [{
      "label": "Name",
      "field": "name",
      "href": true
    }];
    adapted.noHeader = true;
  }
  var adaptedConfig = Object.assign({}, config.instances_pendings, adapted);
  var assistiveText = props.assistiveText;
  if (assistiveText) {
    if (assistiveText.label) {
      adaptedConfig.label = assistiveText.label;
    }
    dealColumnsLabelAssistiveText(assistiveText, adaptedConfig.columns);
    if (assistiveText.illustrationMessageBody) {
      if (!adaptedConfig.illustration) {
        adaptedConfig.illustration = {
          messageBody: ""
        };
      }
      adaptedConfig.illustration.messageBody = assistiveText.illustrationMessageBody;
    }
  }
  return Object.assign({}, adaptedConfig, props);
})(WidgetObject);
WidgetInstancesPendings.displayName = "WidgetInstancesPendings";
export var WidgetAnnouncementsWeek = WidgetConnect(function (props) {
  var adapted = {};
  if (props.position === "RIGHT") {
    adapted.columns = [{
      "field": "name",
      "label": "Title",
      "href": true
    }];
    adapted.noHeader = true;
  }
  var adaptedConfig = Object.assign({}, config.announcements_week, adapted);
  var assistiveText = props.assistiveText;
  if (assistiveText) {
    if (assistiveText.label) {
      adaptedConfig.label = assistiveText.label;
    }
    dealColumnsLabelAssistiveText(assistiveText, adaptedConfig.columns);
    if (assistiveText.illustrationMessageBody) {
      if (!adaptedConfig.illustration) {
        adaptedConfig.illustration = {
          messageBody: ""
        };
      }
      adaptedConfig.illustration.messageBody = assistiveText.illustrationMessageBody;
    }
  }
  return Object.assign({}, adaptedConfig, props);
})(WidgetObject);
WidgetAnnouncementsWeek.displayName = "WidgetAnnouncementsWeek";
export var WidgetTasksToday = WidgetConnect(function (props) {
  var adapted = {};
  if (props.position !== "RIGHT") {
    adapted.columns = [{
      "field": "name",
      "label": "Name",
      "href": true
    }, {
      "field": "due_date",
      "label": "Due date",
      "width": "10rem",
      "type": "date"
    }];
    adapted.noHeader = false;
  }
  var adaptedConfig = Object.assign({}, config.tasks_today, adapted);
  var assistiveText = props.assistiveText;
  if (assistiveText) {
    if (assistiveText.label) {
      adaptedConfig.label = assistiveText.label;
    }
    dealColumnsLabelAssistiveText(assistiveText, adaptedConfig.columns);
    if (assistiveText.illustrationMessageBody) {
      if (!adaptedConfig.illustration) {
        adaptedConfig.illustration = {
          messageBody: ""
        };
      }
      adaptedConfig.illustration.messageBody = assistiveText.illustrationMessageBody;
    }
  }
  return Object.assign({}, adaptedConfig, props);
})(WidgetObject);
WidgetTasksToday.displayName = "WidgetTasksToday";
export var WidgetEventsToday = WidgetConnect(function (props) {
  var adapted = {};
  if (props.position !== "RIGHT") {
    adapted.columns = [{
      field: "name",
      label: "Name",
      href: true
    }, {
      "field": "start",
      "label": "Start",
      "width": "10rem",
      "type": "datetime"
    }];
    adapted.noHeader = false;
  }
  var adaptedConfig = Object.assign({}, config.events_today, adapted);
  var assistiveText = props.assistiveText;
  if (assistiveText) {
    if (assistiveText.label) {
      adaptedConfig.label = assistiveText.label;
    }
    dealColumnsLabelAssistiveText(assistiveText, adaptedConfig.columns);
    if (assistiveText.illustrationMessageBody) {
      if (!adaptedConfig.illustration) {
        adaptedConfig.illustration = {
          messageBody: ""
        };
      }
      adaptedConfig.illustration.messageBody = assistiveText.illustrationMessageBody;
    }
  }
  return Object.assign({}, adaptedConfig, props);
})(WidgetObject);
WidgetEventsToday.displayName = "WidgetEventsToday";