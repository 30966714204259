import { defaultJoinChar } from '../defaults';

/**
 * Splits a string by a given character (default ','). Escaped characters (characters
 * preceded by a backslash) will not apply to the split, and the backslash will be
 * removed in the array element. Inverse of `joinWith`.
 *
 * @example
 * splitBy('this\\,\\,that,,the other,,,\\,')
 * // or
 * splitBy('this\\,\\,that,,the other,,,\\,', ',')
 * // would return
 * ['this,,that', '', 'the other', '', '', ',']
 */
export var splitBy = function splitBy(str, splitChar) {
  if (splitChar === void 0) {
    splitChar = defaultJoinChar;
  }
  return typeof str === 'string' ? str.split("\\" + splitChar).map(function (c) {
    return c.split(splitChar);
  }).reduce(function (prev, curr, idx) {
    if (idx === 0) return curr;
    // prev[prev.length - 1] = `${prev[prev.length - 1]},${curr[0]}`;
    return [].concat(prev.slice(0, prev.length - 1), ["" + prev[prev.length - 1] + splitChar + curr[0]], curr.slice(1));
  }, []) : [];
};

/**
 * Joins an array of strings using the given character (default ','). When the given
 * character appears in an array element, a backslash will be added just before it to
 * distinguish it from the join character. Inverse of `splitBy`.
 *
 * @example
 * joinWith(['this,,that', '', 'the other', '', '', ','])
 * // would return
 * 'this\\,\\,that,,the other,,,\\,'
 */
export var joinWith = function joinWith(strArr, joinChar) {
  if (joinChar === void 0) {
    joinChar = defaultJoinChar;
  }
  return strArr.map(function (str) {
    return (str !== null && str !== void 0 ? str : '').replaceAll(joinChar, "\\" + joinChar);
  }).join(joinChar);
};

/**
 * Trims the value if it is a string. Otherwise returns value as-is.
 */
export var trimIfString = function trimIfString(val) {
  return typeof val === 'string' ? val.trim() : val;
};

/**
 * Splits strings by comma and trims each element; returns arrays as-is.
 */
export var toArray = function toArray(v) {
  return (Array.isArray(v) ? v : typeof v === 'string' ? splitBy(v, defaultJoinChar).filter(function (s) {
    return !/^\s*$/.test(s);
  }) : []).map(trimIfString);
};
export var nullFreeArray = function nullFreeArray(arr) {
  return arr.every(Boolean);
};