import { isArray, isObject, isString, isFunction, startsWith, reduce, merge, map, each } from "lodash";
import resizeObserver from "../../../services/resizeObserver";
import { Plotly, prepareData, prepareLayout, updateData, updateAxes, updateChartSize } from "../plotly";
function createErrorHandler(errorHandler) {
  return function (error) {
    // This error happens only when chart width is 20px and looks that
    // it's safe to just ignore it: 1px less or more and chart will get fixed.
    if (isString(error) && startsWith(error, "ax.dtick error")) {
      return;
    }
    errorHandler(error);
  };
}

// This utility is intended to reduce amount of plot updates when multiple Plotly.relayout
// calls needed in order to compute/update the plot.
// `.append()` method takes an array of two element: first one is a object with updates for layout,
// and second is an optional function that will be called when plot is updated. That function may
// return an array with same structure if further updates needed.
// `.process()` merges all updates into a single object and calls `Plotly.relayout()`. After that
// it calls all callbacks, collects their return values and does another loop if needed.
function initPlotUpdater() {
  var actions = [];
  var updater = {
    append: function append(action) {
      if (isArray(action) && isObject(action[0])) {
        actions.push(action);
      }
      return updater;
    },
    // @ts-expect-error ts-migrate(7023) FIXME: 'process' implicitly has return type 'any' because... Remove this comment to see the full error message
    process: function process(plotlyElement) {
      if (actions.length > 0) {
        var updates = reduce(actions, function (updates, action) {
          return merge(updates, action[0]);
        }, {});
        var handlers = map(actions, function (action) {
          return isFunction(action[1]) ? action[1] : function () {
            return null;
          };
        });
        actions = [];
        return Plotly.relayout(plotlyElement, updates).then(function () {
          each(handlers, function (handler) {
            return updater.append(handler());
          });
          return updater.process(plotlyElement);
        });
      } else {
        return Promise.resolve();
      }
    }
  };
  return updater;
}
export default function initChart(container, options, data, additionalOptions, onError) {
  var handleError = createErrorHandler(onError);
  var plotlyOptions = {
    showLink: false,
    displaylogo: false
  };
  if (additionalOptions.hidePlotlyModeBar) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'displayModeBar' does not exist on type '... Remove this comment to see the full error message
    plotlyOptions.displayModeBar = false;
  }
  var plotlyData = prepareData(data, options);
  var plotlyLayout = prepareLayout(container, options, plotlyData);
  var isDestroyed = false;
  var updater = initPlotUpdater();
  function createSafeFunction(fn) {
    // @ts-expect-error ts-migrate(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    return function () {
      if (!isDestroyed) {
        try {
          return fn.apply(void 0, arguments);
        } catch (error) {
          handleError(error);
        }
      }
    };
  }
  var unwatchResize = function unwatchResize() {};
  var promise = Promise.resolve().then(function () {
    return Plotly.newPlot(container, plotlyData, plotlyLayout, plotlyOptions);
  }).then(createSafeFunction(function () {
    return updater.append(updateAxes(container, plotlyData, plotlyLayout, options)).append(updateChartSize(container, plotlyLayout, options)).process(container);
  })).then(createSafeFunction(function () {
    container.on("plotly_restyle", createSafeFunction(function (updates) {
      // This event is triggered if some plotly data/layout has changed.
      // We need to catch only changes of traces visibility to update stacking
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'visible' does not exist on type 'object'... Remove this comment to see the full error message
      if (isArray(updates) && isObject(updates[0]) && updates[0].visible) {
        updateData(plotlyData, options);
        updater.append(updateAxes(container, plotlyData, plotlyLayout, options)).process(container);
      }
    }));
    options.onHover && container.on("plotly_hover", options.onHover);
    options.onUnHover && container.on("plotly_unhover", options.onUnHover);
    unwatchResize = resizeObserver(container, createSafeFunction(function () {
      updater.append(updateChartSize(container, plotlyLayout, options)).process(container);
    }));
  }))["catch"](handleError);

  // @ts-expect-error ts-migrate(7022) FIXME: 'result' implicitly has type 'any' because it does... Remove this comment to see the full error message
  var result = {
    initialized: promise.then(function () {
      return result;
    }),
    setZoomEnabled: createSafeFunction(function (allowZoom) {
      var layoutUpdates = {
        dragmode: allowZoom ? "zoom" : false
      };
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ dragmode: string | boolean; }'... Remove this comment to see the full error message
      return Plotly.relayout(container, layoutUpdates);
    }),
    destroy: createSafeFunction(function () {
      isDestroyed = true;
      container.removeAllListeners("plotly_restyle");
      unwatchResize();
      delete container.__previousSize; // added by `updateChartSize`
      Plotly.purge(container);
    })
  };
  return result;
}