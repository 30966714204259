import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Notifications from './notifications';
import { loadNotificationsDataInterval, clearNotificationsInterval, loadNotificationsData, postNotificationsMethod } from '../../actions';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    init: function init(options) {
      if (options.interval) {
        dispatch(loadNotificationsDataInterval(options));
      } else if (options.loadDataAfterRender) {
        dispatch(loadNotificationsData(options));
      }
    },
    exist: function exist(options) {
      if (options.interval) {
        dispatch(clearNotificationsInterval(options));
      }
    },
    onMarkReadAll: function onMarkReadAll(event, data) {
      var options;
      if (ownProps.markReadAllApiUrl) {
        options = {
          url: ownProps.markReadAllApiUrl,
          methodName: "markReadAll"
        };
      } else {
        options = {
          methodRecordId: "all",
          methodName: "markReadAll"
        };
      }
      dispatch(postNotificationsMethod(_extends({}, ownProps, options)));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);