export var DEFAULT_FORM_SCHEMA = {
  title: "Digiforce Object Form",
  screenshot: "https://alifd.alicdn.com/fusion-cool/icons/icon-antd/form-1.png",
  schema: {
    componentName: "Form",
    props: {
      labelCol: {
        span: 6
      },
      wrapperCol: {
        span: 14
      },
      name: "basic",
      __events: {
        eventDataList: [{
          type: {
            target: {
              value: "componentEvent",
              checked: true,
              type: "radio",
              prefixCls: "ant-radio-button",
              className: "",
              style: {},
              defaultChecked: false
            },
            nativeEvent: {
              isTrusted: true
            }
          },
          name: "onFinish",
          relatedEventName: "onFinish"
        }, {
          type: {
            target: {
              value: "componentEvent",
              checked: true,
              type: "radio",
              prefixCls: "ant-radio-button",
              className: "",
              style: {},
              defaultChecked: false
            },
            nativeEvent: {
              isTrusted: true
            }
          },
          name: "onFinishFailed",
          relatedEventName: "onFinishFailed"
        }]
      }
    },
    onFinish: {
      type: "JSFunction",
      value: "function(){this.onFinish.apply(this,Array.prototype.slice.call(arguments).concat([])) }"
    },
    onFinishFailed: {
      type: "JSFunction",
      value: "function(){this.onFinishFailed.apply(this,Array.prototype.slice.call(arguments).concat([])) }"
    }
  }
};