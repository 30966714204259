import { LiveEditing } from '@digiforce/dvd-designer';
import { isJSExpression } from '@digiforce/dvd-types';
function getText(node, prop) {
  var p = node.getProp(prop, false);
  if (!p || p.isUnset()) {
    return null;
  }
  var v = p.getValue();
  if (isJSExpression(v)) {
    v = v.mock;
  }
  if (v == null) {
    return null;
  }
  if (p.type === 'literal') {
    return v;
  }
  return Symbol["for"]('not-literal');
}
export function liveEditingRule(target) {
  // for vision components specific
  var node = target.node,
    event = target.event;
  var targetElement = event.target;
  if (!Array.from(targetElement.childNodes).every(function (item) {
    return item.nodeType === Node.TEXT_NODE;
  })) {
    return null;
  }
  var innerText = targetElement.innerText;
  var propTarget = ['title', 'label', 'text', 'content', 'children'].find(function (prop) {
    return equalText(getText(node, prop), innerText);
  });
  if (propTarget) {
    return {
      propElement: targetElement,
      propTarget: propTarget
    };
  }
  return null;
}
function equalText(v, innerText) {
  // TODO: enhance compare text logic
  if (typeof v !== 'string') {
    return false;
  }
  return v.trim() === innerText;
}
export var liveEditingSaveHander = {
  condition: function condition(prop) {
    // const v = prop.getValue();
    return prop.type === 'expression'; // || isI18nData(v);
  },

  onSaveContent: function onSaveContent(content, prop) {
    var v = prop.getValue();
    var data = v;
    if (isJSExpression(v)) {
      data = v.mock;
    }
    data = content;
    if (isJSExpression(v)) {
      prop.setValue({
        type: 'JSExpression',
        value: v.value,
        mock: data
      });
    } else {
      prop.setValue(data);
    }
  }
};
// TODO:
// 非文本编辑
//  国际化数据，改变当前
//  JSExpression, 改变 mock 或 弹出绑定变量

LiveEditing.addLiveEditingSpecificRule(liveEditingRule);
LiveEditing.addLiveEditingSaveHandler(liveEditingSaveHander);