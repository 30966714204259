import { dataServicesSelector } from '../../selectors';
import { loadEntitiesDataRequest } from '../records_request';
import { createAction as baseCreateAction } from '../base';
export var FLOWSTREE_STATE_CHANGE_ACTION = 'FLOWSTREE_STATE_CHANGE';
export var createFlowsTreeAction = function createFlowsTreeAction(partialStateName, partialStateValue, options) {
  return baseCreateAction(FLOWSTREE_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
};
export function loadFlowsTreeEntitiesData(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, FLOWSTREE_STATE_CHANGE_ACTION, service, options);
  };
}