import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Dashboard from './slds_dashboard';
function mapStateToProps() {
  return function (state, ownProps) {
    return Object.assign({}, _extends({}, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    init: function init(options) {}
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);