import _extends from "@babel/runtime/helpers/extends";
import { fieldIsValidUtil, getFieldsArray } from '../parserUtils';
import { celParser } from './celParser';
import { evalCELLiteralValue, generateFlatAndOrList, generateMixedAndOrList, isCELConditionalAnd, isCELConditionalOr, isCELExpressionGroup, isCELIdentifier, isCELLikeExpression, isCELList, isCELLiteral, isCELMap, isCELNegation, isCELRelation, isCELStringLiteral, normalizeOperator } from './utils';

/**
 * Converts a CEL string expression into a query suitable for
 * the QueryBuilder component's `query` or `defaultQuery` props.
 */

function parseCEL(cel, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options,
    fields = _options.fields,
    independentCombinators = _options.independentCombinators,
    listsAsArrays = _options.listsAsArrays;
  var ic = !!independentCombinators;
  var fieldsFlat = getFieldsArray(fields);
  var fieldIsValid = function fieldIsValid(fieldName, operator, subordinateFieldName) {
    var _options2;
    return fieldIsValidUtil({
      fieldName: fieldName,
      fieldsFlat: fieldsFlat,
      operator: operator,
      subordinateFieldName: subordinateFieldName,
      getValueSources: (_options2 = options) === null || _options2 === void 0 ? void 0 : _options2.getValueSources
    });
  };
  var emptyQuery = _extends({
    rules: []
  }, ic ? {} : {
    combinator: 'and'
  });
  var processCELExpression = function processCELExpression(expr, processOpts) {
    if (processOpts === void 0) {
      processOpts = {};
    }
    var _processOpts = processOpts,
      forwardedNegation = _processOpts.forwardNegation,
      groupOnlyIfNecessary = _processOpts.groupOnlyIfNecessary;
    /* istanbul ignore if */
    if (isCELNegation(expr)) {
      var negate = expr.negations % 2 === 1;
      // TODO?: forwardNegation when isCELRelation(expr.value.value), in addition
      // to CELLikeExpression? ('<=' becomes '>', 'in' becomes 'notIn', etc.)
      var negatedExpr = isCELExpressionGroup(expr.value) && isCELLikeExpression(expr.value.value) ? processCELExpression(expr.value.value, {
        forwardNegation: negate
      }) : processCELExpression(expr.value, {
        groupOnlyIfNecessary: true,
        forwardNegation: negate
      });
      if (negatedExpr) {
        if (!negate || negate && !('rules' in negatedExpr) && negatedExpr.operator.startsWith('doesNot')) {
          return ic ? {
            rules: [negatedExpr]
          } : {
            combinator: 'and',
            rules: [negatedExpr]
          };
        }
        return ic ? {
          rules: [negatedExpr],
          not: true
        } : {
          combinator: 'and',
          rules: [negatedExpr],
          not: true
        };
      }
    } else if (isCELExpressionGroup(expr)) {
      var rule = processCELExpression(expr.value, {
        groupOnlyIfNecessary: true
      });
      if (rule) {
        if ('rules' in rule || groupOnlyIfNecessary && isCELExpressionGroup(expr.value)) {
          return rule;
        }
        return ic ? {
          rules: [rule]
        } : {
          combinator: 'and',
          rules: [rule]
        };
      }
    } else if (isCELConditionalAnd(expr) || isCELConditionalOr(expr)) {
      if (ic) {
        var _andOrList = generateFlatAndOrList(expr);
        var _rules = _andOrList.map(function (v) {
          if (typeof v === 'string') {
            return v;
          }
          return processCELExpression(v);
        });
        // Bail out completely if any rules in the list were invalid
        // so as not to return an incorrect and/or sequence
        if (!_rules.every(Boolean)) {
          return null;
        }
        return {
          rules: _rules
        };
      }
      var andOrList = generateMixedAndOrList(expr);
      var combinator = andOrList[1];
      var filteredList = andOrList.filter(function (v) {
        return Array.isArray(v) || !!v && typeof v !== 'string' && 'type' in v;
      }).map(function (v) {
        return Array.isArray(v) ? v.filter(function (vf) {
          return !!v && typeof vf !== 'string' && 'type' in vf;
        }) : v;
      });
      var rules = filteredList.map(function (exp) {
        if (Array.isArray(exp)) {
          return {
            combinator: 'and',
            rules: exp.map(function (e) {
              return processCELExpression(e);
            }).filter(function (r) {
              return !!r;
            })
          };
        }
        return processCELExpression(exp);
      }).filter(function (r) {
        return !!r;
      });
      /* istanbul ignore else */
      if (rules.length > 0) {
        return {
          combinator: combinator,
          rules: rules
        };
      }
    } else if (isCELLikeExpression(expr)) {
      var field = expr.left.value,
        func = expr.right.value;
      var operatorPre = func === 'startsWith' ? 'beginsWith' : func;
      var operator = forwardedNegation ? "doesNot" + operatorPre[0].toUpperCase() + operatorPre.slice(1).replace('s', '') : operatorPre;
      var valueObj = expr.list.value[0];
      var value = isCELStringLiteral(valueObj) ? evalCELLiteralValue(valueObj) : valueObj.value;
      var valueSource = expr.list.value[0].type === 'Identifier' ? 'field' : undefined;
      if (fieldIsValid(field, operator, valueSource === 'field' ? value : undefined)) {
        return valueSource ? {
          field: field,
          operator: operator,
          value: value,
          valueSource: valueSource
        } : {
          field: field,
          operator: operator,
          value: value
        };
      }
    } else if (isCELRelation(expr)) {
      var _field = null;
      var _value = undefined;
      var _valueSource = undefined;
      var flip = false;
      var left = expr.left,
        right = expr.right;
      if (isCELIdentifier(left)) {
        _field = left.value;
        if (isCELIdentifier(right)) {
          _value = right.value;
          _valueSource = 'field';
        } else if (isCELLiteral(right)) {
          _value = evalCELLiteralValue(right);
        }
      } else {
        /* istanbul ignore else */
        if (isCELIdentifier(right) && isCELLiteral(left) && expr.operator !== 'in') {
          flip = true;
          _field = right.value;
          _value = evalCELLiteralValue(left);
        }
      }
      var _operator = normalizeOperator(expr.operator, flip);
      if (_value === null && (_operator === '=' || _operator === '!=')) {
        _operator = _operator === '=' ? 'null' : 'notNull';
      } else if (_operator === 'in' && isCELList(right)) {
        if (right.value.value.every(isCELLiteral)) {
          _value = right.value.value.map(evalCELLiteralValue);
        } else {
          if (right.value.value.every(isCELIdentifier)) {
            _valueSource = 'field';
            _value = right.value.value.map(function (id) {
              return id.value;
            });
          }
        }
        if (_value && !listsAsArrays) {
          _value = _value.map(function (v) {
            return "" + v;
          }).join(',');
        }
      } else if (_operator === 'in' && isCELMap(right)) {
        var keys = right.value.value.map(function (v) {
          return v.left;
        });
        if (keys.every(function (k) {
          return isCELLiteral(k) || isCELIdentifier(k);
        })) {
          _value = keys.map(function (k) {
            return isCELLiteral(k) ? evalCELLiteralValue(k) : k.value;
          });
        }
        if (_value && !listsAsArrays) {
          _value = _value.map(function (v) {
            return "" + v;
          }).join(',');
        }
      }
      if (_field && fieldIsValid(_field, _operator, _valueSource === 'field' ? _value : undefined) && typeof _value !== 'undefined') {
        return _valueSource ? {
          field: _field,
          operator: _operator,
          value: _value,
          valueSource: _valueSource
        } : {
          field: _field,
          operator: _operator,
          value: _value
        };
      }
    }
    return null;
  };
  var processedCEL;
  try {
    processedCEL = celParser.parse(cel).value;
  } catch (err) {
    return emptyQuery;
  }
  var result = processCELExpression(processedCEL);
  if (result) {
    if ('rules' in result) {
      return result;
    }
    return _extends({
      rules: [result]
    }, ic ? {} : {
      combinator: 'and'
    });
  }
  return emptyQuery;
}
export { parseCEL };