import { isPojo } from './parserUtils';
export var isValidationResult = function isValidationResult(vr) {
  return isPojo(vr) && typeof vr.valid === 'boolean';
};
export var isRuleOrGroupValid = function isRuleOrGroupValid(rg, validationResult, validator) {
  if (typeof validationResult === 'boolean') {
    return validationResult;
  }
  if (isValidationResult(validationResult)) {
    return validationResult.valid;
  }
  if (typeof validator === 'function' && !('rules' in rg)) {
    var vr = validator(rg);
    if (typeof vr === 'boolean') {
      return vr;
    }
    // istanbul ignore else
    if (isValidationResult(vr)) {
      return vr.valid;
    }
  }
  return true;
};