function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
// NOTE: 仅用作类型标注，切勿作为实体使用

import { AssetLevel, AssetLevels, isAssetBundle, isAssetItem, AssetType, assetItem, isCSSUrl } from '@digiforce/dvd-utils';
export function createSimulator(host, iframe, vendors) {
  if (vendors === void 0) {
    vendors = [];
  }
  var win = iframe.contentWindow;
  var doc = iframe.contentDocument;
  win.LCSimulatorHost = host;
  var styles = {};
  var scripts = {};
  AssetLevels.forEach(function (lv) {
    styles[lv] = [];
    scripts[lv] = [];
  });
  function parseAssetList(assets, level) {
    for (var _iterator = _createForOfIteratorHelperLoose(assets), _step; !(_step = _iterator()).done;) {
      var asset = _step.value;
      if (!asset) {
        continue;
      }
      if (isAssetBundle(asset)) {
        if (asset.assets) {
          parseAssetList(Array.isArray(asset.assets) ? asset.assets : [asset.assets], asset.level || level);
        }
        continue;
      }
      if (Array.isArray(asset)) {
        parseAssetList(asset, level);
        continue;
      }
      if (!isAssetItem(asset)) {
        asset = assetItem(isCSSUrl(asset) ? AssetType.CSSUrl : AssetType.JSUrl, asset, level);
      }
      var id = asset.id ? " data-id=\"" + asset.id + "\"" : '';
      var lv = asset.level || level || AssetLevel.Environment;
      if (asset.type === AssetType.JSUrl) {
        scripts[lv].push("<script src=\"" + asset.content + "\"" + id + "></script>");
      } else if (asset.type === AssetType.JSText) {
        scripts[lv].push("<script" + id + ">" + asset.content + "</script>");
      } else if (asset.type === AssetType.CSSUrl) {
        styles[lv].push("<link rel=\"stylesheet\" href=\"" + asset.content + "\"" + id + " />");
      } else if (asset.type === AssetType.CSSText) {
        styles[lv].push("<style type=\"text/css\"" + id + ">" + asset.content + "</style>");
      }
    }
  }
  parseAssetList(vendors);
  var styleFrags = Object.keys(styles).map(function (key) {
    return styles[key].join('\n') + "<meta level=\"" + key + "\" />";
  }).join('');
  var scriptFrags = Object.keys(scripts).map(function (key) {
    return scripts[key].join('\n');
  }).join('');
  doc.open();
  doc.write("\n<!doctype html>\n<html class=\"engine-design-mode\">\n  <head><meta charset=\"utf-8\"/>\n    " + styleFrags + "\n  </head>\n  <body>\n    " + scriptFrags + "\n  </body>\n</html>");
  doc.close();
  return new Promise(function (resolve) {
    var renderer = win.SimulatorRenderer || host.renderer;
    if (renderer) {
      return resolve(renderer);
    }
    var loaded = function loaded() {
      resolve(win.SimulatorRenderer || host.renderer);
      win.removeEventListener('load', loaded);
    };
    win.addEventListener('load', loaded);
  });
}