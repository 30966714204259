import { maxBy } from "lodash";
import chroma from "chroma-js";
export default function chooseTextColorForBackground(backgroundColor, textColors) {
  if (textColors === void 0) {
    textColors = ["#ffffff", "#333333"];
  }
  try {
    backgroundColor = chroma(backgroundColor);
    return maxBy(textColors, function (color) {
      return chroma.contrast(backgroundColor, color);
    });
  } catch (e) {
    return null;
  }
}