import _extends from "@babel/runtime/helpers/extends";
function _createForOfIteratorHelperLoose(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (it) return (it = it.call(o)).next.bind(it); if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; return function () { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export default function transform(raw) {
  var _raw$groupList, _raw$sort, _raw$sort2, _raw$componentList;
  var t = getTextReader('en_US');
  var groupList = [];
  var categoryList = [];
  var ignoreComponents = {};
  var componentList = [];
  if (raw !== null && raw !== void 0 && (_raw$groupList = raw.groupList) !== null && _raw$groupList !== void 0 && _raw$groupList.length) {
    groupList = raw.groupList;
  } else {
    groupList = [createI18n('Components', 'Components')];
  }
  if (raw !== null && raw !== void 0 && raw.ignoreComponents) {
    ignoreComponents = raw.ignoreComponents;
  }
  var snippets = [];
  var groups = [];
  if (raw !== null && raw !== void 0 && (_raw$sort = raw.sort) !== null && _raw$sort !== void 0 && _raw$sort.groupList && raw !== null && raw !== void 0 && (_raw$sort2 = raw.sort) !== null && _raw$sort2 !== void 0 && _raw$sort2.categoryList) {
    var _raw$components;
    var map = {};
    groupList = raw.sort.groupList;
    categoryList = raw.sort.categoryList;
    componentList = (_raw$components = raw.components) === null || _raw$components === void 0 ? void 0 : _raw$components.filter(function (component) {
      var _component$snippets, _component$npm;
      var ignore = (component === null || component === void 0 ? void 0 : component.hidden) || !(component !== null && component !== void 0 && (_component$snippets = component.snippets) !== null && _component$snippets !== void 0 && _component$snippets.length) || (ignoreComponents[component === null || component === void 0 ? void 0 : (_component$npm = component.npm) === null || _component$npm === void 0 ? void 0 : _component$npm["package"]] || {})[component.componentName];
      return !ignore;
    }).map(function (component) {
      component.snippets.forEach(function (snippet) {
        snippet.id = t(component.group) + "_" + t(component.category) + "_" + component.componentName + "_" + snippet.title;
        snippets.push(snippet);
      });
      var _component$group = component.group,
        group = _component$group === void 0 ? createI18n('Default', 'Default') : _component$group,
        category = component.category,
        _component$priority = component.priority,
        componentPriority = _component$priority === void 0 ? 0 : _component$priority;
      group = typeof group !== 'string' ? 'Default' : group;
      component.group = group;
      component.priority = componentPriority;
      var indexOfCategory = categoryList.indexOf(category);
      var categoryPriority = indexOfCategory === -1 ? -1 : categoryList.length - indexOfCategory;
      if (!map[t(group)]) {
        var groupIndex = groupList.indexOf(group);
        var groupPriority = groupIndex === -1 ? -1 : groupList.length - groupIndex;
        var obj = {
          name: group,
          content: {},
          categories: [],
          priority: groupPriority
        };
        map[t(group)] = obj;
        groups.push(obj);
      }
      var currentGroup = map[t(group)];
      if (!currentGroup.content[t(category)]) {
        var cateObj = {
          components: [],
          name: category,
          priority: categoryPriority
        };
        currentGroup.content[t(category)] = cateObj;
        currentGroup.categories.push(cateObj);
      }
      var currentCategory = currentGroup.content[t(category)];
      currentCategory.components.push(component);
      return component;
    });
  } else if (raw !== null && raw !== void 0 && (_raw$componentList = raw.componentList) !== null && _raw$componentList !== void 0 && _raw$componentList.length) {
    var _map = {};
    componentList = pipe(raw.componentList).pipe(flatten).pipe(formatSort).pipe(formatSnippets).pipe(function (stdComponent) {
      var sort = stdComponent.sort;
      if (stdComponent.snippets.length) {
        stdComponent.snippets.forEach(function (snippet) {
          snippet.id = t(sort.group) + "_" + t(sort.category) + "_" + snippet.componentName + "_" + snippet.title;
          snippets.push(snippet);
        });
      }
      return [stdComponent];
    }).pipe(function (stdComponent) {
      var sort = stdComponent.sort;
      var group = sort.group,
        category = sort.category,
        _sort$priority = sort.priority,
        priority = _sort$priority === void 0 ? 0 : _sort$priority;
      var hasGroup = textExistIn(group, groupList);
      if (hasGroup) {
        if (!_map[t(group)]) {
          var groupIndex = groupList.indexOf(group);
          var groupPriority = groupIndex === -1 ? -1 : groupList.length - groupIndex;
          var obj = {
            name: group,
            content: {},
            categories: [],
            priority: groupPriority
          };
          _map[t(group)] = obj;
          groups.push(obj);
        }
        var currentGroup = _map[t(group)];
        if (!currentGroup.content[t(category)]) {
          var cateObj = {
            components: [],
            name: category,
            priority: priority
          };
          currentGroup.content[t(category)] = cateObj;
          currentGroup.categories.push(cateObj);
        }
        var currentCategory = currentGroup.content[t(category)];
        currentCategory.components.push(stdComponent);
      }
      return [stdComponent];
    }).run();
  }
  groups.sort(function (a, b) {
    return b.priority - a.priority;
  });
  groups.forEach(function (group) {
    if (!group.categories || !group.categories.length) {
      return;
    }
    group.categories.sort(function (a, b) {
      return b.priority - a.priority;
    });
    group.categories = group.categories.map(function (category) {
      var _category$components;
      category === null || category === void 0 ? void 0 : (_category$components = category.components) === null || _category$components === void 0 ? void 0 : _category$components.sort(function (a, b) {
        return (b.priority || 0) - (a.priority || 0);
      });
      return category;
    });
  });
  return {
    groupList: groupList,
    componentList: componentList,
    groups: groups,
    snippets: snippets
  };
}
function flatten(item) {
  if (typeof item === 'object') {
    var _item$children;
    if (item !== null && item !== void 0 && (_item$children = item.children) !== null && _item$children !== void 0 && _item$children.length) {
      return item.children.map(function (c) {
        return _extends({
          category: item.title
        }, c);
      });
    }
    if (item.componentName) {
      return [item];
    }
  }
  return [];
}
function formatSort(item) {
  var _item$sort = item.sort,
    sort = _item$sort === void 0 ? {} : _item$sort;
  var category = sort.category || item.category;
  var useDefaultCategory = !category;
  item.sort = _extends({
    group: createI18n('Components', 'Components')
  }, sort, {
    category: category || createI18n('Others', 'Others'),
    priority: useDefaultCategory ? -1 : sort.priority
  });
  if (item.sort.hidden) {
    return [];
  }
  return [item];
}
function formatSnippets(item) {
  item.snippets = item.snippets || [];
  if (item.snippets.length === 0) {
    item.snippets = [{
      componentName: item.componentName,
      schema: {
        componentName: item.componentName,
        props: item.props || item.defaultProps || {}
      }
    }];
  }
  item.snippets = pipe(item.snippets).pipe(function (snippet) {
    if (!snippet.schema) {
      return [];
    }
    if (!snippet.screenshot) {
      snippet.screenshot = item.icon;
    }
    if (!snippet.componentName) {
      snippet.componentName = item.componentName;
    }
    return [snippet];
  }).run();
  return [item];
}
export function getTextReader(lang) {
  return function (input) {
    if (typeof input === 'string') {
      return input;
    }
    if (typeof input === 'object' && input.type === 'i18n') {
      return input[lang];
    }
    return '';
  };
}
export function createI18n(zh_CN, en_US) {
  return {
    zh_CN: zh_CN,
    en_US: en_US,
    type: 'i18n'
  };
}
export function pipe(arr) {
  var fns = [];
  var last = function last(ret) {
    return ret;
  };
  function _run(buffer, fn, next) {
    if (!(buffer !== null && buffer !== void 0 && buffer.length)) {
      return [];
    }
    var push = [].concat(buffer);
    var pop = [];
    for (var _iterator = _createForOfIteratorHelperLoose(push), _step; !(_step = _iterator()).done;) {
      var _item = _step.value;
      var result = fn(_item) || [];
      var data = next(result) || [];
      pop.push.apply(pop, data);
    }
    return pop;
  }
  return {
    pipe: function pipe(fn) {
      fns.push(fn);
      return this;
    },
    run: function run() {
      var process = [].concat(fns, [last]).reduceRight(function (next, fn) {
        return function (input) {
          return _run(input, fn, next);
        };
      });
      return process(arr);
    }
  };
}
export function textExistIn(text, arr) {
  var t = getTextReader('en_US');
  return !!arr.find(function (item) {
    return t(item) === t(text);
  });
}