import _extends from "@babel/runtime/helpers/extends";
import { includes, has, get, map, first, isFunction, isEmpty, startsWith } from "lodash";
import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import notification from "../../../notification";
import DatabricksDataSource from "../../../../services/databricks-data-source";
function getDatabases(dataSource, refresh) {
  if (refresh === void 0) {
    refresh = false;
  }
  if (!dataSource) {
    return Promise.resolve([]);
  }
  return DatabricksDataSource.getDatabases(dataSource, refresh)["catch"](function () {
    notification.error("Failed to load Database list.", "Please try again later.");
    return Promise.reject();
  });
}
function getSchema(dataSource, databaseName, refresh) {
  if (refresh === void 0) {
    refresh = false;
  }
  if (!dataSource || !databaseName) {
    return Promise.resolve([]);
  }
  return DatabricksDataSource.getDatabaseTables(dataSource, databaseName, refresh)["catch"](function () {
    notification.error("Failed to load tables for " + databaseName + ".", "Please try again later.");
    return Promise.reject();
  });
}
function addDisplayNameWithoutDatabaseName(schema, databaseName) {
  if (!databaseName) {
    return schema;
  }
  // add display name without {databaseName} + "."
  return map(schema, function (table) {
    var databaseNamePrefix = databaseName + ".";
    var displayName = table.name;
    if (startsWith(table.name, databaseNamePrefix)) {
      displayName = table.name.slice(databaseNamePrefix.length);
    }
    return _extends({}, table, {
      displayName: displayName
    });
  });
}
export default function useDatabricksSchema(dataSource, options, onOptionsUpdate) {
  if (options === void 0) {
    options = null;
  }
  if (onOptionsUpdate === void 0) {
    onOptionsUpdate = null;
  }
  var _useState = useState([]),
    databases = _useState[0],
    setDatabases = _useState[1];
  var _useState2 = useState(true),
    loadingDatabases = _useState2[0],
    setLoadingDatabases = _useState2[1];
  var _useState3 = useState(),
    currentDatabaseName = _useState3[0],
    setCurrentDatabaseName = _useState3[1];
  var _useState4 = useState({}),
    schemas = _useState4[0],
    setSchemas = _useState4[1];
  var _useState5 = useState(false),
    loadingSchema = _useState5[0],
    setLoadingSchema = _useState5[1];
  var _useState6 = useState(false),
    refreshing = _useState6[0],
    setRefreshing = _useState6[1];
  var setCurrentSchema = useCallback(function (schema) {
    return setSchemas(function (currentSchemas) {
      var _extends2;
      return _extends({}, currentSchemas, (_extends2 = {}, _extends2[currentDatabaseName] = schema, _extends2));
    });
  }, [currentDatabaseName]);
  var currentDatabaseNameRef = useRef();
  currentDatabaseNameRef.current = currentDatabaseName;
  var loadTableColumns = useCallback(function (tableName) {
    // remove [databaseName.] from the tableName
    DatabricksDataSource.getTableColumns(dataSource, currentDatabaseName, tableName.substring(currentDatabaseName.length + 1)).then(function (columns) {
      if (currentDatabaseNameRef.current === currentDatabaseName) {
        setSchemas(function (currentSchemas) {
          var _extends3;
          var schema = get(currentSchemas, currentDatabaseName, []);
          var updatedSchema = map(schema, function (table) {
            if (table.name === tableName) {
              return _extends({}, table, {
                columns: columns,
                loading: false
              });
            }
            return table;
          });
          return _extends({}, currentSchemas, (_extends3 = {}, _extends3[currentDatabaseName] = updatedSchema, _extends3));
        });
      }
    });
  }, [dataSource, currentDatabaseName]);
  var schema = useMemo(function () {
    var currentSchema = get(schemas, currentDatabaseName, []);
    return addDisplayNameWithoutDatabaseName(currentSchema, currentDatabaseName);
  }, [schemas, currentDatabaseName]);
  var refreshAll = useCallback(function () {
    if (!refreshing) {
      setRefreshing(true);
      var getDatabasesPromise = getDatabases(dataSource, true).then(setDatabases);
      var getSchemasPromise = getSchema(dataSource, currentDatabaseName, true).then(function (_ref) {
        var schema = _ref.schema;
        return setCurrentSchema(schema);
      });
      Promise.all([getSchemasPromise["catch"](function () {}), getDatabasesPromise["catch"](function () {})]).then(function () {
        return setRefreshing(false);
      });
    }
  }, [dataSource, currentDatabaseName, setCurrentSchema, refreshing]);
  var schemasRef = useRef();
  schemasRef.current = schemas;
  useEffect(function () {
    var isCancelled = false;
    if (currentDatabaseName && !has(schemasRef.current, currentDatabaseName)) {
      setLoadingSchema(true);
      getSchema(dataSource, currentDatabaseName)["catch"](function () {
        return Promise.resolve({
          schema: [],
          has_columns: true
        });
      }).then(function (_ref2) {
        var schema = _ref2.schema,
          has_columns = _ref2.has_columns;
        if (!isCancelled) {
          if (!has_columns && !isEmpty(schema)) {
            schema = map(schema, function (table) {
              return _extends({}, table, {
                loading: true
              });
            });
            getSchema(dataSource, currentDatabaseName, true).then(function (_ref3) {
              var schema = _ref3.schema;
              if (!isCancelled) {
                setCurrentSchema(schema);
              }
            });
          }
          setCurrentSchema(schema);
        }
      })["finally"](function () {
        if (!isCancelled) {
          setLoadingSchema(false);
        }
      });
    }
    return function () {
      isCancelled = true;
    };
  }, [dataSource, currentDatabaseName, setCurrentSchema]);
  var defaultDatabaseNameRef = useRef();
  defaultDatabaseNameRef.current = get(options, "selectedDatabase", null);
  useEffect(function () {
    var isCancelled = false;
    setLoadingDatabases(true);
    setCurrentDatabaseName(undefined);
    setSchemas({});
    getDatabases(dataSource)["catch"](function () {
      return Promise.resolve([]);
    }).then(function (data) {
      if (!isCancelled) {
        setDatabases(data);

        // We set the database using this order:
        // 1. Currently selected value.
        // 2. Last used stored in localStorage.
        // 3. default database.
        // 4. first database in the list.
        var lastUsedDatabase = defaultDatabaseNameRef.current || localStorage.getItem("lastSelectedDatabricksDatabase_" + dataSource.id);
        if (!lastUsedDatabase) {
          lastUsedDatabase = includes(data, "default") ? "default" : first(data) || null;
        }
        setCurrentDatabaseName(lastUsedDatabase);
      }
    })["finally"](function () {
      if (!isCancelled) {
        setLoadingDatabases(false);
      }
    });
    return function () {
      isCancelled = true;
    };
  }, [dataSource]);
  var setCurrentDatabase = useCallback(function (databaseName) {
    if (databaseName) {
      try {
        localStorage.setItem("lastSelectedDatabricksDatabase_" + dataSource.id, databaseName);
      } catch (e) {
        // `localStorage.setItem` may throw exception if there are no enough space - in this case it could be ignored
      }
    }
    setCurrentDatabaseName(databaseName);
    if (isFunction(onOptionsUpdate) && databaseName !== defaultDatabaseNameRef.current) {
      onOptionsUpdate(_extends({}, options, {
        selectedDatabase: databaseName
      }));
    }
  }, [dataSource.id, options, onOptionsUpdate]);
  return {
    databases: databases,
    loadingDatabases: loadingDatabases,
    schema: schema,
    loadingSchema: loadingSchema,
    currentDatabaseName: currentDatabaseName,
    setCurrentDatabase: setCurrentDatabase,
    loadTableColumns: loadTableColumns,
    refreshAll: refreshAll,
    refreshing: refreshing
  };
}