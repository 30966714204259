export var getValueSourcesUtil = function getValueSourcesUtil(fieldData, operator, getValueSources) {
  // TypeScript doesn't allow it directly, but in practice
  // `fieldData` can end up being undefined or null. The nullish
  // coalescing assignment below avoids errors like
  // "TypeError: Cannot read properties of undefined (reading 'name')"
  var fd = fieldData !== null && fieldData !== void 0 ? fieldData : /* istanbul ignore else */{};
  if (fd.valueSources) {
    if (typeof fd.valueSources === 'function') {
      return fd.valueSources(operator);
    }
    return fd.valueSources;
  }
  if (getValueSources) {
    var vals = getValueSources(fd.name, operator);
    /* istanbul ignore else */
    if (vals) return vals;
  }
  return ['value'];
};