import { useEffect } from 'react';
import { toArray } from '../arrayUtils';
/**
 * This Effect trims the value if all of the following are true:
 *  - `inputType` is "number"
 *  - `operator` is not one of ("between", "notBetween", "in", "notIn")
 *  - `value` is an array OR the value is a string containing a comma
 *
 * For example, consider the following rule:
 *
 * `{ field: "f1", operator: "between", value: "12,14" }`
 *
 * If its operator changes to "=", the value will be reset to "12" since
 * the "number" input type can't handle arrays or strings with commas.
 */
export var useValueEditor = function useValueEditor(_ref) {
  var handleOnChange = _ref.handleOnChange,
    inputType = _ref.inputType,
    operator = _ref.operator,
    value = _ref.value;
  useEffect(function () {
    if (inputType === 'number' && !['between', 'notBetween', 'in', 'notIn'].includes(operator) && (typeof value === 'string' && value.includes(',') || Array.isArray(value))) {
      var _toArray$;
      handleOnChange((_toArray$ = toArray(value)[0]) !== null && _toArray$ !== void 0 ? _toArray$ : '');
    }
  }, [handleOnChange, inputType, operator, value]);
};