import _extends from "@babel/runtime/helpers/extends";
import DetailsRenderer from "./DetailsRenderer";
var DEFAULT_OPTIONS = {};
export default {
  type: "DETAILS",
  name: "Details View",
  getOptions: function getOptions(options) {
    return _extends({}, DEFAULT_OPTIONS, options);
  },
  Renderer: DetailsRenderer,
  defaultColumns: 2,
  defaultRows: 2
};