var Submitter = {
  category: 'SUBMITTER',
  group: 'Digiforce',
  icon: '',
  "package": 'antd',
  snippets: [{
    title: 'Submit',
    schema: {
      componentName: "Row",
      props: {
        align: "top",
        justify: "start",
        wrap: false
      },
      condition: true,
      children: [{
        componentName: "Col",
        props: {
          span: 24,
          order: 0,
          style: {
            display: "flex",
            flexDirection: "row-reverse"
          }
        },
        condition: true,
        children: [{
          componentName: "Space",
          props: {
            align: "start",
            direction: "horizontal",
            size: "middle"
          },
          condition: true,
          children: [{
            componentName: "Button",
            props: {
              children: "Cancel",
              htmlType: "button",
              size: "middle",
              shape: "default",
              icon: {
                type: "JSSlot",
                value: [{
                  componentName: "Icon",
                  props: {
                    type: "CloseCircleOutlined",
                    size: 14
                  },
                  condition: true
                }]
              }
            },
            condition: true
          }, {
            componentName: "Button",
            props: {
              children: "Submit",
              htmlType: "submit",
              type: "primary",
              size: "middle",
              shape: "default",
              icon: {
                type: "JSSlot",
                value: [{
                  componentName: "Icon",
                  props: {
                    type: "CheckCircleFilled",
                    size: 14
                  },
                  condition: true
                }]
              }
            },
            condition: true
          }]
        }]
      }]
    }
  }]
};
export { Submitter };