import { loadEntitiesDataRequest } from '../records_request';
import { dataServicesSelector } from '../../selectors';
import { createAction as baseCreateAction } from '../base';
export var TREE_STATE_CHANGE_ACTION = 'TREE_STATE_CHANGE';
export var createTreeAction = function createTreeAction(partialStateName, partialStateValue, options) {
  return baseCreateAction(TREE_STATE_CHANGE_ACTION, partialStateName, partialStateValue, options);
};
export function loadTreeEntitiesData(options) {
  return function (dispatch, getState) {
    var service = dataServicesSelector(getState());
    return loadEntitiesDataRequest(dispatch, TREE_STATE_CHANGE_ACTION, service, options);
  };
}