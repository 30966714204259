import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import { visibleAppsSelector } from '../../selectors';
import WidgetApps from './widget_apps';
function mapStateToProps() {
  return function (state, ownProps) {
    var apps = visibleAppsSelector(state);
    return Object.assign({}, {
      apps: apps
    }, _extends({}, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    init: function init(options) {}
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetApps);