import { defaultPlaceholderFieldName, defaultPlaceholderOperatorName } from '../../defaults';
import { uniqByName } from '../../internal/uniq';
import { toArray } from '../arrayUtils';
import { convertFromIC } from '../convertQuery';
import { isRuleOrGroupValid } from '../isRuleOrGroupValid';
import { defaultRuleProcessorCEL } from './defaultRuleProcessorCEL';
import { defaultRuleProcessorJsonLogic } from './defaultRuleProcessorJsonLogic';
import { defaultRuleProcessorMongoDB } from './defaultRuleProcessorMongoDB';
import { defaultRuleProcessorSpEL } from './defaultRuleProcessorSpEL';
import { defaultValueProcessorByRule } from './defaultValueProcessorByRule';
import { celCombinatorMap, isValueProcessorLegacy, mapSQLOperator, numerifyValues, shouldRenderAsNumber } from './utils';

/**
 * Formats a query in the requested output format.
 */

function formatQuery(ruleGroup, options) {
  if (options === void 0) {
    options = {};
  }
  var format = 'json';
  var valueProcessorInternal = defaultValueProcessorByRule;
  var ruleProcessorInternal = null;
  var quoteFieldNamesWith = '';
  var validator = function validator() {
    return true;
  };
  var fields = [];
  var validationMap = {};
  var fallbackExpression = '';
  var paramPrefix = ':';
  var parseNumbers = false;
  var placeholderFieldName = defaultPlaceholderFieldName;
  var placeholderOperatorName = defaultPlaceholderOperatorName;
  if (typeof options === 'string') {
    format = options.toLowerCase();
    if (format === 'mongodb') {
      ruleProcessorInternal = defaultRuleProcessorMongoDB;
    } else if (format === 'cel') {
      ruleProcessorInternal = defaultRuleProcessorCEL;
    } else if (format === 'spel') {
      ruleProcessorInternal = defaultRuleProcessorSpEL;
    } else if (format === 'jsonlogic') {
      ruleProcessorInternal = defaultRuleProcessorJsonLogic;
    }
  } else {
    var _options$format, _ruleProcessorInterna, _ruleProcessorInterna2, _ruleProcessorInterna3, _ruleProcessorInterna4, _options$quoteFieldNa, _options$validator, _options$fields, _options$fallbackExpr, _options$paramPrefix, _options$placeholderF, _options$placeholderO;
    format = ((_options$format = options.format) !== null && _options$format !== void 0 ? _options$format : 'json').toLowerCase();
    var _options = options,
      _options$valueProcess = _options.valueProcessor,
      valueProcessor = _options$valueProcess === void 0 ? null : _options$valueProcess,
      _options$ruleProcesso = _options.ruleProcessor,
      ruleProcessor = _options$ruleProcesso === void 0 ? null : _options$ruleProcesso;
    if (typeof ruleProcessor === 'function') {
      ruleProcessorInternal = ruleProcessor;
    }
    valueProcessorInternal = typeof valueProcessor === 'function' ? function (r) {
      return isValueProcessorLegacy(valueProcessor) ? valueProcessor(r.field, r.operator, r.value, r.valueSource) : valueProcessor(r, {
        parseNumbers: parseNumbers
      });
    } : format === 'mongodb' ? (_ruleProcessorInterna = ruleProcessorInternal) !== null && _ruleProcessorInterna !== void 0 ? _ruleProcessorInterna : defaultRuleProcessorMongoDB : format === 'cel' ? (_ruleProcessorInterna2 = ruleProcessorInternal) !== null && _ruleProcessorInterna2 !== void 0 ? _ruleProcessorInterna2 : defaultRuleProcessorCEL : format === 'spel' ? (_ruleProcessorInterna3 = ruleProcessorInternal) !== null && _ruleProcessorInterna3 !== void 0 ? _ruleProcessorInterna3 : defaultRuleProcessorSpEL : format === 'jsonlogic' ? (_ruleProcessorInterna4 = ruleProcessorInternal) !== null && _ruleProcessorInterna4 !== void 0 ? _ruleProcessorInterna4 : defaultRuleProcessorJsonLogic : defaultValueProcessorByRule;
    quoteFieldNamesWith = (_options$quoteFieldNa = options.quoteFieldNamesWith) !== null && _options$quoteFieldNa !== void 0 ? _options$quoteFieldNa : '';
    validator = (_options$validator = options.validator) !== null && _options$validator !== void 0 ? _options$validator : function () {
      return true;
    };
    fields = (_options$fields = options.fields) !== null && _options$fields !== void 0 ? _options$fields : [];
    fallbackExpression = (_options$fallbackExpr = options.fallbackExpression) !== null && _options$fallbackExpr !== void 0 ? _options$fallbackExpr : '';
    paramPrefix = (_options$paramPrefix = options.paramPrefix) !== null && _options$paramPrefix !== void 0 ? _options$paramPrefix : ':';
    parseNumbers = !!options.parseNumbers;
    placeholderFieldName = (_options$placeholderF = options.placeholderFieldName) !== null && _options$placeholderF !== void 0 ? _options$placeholderF : defaultPlaceholderFieldName;
    placeholderOperatorName = (_options$placeholderO = options.placeholderOperatorName) !== null && _options$placeholderO !== void 0 ? _options$placeholderO : defaultPlaceholderOperatorName;
  }
  if (!fallbackExpression) {
    fallbackExpression = format === 'mongodb' ? '"$and":[{"$expr":true}]' : format === 'cel' || format === 'spel' ? '1 == 1' : '(1 = 1)';
  }
  if (format === 'json' || format === 'json_without_ids') {
    var rg = parseNumbers ? numerifyValues(ruleGroup) : ruleGroup;
    if (format === 'json') {
      return JSON.stringify(rg, null, 2);
    } else {
      return JSON.stringify(rg, ['rules', 'field', 'value', 'operator', 'combinator', 'not', 'valueSource']);
    }
  } else {
    // istanbul ignore else
    if (typeof validator === 'function') {
      var validationResult = validator(ruleGroup);
      if (typeof validationResult === 'boolean') {
        if (validationResult === false) {
          return format === 'parameterized' ? {
            sql: fallbackExpression,
            params: []
          } : format === 'parameterized_named' ? {
            sql: fallbackExpression,
            params: {}
          } : format === 'mongodb' ? "{" + fallbackExpression + "}" : format === 'jsonlogic' ? false : fallbackExpression;
        }
      } else {
        validationMap = validationResult;
      }
    }
    var validatorMap = {};
    var uniqueFields = uniqByName(fields);
    uniqueFields.forEach(function (f) {
      // istanbul ignore else
      if (typeof f.validator === 'function') {
        validatorMap[f.name] = f.validator;
      }
    });
    var validateRule = function validateRule(rule) {
      var validationResult = undefined;
      var fieldValidator = undefined;
      if (rule.id) {
        validationResult = validationMap[rule.id];
      }
      if (fields.length) {
        var fieldArr = fields.filter(function (f) {
          return f.name === rule.field;
        });
        if (fieldArr.length) {
          var field = fieldArr[0];
          // istanbul ignore else
          if (typeof field.validator === 'function') {
            fieldValidator = field.validator;
          }
        }
      }
      return [validationResult, fieldValidator];
    };
    if (format === 'sql' || format === 'parameterized' || format === 'parameterized_named') {
      var parameterized = format === 'parameterized';
      var parameterized_named = format === 'parameterized_named';
      var params = [];
      var params_named = {};
      var fieldParamIndexes = {};
      var getNextNamedParam = function getNextNamedParam(field) {
        var _fieldParamIndexes$fi;
        fieldParamIndexes[field] = ((_fieldParamIndexes$fi = fieldParamIndexes[field]) !== null && _fieldParamIndexes$fi !== void 0 ? _fieldParamIndexes$fi : 0) + 1;
        return field + "_" + fieldParamIndexes[field];
      };
      var processRule = function processRule(rule) {
        var _rule$valueSource, _rule$valueSource2;
        var _validateRule = validateRule(rule),
          validationResult = _validateRule[0],
          fieldValidator = _validateRule[1];
        if (!isRuleOrGroupValid(rule, validationResult, fieldValidator) || rule.field === placeholderFieldName || rule.operator === placeholderOperatorName) {
          return '';
        }
        var value = valueProcessorInternal(rule, {
          parseNumbers: parseNumbers,
          escapeQuotes: format === 'sql' && ((_rule$valueSource = rule.valueSource) !== null && _rule$valueSource !== void 0 ? _rule$valueSource : 'value') === 'value'
        });
        var operator = mapSQLOperator(rule.operator);
        if ((parameterized || parameterized_named) && ((_rule$valueSource2 = rule.valueSource) !== null && _rule$valueSource2 !== void 0 ? _rule$valueSource2 : 'value') === 'value') {
          if (operator.toLowerCase() === 'is null' || operator.toLowerCase() === 'is not null') {
            return "" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator;
          } else if (operator.toLowerCase() === 'in' || operator.toLowerCase() === 'not in') {
            if (value) {
              var splitValue = toArray(rule.value);
              if (parameterized) {
                splitValue.forEach(function (v) {
                  return params.push(shouldRenderAsNumber(v, parseNumbers) ? parseFloat(v) : v);
                });
                return "" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator + " (" + splitValue.map(function () {
                  return '?';
                }).join(', ') + ")";
              }
              var inParams = [];
              splitValue.forEach(function (v) {
                var thisParamName = getNextNamedParam(rule.field);
                inParams.push("" + paramPrefix + thisParamName);
                params_named[thisParamName] = shouldRenderAsNumber(v, parseNumbers) ? parseFloat(v) : v;
              });
              return "" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator + " (" + inParams.join(', ') + ")";
            } else {
              return '';
            }
          } else if (operator.toLowerCase() === 'between' || operator.toLowerCase() === 'not between') {
            if (value) {
              var valArray = toArray(rule.value);
              var _valArray$slice$map = valArray.slice(0, 2).map(function (v) {
                  return shouldRenderAsNumber(v, parseNumbers) ? parseFloat(v) : v;
                }),
                first = _valArray$slice$map[0],
                second = _valArray$slice$map[1];
              if (parameterized) {
                params.push(first);
                params.push(second);
                return "" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator + " ? and ?";
              }
              var firstParamName = getNextNamedParam(rule.field);
              var secondParamName = getNextNamedParam(rule.field);
              params_named[firstParamName] = first;
              params_named[secondParamName] = second;
              return "" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator + " " + paramPrefix + firstParamName + " and " + paramPrefix + secondParamName;
            } else {
              return '';
            }
          }
          var paramValue = rule.value;
          if (typeof rule.value === 'string') {
            if (shouldRenderAsNumber(rule.value, parseNumbers)) {
              paramValue = parseFloat(rule.value);
            } else {
              // Note that we're using `value` here, which has been processed through
              // a `valueProcessor`, as opposed to `rule.value` which has not
              paramValue = /^'.*'$/g.test(value) ? value.replace(/(^'|'$)/g, '') : /* istanbul ignore next */value;
            }
          }
          var paramName = '';
          if (parameterized) {
            params.push(paramValue);
          } else {
            paramName = getNextNamedParam(rule.field);
            params_named[paramName] = paramValue;
          }
          return ("" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator + " " + (parameterized ? '?' : "" + paramPrefix + paramName)).trim();
        } else {
          var operatorLowerCase = operator.toLowerCase();
          if ((operatorLowerCase === 'in' || operatorLowerCase === 'not in' || operatorLowerCase === 'between' || operatorLowerCase === 'not between') && !value) {
            return '';
          }
        }
        return ("" + quoteFieldNamesWith + rule.field + quoteFieldNamesWith + " " + operator + " " + value).trim();
      };
      var processRuleGroup = function processRuleGroup(rg, outermost) {
        var _rg$id;
        if (!isRuleOrGroupValid(rg, validationMap[(_rg$id = rg.id) !== null && _rg$id !== void 0 ? _rg$id : /* istanbul ignore next */''])) {
          return outermost ? fallbackExpression : '';
        }
        var processedRules = rg.rules.map(function (rule) {
          if (typeof rule === 'string') {
            return rule;
          }
          if ('rules' in rule) {
            return processRuleGroup(rule);
          }
          return processRule(rule);
        });
        if (processedRules.length === 0) {
          return fallbackExpression;
        }
        return (rg.not ? 'NOT ' : '') + "(" + processedRules.filter(Boolean).join('combinator' in rg ? " " + rg.combinator + " " : ' ') + ")";
      };
      if (parameterized) {
        return {
          sql: processRuleGroup(ruleGroup, true),
          params: params
        };
      } else if (parameterized_named) {
        return {
          sql: processRuleGroup(ruleGroup, true),
          params: params_named
        };
      } else {
        return processRuleGroup(ruleGroup, true);
      }
    } else if (format === 'mongodb') {
      var _processRuleGroup = function _processRuleGroup(rg, outermost) {
        var _rg$id2;
        if (!isRuleOrGroupValid(rg, validationMap[(_rg$id2 = rg.id) !== null && _rg$id2 !== void 0 ? _rg$id2 : /* istanbul ignore next */''])) {
          return outermost ? fallbackExpression : '';
        }
        var combinator = "\"$" + rg.combinator.toLowerCase() + "\"";
        var hasChildRules = false;
        var expressions = rg.rules.map(function (rule) {
          var _ruleProcessorInterna5;
          if ('rules' in rule) {
            var processedRuleGroup = _processRuleGroup(rule);
            if (processedRuleGroup) {
              hasChildRules = true;
              // Don't wrap in curly braces if the result already is.
              return /^\{.+\}$/.test(processedRuleGroup) ? processedRuleGroup : "{" + processedRuleGroup + "}";
            }
            return '';
          }
          var _validateRule2 = validateRule(rule),
            validationResult = _validateRule2[0],
            fieldValidator = _validateRule2[1];
          if (!isRuleOrGroupValid(rule, validationResult, fieldValidator) || rule.field === placeholderFieldName || rule.operator === placeholderOperatorName) {
            return '';
          }
          return ((_ruleProcessorInterna5 = ruleProcessorInternal) !== null && _ruleProcessorInterna5 !== void 0 ? _ruleProcessorInterna5 : valueProcessorInternal)(rule, {
            parseNumbers: parseNumbers
          });
        }).filter(Boolean);
        return expressions.length > 0 ? expressions.length === 1 && !hasChildRules ? expressions[0] : combinator + ":[" + expressions.join(',') + "]" : fallbackExpression;
      };
      var rgStandard = 'combinator' in ruleGroup ? ruleGroup : convertFromIC(ruleGroup);
      var processedQuery = _processRuleGroup(rgStandard, true);
      return /^\{.+\}$/.test(processedQuery) ? processedQuery : "{" + processedQuery + "}";
    } else if (format === 'cel') {
      var _processRuleGroup2 = function _processRuleGroup2(rg, outermost) {
        var _rg$id3;
        if (!isRuleOrGroupValid(rg, validationMap[(_rg$id3 = rg.id) !== null && _rg$id3 !== void 0 ? _rg$id3 : /* istanbul ignore next */''])) {
          return outermost ? fallbackExpression : '';
        }
        var expression = rg.rules.map(function (rule) {
          var _ruleProcessorInterna6, _rule$valueSource3;
          if (typeof rule === 'string') {
            return celCombinatorMap[rule];
          }
          if ('rules' in rule) {
            return _processRuleGroup2(rule);
          }
          var _validateRule3 = validateRule(rule),
            validationResult = _validateRule3[0],
            fieldValidator = _validateRule3[1];
          if (!isRuleOrGroupValid(rule, validationResult, fieldValidator) || rule.field === placeholderFieldName || rule.operator === placeholderOperatorName) {
            return '';
          }
          return ((_ruleProcessorInterna6 = ruleProcessorInternal) !== null && _ruleProcessorInterna6 !== void 0 ? _ruleProcessorInterna6 : valueProcessorInternal)(rule, {
            parseNumbers: parseNumbers,
            escapeQuotes: ((_rule$valueSource3 = rule.valueSource) !== null && _rule$valueSource3 !== void 0 ? _rule$valueSource3 : 'value') === 'value'
          });
        }).filter(Boolean).join('combinator' in rg ? " " + celCombinatorMap[rg.combinator] + " " : ' ');
        var _ref = rg.not || !outermost ? [(rg.not ? '!' : '') + "(", ')'] : ['', ''],
          prefix = _ref[0],
          suffix = _ref[1];
        return expression ? "" + prefix + expression + suffix : fallbackExpression;
      };
      return _processRuleGroup2(ruleGroup, true);
    } else if (format === 'spel') {
      var _processRuleGroup3 = function _processRuleGroup3(rg, outermost) {
        var _rg$id4;
        if (!isRuleOrGroupValid(rg, validationMap[(_rg$id4 = rg.id) !== null && _rg$id4 !== void 0 ? _rg$id4 : /* istanbul ignore next */''])) {
          return outermost ? fallbackExpression : '';
        }
        var expression = rg.rules.map(function (rule) {
          var _ruleProcessorInterna7, _rule$valueSource4;
          if (typeof rule === 'string') {
            return rule;
          }
          if ('rules' in rule) {
            return _processRuleGroup3(rule);
          }
          var _validateRule4 = validateRule(rule),
            validationResult = _validateRule4[0],
            fieldValidator = _validateRule4[1];
          if (!isRuleOrGroupValid(rule, validationResult, fieldValidator) || rule.field === placeholderFieldName || rule.operator === placeholderOperatorName) {
            return '';
          }
          return ((_ruleProcessorInterna7 = ruleProcessorInternal) !== null && _ruleProcessorInterna7 !== void 0 ? _ruleProcessorInterna7 : valueProcessorInternal)(rule, {
            parseNumbers: parseNumbers,
            escapeQuotes: ((_rule$valueSource4 = rule.valueSource) !== null && _rule$valueSource4 !== void 0 ? _rule$valueSource4 : 'value') === 'value'
          });
        }).filter(Boolean).join('combinator' in rg ? " " + rg.combinator + " " : ' ');
        var _ref2 = rg.not || !outermost ? [(rg.not ? '!' : '') + "(", ')'] : ['', ''],
          prefix = _ref2[0],
          suffix = _ref2[1];
        return expression ? "" + prefix + expression + suffix : fallbackExpression;
      };
      return _processRuleGroup3(ruleGroup, true);
    } else if (format === 'jsonlogic') {
      var query = 'combinator' in ruleGroup ? ruleGroup : convertFromIC(ruleGroup);
      var _processRuleGroup4 = function _processRuleGroup4(rg) {
        var _rg$id5, _ref3;
        if (!isRuleOrGroupValid(rg, validationMap[(_rg$id5 = rg.id) !== null && _rg$id5 !== void 0 ? _rg$id5 : /* istanbul ignore next */''])) {
          return false;
        }
        var processedRules = rg.rules.map(function (rule) {
          var _ruleProcessorInterna8;
          if ('rules' in rule) {
            return _processRuleGroup4(rule);
          }
          var _validateRule5 = validateRule(rule),
            validationResult = _validateRule5[0],
            fieldValidator = _validateRule5[1];
          if (!isRuleOrGroupValid(rule, validationResult, fieldValidator) || rule.field === placeholderFieldName || rule.operator === placeholderOperatorName) {
            return false;
          }
          return ((_ruleProcessorInterna8 = ruleProcessorInternal) !== null && _ruleProcessorInterna8 !== void 0 ? _ruleProcessorInterna8 : valueProcessorInternal)(rule, {
            parseNumbers: parseNumbers
          });
        }).filter(Boolean);
        if (processedRules.length === 0) {
          return false;
        }
        var jsonRuleGroup = processedRules.length === 1 ? processedRules[0] : (_ref3 = {}, _ref3[rg.combinator] = processedRules, _ref3);
        return rg.not ? {
          '!': jsonRuleGroup
        } : jsonRuleGroup;
      };
      return _processRuleGroup4(query);
    } else {
      return '';
    }
  }
}
export { formatQuery };