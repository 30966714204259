import _extends from "@babel/runtime/helpers/extends";
import Renderer from "./Renderer";
import Editor from "./Editor";
var DEFAULT_OPTIONS = {
  counterLabel: "",
  counterColName: "counter",
  rowNumber: 1,
  targetRowNumber: 1,
  stringDecimal: 0,
  stringDecChar: ".",
  stringThouSep: ",",
  tooltipFormat: "0,0.000" // TODO: Show in editor
};

export default {
  type: "COUNTER",
  name: "Counter",
  getOptions: function getOptions(options) {
    return _extends({}, DEFAULT_OPTIONS, options);
  },
  Renderer: Renderer,
  Editor: Editor,
  defaultColumns: 2,
  defaultRows: 5
};