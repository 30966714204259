import { GRID_STATE_CHANGE_ACTION } from '../../actions/views/grid';
function transformEntityState(state, payload, options) {
  return Object.assign({}, state, {
    rows: payload.partialStateValue.records,
    totalCount: payload.partialStateValue.totalCount
  }, options);
}
function reducer(state, action) {
  if (state === void 0) {
    state = {};
  }
  if (action.type === GRID_STATE_CHANGE_ACTION) {
    var _Object$assign;
    var payload = action.payload;
    switch (payload.partialStateName) {
      case 'loadDataSauce':
        return transformEntityState(state, payload, {
          loading: false
        });
      case 'requestRemoveSelectedOption':
        return Object.assign({}, state, {
          selection: payload.partialStateValue
        });
      case 'search':
        return Object.assign({}, state, {
          search: payload.partialStateValue
        }, {
          loading: false,
          currentPage: 0
        });
      case 'filters':
        return Object.assign({}, state, {
          loading: true,
          currentPage: 0
        });
      case 'currentPage':
        return Object.assign({}, state, {
          currentPage: payload.partialStateValue
        }, {
          loading: true
        });
      default:
        break;
    }
    return Object.assign({}, state, (_Object$assign = {}, _Object$assign[payload.partialStateName] = payload.partialStateValue, _Object$assign));
  }
  return state;
}
export default reducer;