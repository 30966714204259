import { API } from '@digiforce-ui/builder-store';
import 'lodash';
var CHART_OBJECT_APINAME = 'charts';
var saveOrCreateUrl = function saveOrCreateUrl(data) {
  return data.id ? "api/visualizations/" + data.id : "api/visualizations";
};
//TDOO
var Visualization = {
  save: function save(data) {
    if (data.id) {
      var doc = {
        type: data.type,
        label: data.label,
        name: data.name,
        options: data.options,
        description: data.description,
        query: data.query_id
      };
      return API.updateRecord(CHART_OBJECT_APINAME, data.id, doc);
    } else {
      var _doc = {
        type: data.type,
        label: data.label,
        name: data.name,
        options: data.options,
        description: data.description,
        query: data.query_id
      };
      return API.insertRecord(CHART_OBJECT_APINAME, _doc);
    }
    // return API.client.doFetch(saveOrCreateUrl(data), {method: 'post', body: JSON.stringify(data)})
  },

  "delete": function _delete(data) {
    return API.deleteRecord(CHART_OBJECT_APINAME, data.id);
  }
};
export default Visualization;