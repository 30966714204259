import _extends from "@babel/runtime/helpers/extends";
import { isString, each, extend, includes, map, reduce } from "lodash";
import d3 from "d3";
import chooseTextColorForBackground from "../../../lib/chooseTextColorForBackground";
import { ColorPaletteArray } from "../../ColorPalette";
import { cleanNumber, normalizeValue } from "./utils";
export function getPieDimensions(series) {
  var rows = series.length > 2 ? 2 : 1;
  var cellsInRow = Math.ceil(series.length / rows);
  var cellWidth = 1 / cellsInRow;
  var cellHeight = 1 / rows;
  var xPadding = 0.02;
  var yPadding = 0.1;
  return {
    rows: rows,
    cellsInRow: cellsInRow,
    cellWidth: cellWidth,
    cellHeight: cellHeight,
    xPadding: xPadding,
    yPadding: yPadding
  };
}
function getPieHoverInfoPattern(options) {
  var hasX = /{{\s*@@x\s*}}/.test(options.textFormat);
  var result = "text";
  if (!hasX) result += "+label";
  return result;
}
function prepareSeries(series, options, additionalOptions) {
  var cellWidth = additionalOptions.cellWidth,
    cellHeight = additionalOptions.cellHeight,
    xPadding = additionalOptions.xPadding,
    yPadding = additionalOptions.yPadding,
    cellsInRow = additionalOptions.cellsInRow,
    hasX = additionalOptions.hasX,
    index = additionalOptions.index,
    hoverInfoPattern = additionalOptions.hoverInfoPattern,
    getValueColor = additionalOptions.getValueColor;
  var seriesOptions = extend({
    type: options.globalSeriesType,
    yAxis: 0
  }, options.seriesOptions[series.name]);
  var xPosition = index % cellsInRow * cellWidth;
  var yPosition = Math.floor(index / cellsInRow) * cellHeight;
  var labels = [];
  var values = [];
  var sourceData = new Map();
  var seriesTotal = reduce(series.data, function (result, row) {
    var y = cleanNumber(row.y);
    return result + Math.abs(y);
  }, 0);
  each(series.data, function (row) {
    var x = hasX ? normalizeValue(row.x, options.xAxis.type) : "Slice " + index;
    var y = cleanNumber(row.y);
    labels.push(x);
    values.push(y);
    sourceData.set(x, {
      x: x,
      y: y,
      yPercent: y / seriesTotal * 100,
      row: row
    });
  });
  var markerColors = map(series.data, function (row) {
    return getValueColor(row.x);
  });
  var textColors = map(markerColors, function (c) {
    return chooseTextColorForBackground(c);
  });
  return {
    visible: true,
    values: values,
    labels: labels,
    type: "pie",
    hole: 0.4,
    marker: {
      colors: markerColors
    },
    hoverinfo: hoverInfoPattern,
    text: [],
    textinfo: options.showDataLabels ? "percent" : "none",
    textposition: "inside",
    textfont: {
      color: textColors
    },
    name: seriesOptions.name || series.name,
    direction: options.direction.type,
    domain: {
      x: [xPosition, xPosition + cellWidth - xPadding],
      y: [yPosition, yPosition + cellHeight - yPadding]
    },
    sourceData: sourceData
  };
}
export default function preparePieData(seriesList, options) {
  // we will use this to assign colors for values that have no explicitly set color
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'scale' does not exist on type 'typeof im... Remove this comment to see the full error message
  var getDefaultColor = d3.scale.ordinal().domain([]).range(ColorPaletteArray);
  var valuesColors = {};
  each(options.valuesOptions, function (item, key) {
    if (isString(item.color) && item.color !== "") {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      valuesColors[key] = item.color;
    }
  });
  var additionalOptions = _extends({}, getPieDimensions(seriesList), {
    hasX: includes(options.columnMapping, "x"),
    hoverInfoPattern: getPieHoverInfoPattern(options),
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    getValueColor: function getValueColor(v) {
      return valuesColors[v] || getDefaultColor(v);
    }
  });
  return map(seriesList, function (series, index) {
    return prepareSeries(series, options, _extends({}, additionalOptions, {
      index: index
    }));
  });
}