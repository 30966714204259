export default function keyMirror(obj) {
  var key;
  var mirrored = {};
  if (obj && typeof obj === 'object') {
    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        mirrored[key] = key;
      }
    }
  }
  return mirrored;
}