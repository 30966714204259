var canvas = document.createElement("canvas");
canvas.style.display = "none";
canvas.id = "digiforcePageCalculateTextWidthCanvas";
var initCanvas = function initCanvas() {
  if (document.body && document.body.appendChild) {
    if (!document.getElementById("digiforcePageCalculateTextWidthCanvas")) {
      document.body.appendChild(canvas);
    }
  }
};
export function calculateTextWidth(text, container) {
  if (container === void 0) {
    container = document.body;
  }
  initCanvas();
  var ctx = canvas.getContext("2d");
  if (ctx) {
    var containerStyle = window.getComputedStyle(container);
    ctx.font = containerStyle.fontSize + " " + containerStyle.fontFamily;
    var textMetrics = ctx.measureText(text);
    var actualWidth = textMetrics.width;
    if ("actualBoundingBoxLeft" in textMetrics) {
      // only available on evergreen browsers
      actualWidth = Math.abs(textMetrics.actualBoundingBoxLeft) + Math.abs(textMetrics.actualBoundingBoxRight);
    }
    return actualWidth;
  }
  return null;
}