export var isCELExpressionGroup = function isCELExpressionGroup(expr) {
  return expr.type === 'ExpressionGroup';
};
export var isCELConditionalAnd = function isCELConditionalAnd(expr) {
  return expr.type === 'ConditionalAnd';
};
export var isCELConditionalOr = function isCELConditionalOr(expr) {
  return expr.type === 'ConditionalOr';
};
export var isCELStringLiteral = function isCELStringLiteral(expr) {
  return expr.type === 'StringLiteral';
};
export var isCELLiteral = function isCELLiteral(expr) {
  return isCELNumericLiteral(expr) || isCELStringLiteral(expr) || expr.type === 'BooleanLiteral' || expr.type === 'NullLiteral' || expr.type === 'BytesLiteral';
};
export var isCELNumericLiteral = function isCELNumericLiteral(expr) {
  return expr.type === 'FloatLiteral' || expr.type === 'IntegerLiteral' || expr.type === 'UnsignedIntegerLiteral';
};
export var isCELRelation = function isCELRelation(expr) {
  return expr.type === 'Relation';
};
export var isCELList = function isCELList(expr) {
  return expr.type === 'List';
};
export var isCELMap = function isCELMap(expr) {
  return expr.type === 'Map';
};
export var isCELIdentifier = function isCELIdentifier(expr) {
  return expr.type === 'Identifier';
};
export var isCELNegation = function isCELNegation(expr) {
  return expr.type === 'Negation';
};
export var isCELMember = function isCELMember(expr) {
  return expr.type === 'Member';
};
export var isCELLikeExpression = function isCELLikeExpression(expr) {
  return isCELMember(expr) && !!expr.left && !!expr.right && !!expr.list && isCELIdentifier(expr.left) && isCELIdentifier(expr.right) && (expr.right.value === 'contains' || expr.right.value === 'startsWith' || expr.right.value === 'endsWith') && expr.list.value.length === 1 && (isCELStringLiteral(expr.list.value[0]) || isCELIdentifier(expr.list.value[0]));
};
function evalCELLiteralValue(literal) {
  if (literal.type === 'StringLiteral') {
    return literal.value.replace(/^((?:'''|"""|'|")?)([\s\S]*?)\1$/gm, '$2');
  } else if (literal.type === 'BooleanLiteral') {
    return literal.value;
  } else if (literal.type === 'NullLiteral' || literal.type === 'BytesLiteral') {
    return null;
  }
  return literal.value;
}
export var normalizeCombinator = function normalizeCombinator(c) {
  return c === '||' ? 'or' : 'and';
};
export var normalizeOperator = function normalizeOperator(op, flip) {
  if (flip) {
    if (op === '<') return '>';
    if (op === '<=') return '>=';
    if (op === '>') return '<';
    if (op === '>=') return '<=';
  }
  if (op === '==') return '=';
  return op;
};
export var generateFlatAndOrList = function generateFlatAndOrList(expr) {
  var combinator = normalizeCombinator(expr.type === 'ConditionalAnd' ? '&&' : '||');
  var left = expr.left,
    right = expr.right;
  if (isCELConditionalAnd(left) || isCELConditionalOr(left)) {
    return [].concat(generateFlatAndOrList(left), [combinator, right]);
  }
  return [left, combinator, right];
};
export var generateMixedAndOrList = function generateMixedAndOrList(expr) {
  var arr = generateFlatAndOrList(expr);
  var returnArray = [];
  var startIndex = 0;
  for (var i = 0; i < arr.length; i += 2) {
    if (arr[i + 1] === 'and') {
      startIndex = i;
      var j = 1;
      while (arr[startIndex + j] === 'and') {
        i += 2;
        j += 2;
      }
      var tempAndArray = arr.slice(startIndex, i + 1);
      returnArray.push(tempAndArray);
      i -= 2;
    } else if (arr[i + 1] === 'or') {
      if (i === 0 || i === arr.length - 3) {
        if (i === 0 || arr[i - 1] === 'or') {
          returnArray.push(arr[i]);
        }
        returnArray.push(arr[i + 1]);
        if (i === arr.length - 3) {
          returnArray.push(arr[i + 2]);
        }
      } else {
        if (arr[i - 1] === 'and') {
          returnArray.push(arr[i + 1]);
        } else {
          returnArray.push(arr[i]);
          returnArray.push(arr[i + 1]);
        }
      }
    }
  }
  if (returnArray.length === 1 && Array.isArray(returnArray[0])) {
    // If length is 1, then the only element is an AND array so just return that
    return returnArray[0];
  }
  return returnArray;
};
export { evalCELLiteralValue };