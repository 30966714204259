import { extend, filter } from "lodash";
import { useCallback } from "react";
import Visualization from "../../../services/visualization";
import notification from "../../../components/notification";
import useImmutableCallback from "../../../lib/hooks/useImmutableCallback";
export default function useDeleteVisualization(query, onChange) {
  var handleChange = useImmutableCallback(onChange);
  return useCallback(function (visualizationId) {
    return Visualization["delete"]({
      id: visualizationId
    }).then(function () {
      var filteredVisualizations = filter(query.visualizations, function (v) {
        return v.id !== visualizationId;
      });
      handleChange(extend(query.clone(), {
        visualizations: filteredVisualizations
      }));
    })["catch"](function () {
      notification.error("Error deleting visualization.", "Maybe it's used in a dashboard?");
    });
  }, [query, handleChange]);
}