export default [{
  category: 'commonlyEvent',
  name: 'Common Events',
  eventList: [{
    name: 'onClick'
  }, {
    name: 'onChange'
  }, {
    name: 'onInput'
  }, {
    name: 'onSelect'
  }, {
    name: 'onSubmit'
  }, {
    name: 'onReset'
  }, {
    name: 'onFocus'
  }, {
    name: 'onBlur'
  }, {
    name: 'onScroll'
  }, {
    name: 'onLoad'
  }, {
    name: 'onError'
  }]
}, {
  category: 'keyboardEvent',
  name: 'Keyboard Event',
  eventList: [{
    name: 'onKeyDown'
  }, {
    name: 'onKeyPress'
  }, {
    name: 'onKeyUp'
  }]
}, {
  category: 'mouseEvent',
  name: 'Mouse Event',
  eventList: [{
    name: 'onDoubleClick'
  }, {
    name: 'onDrag'
  }, {
    name: 'onDragEnd'
  }, {
    name: 'onDragEnter'
  }, {
    name: 'onDragExit'
  }, {
    name: 'onDragLeave'
  }, {
    name: 'onDragOver'
  }, {
    name: 'onDragStart'
  }, {
    name: 'onDrop'
  }, {
    name: 'onMouseDown'
  }, {
    name: 'onMouseEnter'
  }, {
    name: 'onMouseLeave'
  }, {
    name: 'onMouseMove'
  }, {
    name: 'onMouseOut'
  }, {
    name: 'onMouseOver'
  }, {
    name: 'onMouseUp'
  }]
}, {
  category: 'animateEvent',
  name: 'Animation Event',
  eventList: [{
    name: 'onAnimationStart'
  }, {
    name: 'onAnimationEnd'
  }, {
    name: 'onAnimationItration'
  }, {
    name: 'onTransitionEnd'
  }]
}];