import { get, includes } from "lodash";
import axiosLib from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import qs from "query-string";
import { API } from '@digiforce-ui/builder-store';
axiosLib.defaults.withCredentials = true;
export var axios = axiosLib.create({
  paramsSerializer: function paramsSerializer(params) {
    return qs.stringify(params);
  },
  xsrfCookieName: "csrf_token",
  xsrfHeaderName: "X-CSRF-TOKEN"
});
axios.interceptors.response.use(function (response) {
  return response.data;
});
export var csrfRefreshInterceptor = createAuthRefreshInterceptor(axios, function (error) {
  var message = get(error, "response.data.message");
  if (error.isAxiosError && includes(message, "CSRF")) {
    return axios.get("/ping");
  } else {
    return Promise.reject(error);
  }
}, {
  statusCodes: [400]
});
export var sessionRefreshInterceptor = createAuthRefreshInterceptor(axios, function (error) {
  var status = parseInt(get(error, "response.status"));
  var message = get(error, "response.data.message");
  // TODO: In axios@0.9.1 this check could be replaced with { skipAuthRefresh: true } flag. See axios-auth-refresh docs
  var requestUrl = get(error, "config.url");
  if (error.isAxiosError && (status === 401 || includes(message, "Please login")) && requestUrl !== "api/session") {
    // return restoreSession();
  }
  return Promise.reject(error);
}, {
  statusCodes: [401, 404],
  pauseInstanceWhileRefreshing: false // According to docs, `false` is default value, but in fact it's not :-)
});

axios.interceptors.request.use(function (config) {
  config.url = API.client.getUrl() + config.url;
  var apiKey = API.client.getAuthToken();
  if (apiKey) {
    config.headers.Authorization = "Bearer " + apiKey;
  }
  return config;
});