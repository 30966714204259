import { types } from 'mobx-state-tree';
import { get } from 'lodash';
var isProd = process.env.NODE_ENV === 'production';
var rootUrl = isProd ? '' : process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://localhost:5000';
var tenantId = localStorage.getItem('digiforce:spaceId');
var userId = localStorage.getItem('digiforce:userId');
var authToken = localStorage.getItem('digiforce:token');
var locale = 'vi_VN';
var config = get(window, 'digiforce.setting', {});
rootUrl = config.rootUrl || rootUrl;
tenantId = config.tenantId || tenantId;
userId = config.userId || userId;
authToken = config.authToken || authToken;
locale = config.locale || locale;
var hrefPopup = config.hrefPopup || false;
var env = config.env;
export var Settings = types.model('Settings', {
  isProd: isProd,
  rootUrl: rootUrl,
  hrefPopup: types.maybeNull(types["boolean"]),
  tenantId: types.maybeNull(types.string),
  userId: types.maybeNull(types.string),
  authToken: types.maybeNull(types.string),
  locale: types.maybeNull(types.string),
  currentObjectApiName: types.maybeNull(types.string),
  currentRecordId: types.maybeNull(types.string),
  env: types.frozen()
}).actions(function (self) {
  return {
    setRootUrl: function setRootUrl(rootUrl) {
      self.rootUrl = rootUrl;
    },
    setHrefPopup: function setHrefPopup(hrefPopup) {
      self.hrefPopup = hrefPopup;
    },
    setTenantId: function setTenantId(tenantId) {
      self.tenantId = tenantId;
      if (tenantId) localStorage.setItem('digiforce:spaceId', tenantId);else localStorage.removeItem('digiforce:spaceId');
    },
    setUserId: function setUserId(userId) {
      self.userId = userId;
      if (userId) localStorage.setItem('digiforce:userId', userId);else localStorage.removeItem('digiforce:userId');
    },
    setAuthToken: function setAuthToken(authToken) {
      self.authToken = authToken;
      if (authToken) localStorage.setItem('digiforce:token', authToken);else localStorage.removeItem('digiforce:token');
    },
    setLocale: function setLocale(locale) {
      self.locale = locale;
    },
    setCurrentObjectApiName: function setCurrentObjectApiName(name) {
      self.currentObjectApiName = name;
    },
    setCurrentRecordId: function setCurrentRecordId(id) {
      self.currentRecordId = id;
    }
  };
}).create({
  rootUrl: rootUrl,
  hrefPopup: hrefPopup,
  tenantId: tenantId,
  userId: userId,
  authToken: authToken,
  locale: locale,
  env: env
});