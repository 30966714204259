import { useCallback } from "react";
import useUpdateQuery from "./useUpdateQuery";
import recordEvent from "../../../services/recordEvent";
export default function useUpdateQueryDescription(query, onChange) {
  var updateQuery = useUpdateQuery(query, onChange);
  return useCallback(function (description) {
    recordEvent("edit_description", "query", query.id);
    updateQuery({
      description: description
    });
  }, [query.id, updateQuery]);
}