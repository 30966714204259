import { toArray, trimIfString } from '../arrayUtils';
import { shouldRenderAsNumber } from './utils';
var shouldNegate = function shouldNegate(op) {
  return /^(does)?not/i.test(op);
};
var wrapInNegation = function wrapInNegation(clause, negate) {
  return "" + (negate ? '!(' : '') + clause + (negate ? ')' : '');
};
export var defaultRuleProcessorSpEL = function defaultRuleProcessorSpEL(_ref, // istanbul ignore next
_temp) {
  var field = _ref.field,
    operator = _ref.operator,
    value = _ref.value,
    valueSource = _ref.valueSource;
  var _ref2 = _temp === void 0 ? {} : _temp,
    escapeQuotes = _ref2.escapeQuotes,
    parseNumbers = _ref2.parseNumbers;
  var escapeSingleQuotes = function escapeSingleQuotes(v) {
    return typeof v !== 'string' || !escapeQuotes ? v : v.replaceAll("'", "\\'");
  };
  var valueIsField = valueSource === 'field';
  var operatorTL = operator.replace(/^=$/, '==');
  var useBareValue = typeof value === 'number' || typeof value === 'boolean' || typeof value === 'bigint' || shouldRenderAsNumber(value, parseNumbers);
  if (operatorTL === '<' || operatorTL === '<=' || operatorTL === '==' || operatorTL === '!=' || operatorTL === '>' || operatorTL === '>=') {
    return field + " " + operatorTL + " " + (valueIsField || useBareValue ? trimIfString(value) : "'" + escapeSingleQuotes(value) + "'");
  } else if (operatorTL === 'contains' || operatorTL === 'doesNotContain') {
    return wrapInNegation(field + " matches " + (valueIsField || useBareValue ? trimIfString(value) : "'" + escapeSingleQuotes(value) + "'"), shouldNegate(operatorTL));
  } else if (operatorTL === 'beginsWith' || operatorTL === 'doesNotBeginWith') {
    var valueTL = valueIsField ? "'^'.concat(" + trimIfString(value) + ")" : "'" + (typeof value === 'string' && !value.startsWith('^') || useBareValue ? '^' : '') + escapeSingleQuotes(value) + "'";
    return wrapInNegation(field + " matches " + valueTL, shouldNegate(operatorTL));
  } else if (operatorTL === 'endsWith' || operatorTL === 'doesNotEndWith') {
    var _valueTL = valueIsField ? trimIfString(value) + ".concat('$')" : "'" + escapeSingleQuotes(value) + (typeof value === 'string' && !value.endsWith('$') || useBareValue ? '$' : '') + "'";
    return wrapInNegation(field + " matches " + _valueTL, shouldNegate(operatorTL));
  } else if (operatorTL === 'null') {
    return field + " == null";
  } else if (operatorTL === 'notNull') {
    return field + " != null";
  } else if (operatorTL === 'in' || operatorTL === 'notIn') {
    var negate = shouldNegate(operatorTL) ? '!' : '';
    var valArray = toArray(value);
    if (valArray.length > 0) {
      return negate + "(" + valArray.map(function (val) {
        return field + " == " + (valueIsField || shouldRenderAsNumber(val, parseNumbers) ? "" + trimIfString(val) : "'" + escapeSingleQuotes(val) + "'");
      }).join(' or ') + ")";
    } else {
      return '';
    }
  } else if (operatorTL === 'between' || operatorTL === 'notBetween') {
    var _valArray = toArray(value);
    if (_valArray.length >= 2 && !!_valArray[0] && !!_valArray[1]) {
      var first = _valArray[0],
        second = _valArray[1];
      var firstNum = shouldRenderAsNumber(first, true) ? parseFloat(first) : NaN;
      var secondNum = shouldRenderAsNumber(second, true) ? parseFloat(second) : NaN;
      var firstValue = isNaN(firstNum) ? valueIsField ? "" + first : "'" + escapeSingleQuotes(first) + "'" : firstNum;
      var secondValue = isNaN(secondNum) ? valueIsField ? "" + second : "'" + escapeSingleQuotes(second) + "'" : secondNum;
      if (firstValue === firstNum && secondValue === secondNum && secondNum < firstNum) {
        var tempNum = secondNum;
        secondValue = firstNum;
        firstValue = tempNum;
      }
      if (operator === 'between') {
        return "(" + field + " >= " + firstValue + " and " + field + " <= " + secondValue + ")";
      } else {
        return "(" + field + " < " + firstValue + " or " + field + " > " + secondValue + ")";
      }
    } else {
      return '';
    }
  }
  return '';
};