import { TREE_STATE_CHANGE_ACTION } from '../../actions/views/tree';
// import _ from 'lodash'
import produce from "immer";

/**
 * return: {id: {label: ,type: , id}}
 * @param records Data to be converted
 */
function transformData(records) {
  var items = {};
  records.forEach(function (element) {
    var item = {
      id: element._id,
      label: element.name
    };
    if (_.isEmpty(element.children)) {
      item.type = 'item';
    } else {
      item.type = 'branch';
      item.nodes = element.children;
    }
    items[element._id] = item;
  });
  return items;
}
var reducer = produce(function (draft, action) {
  if (draft === void 0) {
    draft = {};
  }
  if (action.type === TREE_STATE_CHANGE_ACTION) {
    var payload = action.payload;
    var value = payload.partialStateValue;
    var nodeId = value.node ? value.node.id : "";
    switch (payload.partialStateName) {
      case 'expandClick':
        draft.nodes[value.node.id]["expanded"] = value.expand;
        break;
      case 'click':
        var selectedNodeIds = draft.selectedNode || [];
        if (selectedNodeIds.length > 0) {
          draft.nodes[selectedNodeIds[0]].selected = false;
        }
        var selected = value.select ? true : value.node.selected;
        draft.nodes[nodeId]["selected"] = selected;
        if (selected) {
          draft.selectedNode = [nodeId];
        }
        break;
      case 'loadDataSauce':
        draft.nodes = transformData(payload.partialStateValue.records);
        draft.totalCount = payload.partialStateValue.totalCount;
        break;
      case 'changeNode':
        draft.nodes[value.node.id] = Object.assign({}, draft.nodes[value.node.id], value.node);
        break;
      case 'changeNodes':
        _.each(value.nodes, function (node) {
          draft.nodes[node.id] = Object.assign({}, draft.nodes[node.id], node);
        });
        break;
      case 'setNode':
        draft.nodes[value.node.id] = Object.assign({}, value.node);
        break;
      case 'setNodes':
        draft.nodes = value.nodes;
        break;
      default:
        break;
    }
    draft[payload.partialStateName] = payload.partialStateValue;
    // return Object.assign({}, draft, { [payload.partialStateName]: payload.partialStateValue });
  }

  return draft;
});
export default reducer;