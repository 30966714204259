import _extends from "@babel/runtime/helpers/extends";
import _ from "lodash";
import { axios } from "./axios";
import dashboardGridOptions from "../config/dashboard-grid-options";
import Widget from "./widget";
import location from "./location";
import { cloneParameter } from "./parameters";
import { policy } from "./policy";
export var urlForDashboard = function urlForDashboard(_ref) {
  var id = _ref.id,
    slug = _ref.slug;
  return "dashboards/" + id + "-" + slug;
};
export function collectDashboardFilters(dashboard, queryResults, urlParams) {
  var filters = {};
  _.each(queryResults, function (queryResult) {
    var queryFilters = queryResult && queryResult.getFilters ? queryResult.getFilters() : [];
    _.each(queryFilters, function (queryFilter) {
      var hasQueryStringValue = _.has(urlParams, queryFilter.name);
      if (!(hasQueryStringValue || dashboard.dashboard_filters_enabled)) {
        // If dashboard filters not enabled, or no query string value given,
        // skip filters linking.
        return;
      }
      if (hasQueryStringValue) {
        queryFilter.current = urlParams[queryFilter.name];
      }
      var filter = _extends({}, queryFilter);
      if (!_.has(filters, queryFilter.name)) {
        filters[filter.name] = filter;
      } else {
        filters[filter.name].values = _.union(filters[filter.name].values, filter.values);
      }
    });
  });
  return _.values(filters);
}
function prepareWidgetsForDashboard(widgets) {
  // Default height for auto-height widgets.
  // Compute biggest widget size and choose between it and some magic number.
  // This value should be big enough so auto-height widgets will not overlap other ones.
  var defaultWidgetSizeY = Math.max(_.chain(widgets).map(function (w) {
    return w.options.position.sizeY;
  }).max().value(), 20) + 5;

  // Fix layout:
  // 1. sort and group widgets by row
  // 2. update position of widgets in each row - place it right below
  //    biggest widget from previous row
  _.chain(widgets).sortBy(function (widget) {
    return widget.options.position.row;
  }).groupBy(function (widget) {
    return widget.options.position.row;
  }).reduce(function (row, widgetsAtRow) {
    var height = 1;
    _.each(widgetsAtRow, function (widget) {
      height = Math.max(height, widget.options.position.autoHeight ? defaultWidgetSizeY : widget.options.position.sizeY);
      widget.options.position.row = row;
      if (widget.options.position.sizeY < 1) {
        widget.options.position.sizeY = defaultWidgetSizeY;
      }
    });
    return row + height;
  }, 0).value();

  // Sort widgets by updated column and row value
  widgets = _.sortBy(widgets, function (widget) {
    return widget.options.position.col;
  });
  widgets = _.sortBy(widgets, function (widget) {
    return widget.options.position.row;
  });
  return widgets;
}
function calculateNewWidgetPosition(existingWidgets, newWidget) {
  var width = _.extend({
    sizeX: dashboardGridOptions.defaultSizeX
  }, _.extend({}, newWidget.options).position).sizeX;

  // Find first free row for each column
  var bottomLine = _.chain(existingWidgets).map(function (w) {
    var options = _.extend({}, w.options);
    var position = _.extend({
      row: 0,
      sizeY: 0
    }, options.position);
    return {
      left: position.col,
      top: position.row,
      right: position.col + position.sizeX,
      bottom: position.row + position.sizeY,
      width: position.sizeX,
      height: position.sizeY
    };
  }).reduce(function (result, item) {
    var from = Math.max(item.left, 0);
    var to = Math.min(item.right, result.length + 1);
    for (var i = from; i < to; i += 1) {
      result[i] = Math.max(result[i], item.bottom);
    }
    return result;
  }, _.map(new Array(dashboardGridOptions.columns), _.constant(0))).value();

  // Go through columns, pick them by count necessary to hold new block,
  // and calculate bottom-most free row per group.
  // Choose group with the top-most free row (comparing to other groups)
  return _.chain(_.range(0, dashboardGridOptions.columns - width + 1)).map(function (col) {
    return {
      col: col,
      row: _.chain(bottomLine).slice(col, col + width).max().value()
    };
  }).sortBy("row").first().value();
}
export function Dashboard(dashboard) {
  _.extend(this, dashboard);
  Object.defineProperty(this, "url", {
    get: function get() {
      return urlForDashboard(this);
    }
  });
}
function prepareDashboardWidgets(widgets) {
  return prepareWidgetsForDashboard(_.map(widgets, function (widget) {
    return new Widget(widget);
  }));
}
function transformSingle(dashboard) {
  dashboard = new Dashboard(dashboard);
  if (dashboard.widgets) {
    dashboard.widgets = prepareDashboardWidgets(dashboard.widgets);
  }
  dashboard.publicAccessEnabled = dashboard.public_url !== undefined;
  return dashboard;
}
function transformResponse(data) {
  if (data.results) {
    data = _extends({}, data, {
      results: _.map(data.results, transformSingle)
    });
  } else {
    data = transformSingle(data);
  }
  return data;
}

// const saveOrCreateUrl = data => (data.id ? `api/dashboards/${data.id}` : "api/dashboards");
var DashboardService = {
  get: function get(_ref2) {
    var id = _ref2.id,
      slug = _ref2.slug;
    var params = {};
    if (!id) {
      params.legacy = null;
    }
    return axios.get("/service/api/~packages-@digiforce/service-pages/page/" + id, {
      params: params
    }).then(transformResponse);
  },
  // getByToken: ({ token }) => axios.get(`api/dashboards/public/${token}`).then(transformResponse),
  getByToken: function getByToken(_ref3) {
    var token = _ref3.token;
    return axios.get("/service/api/~packages-@digiforce/service-pages/page/" + token).then(transformResponse);
  },
  save: function save(data) {
    if (data._id) {
      return axios.put("/api/v4/pages/" + data._id, data).then(transformResponse);
    } else {
      return axios.post("/api/v4/pages", data).then(transformResponse);
    }
  },
  "delete": function _delete(_ref4) {
    var id = _ref4.id;
    return axios["delete"]("api/dashboards/" + id).then(transformResponse);
  },
  query: function query(params) {
    return axios.get("/service/api/~packages-@digiforce/service-pages/search/page", {
      params: params
    }).then(transformResponse);
  },
  recent: function recent(params) {
    return axios.get("api/dashboards/recent", {
      params: params
    }).then(transformResponse);
  },
  myDashboards: function myDashboards(params) {
    return axios.get("api/dashboards/my", {
      params: params
    }).then(transformResponse);
  },
  favorites: function favorites(params) {
    return axios.get("api/dashboards/favorites", {
      params: params
    }).then(transformResponse);
  },
  favorite: function favorite(_ref5) {
    var id = _ref5.id;
    return axios.post("api/dashboards/" + id + "/favorite");
  },
  unfavorite: function unfavorite(_ref6) {
    var id = _ref6.id;
    return axios["delete"]("api/dashboards/" + id + "/favorite");
  }
};
_.extend(Dashboard, DashboardService);
Dashboard.prepareDashboardWidgets = prepareDashboardWidgets;
Dashboard.prepareWidgetsForDashboard = prepareWidgetsForDashboard;
Dashboard.prototype.canEdit = function canEdit() {
  return policy.canEdit(this);
};
Dashboard.prototype.getParametersDefs = function getParametersDefs() {
  var _this = this;
  var globalParams = {};
  var queryParams = location.search;
  _.each(this.widgets, function (widget) {
    if (widget.getQuery()) {
      var mappings = widget.getParameterMappings();
      widget.getQuery().getParametersDefs(false).forEach(function (param) {
        var mapping = mappings[param.name];
        if (mapping.type === Widget.MappingType.DashboardLevel) {
          // create global param
          if (!globalParams[mapping.mapTo]) {
            globalParams[mapping.mapTo] = cloneParameter(param);
            globalParams[mapping.mapTo].name = mapping.mapTo;
            globalParams[mapping.mapTo].title = mapping.title || param.title;
            globalParams[mapping.mapTo].locals = [];
          }

          // add to locals list
          globalParams[mapping.mapTo].locals.push(param);
        }
      });
    }
  });
  var resultingGlobalParams = _.values(_.each(globalParams, function (param) {
    param.setValue(param.value); // apply global param value to all locals
    param.fromUrlParams(queryParams); // try to initialize from url (may do nothing)
  }));

  // order dashboard params using paramOrder
  return _.sortBy(resultingGlobalParams, function (param) {
    return _.includes(_this.options.globalParamOrder, param.name) ? _.indexOf(_this.options.globalParamOrder, param.name) : _.size(_this.options.globalParamOrder);
  });
};
Dashboard.prototype.addWidget = function addWidget(textOrVisualization, options) {
  var _this2 = this;
  if (options === void 0) {
    options = {};
  }
  var props = {
    page: this.name,
    options: _extends({}, options, {
      isHidden: false,
      position: {}
    }),
    text: "",
    type: '',
    visualization_id: null,
    visualization: null
  };
  if (_.isString(textOrVisualization)) {
    props.text = textOrVisualization;
    props.type = 'textbox';
  } else if (_.isObject(textOrVisualization)) {
    props.visualization_id = textOrVisualization.name;
    props.visualization = textOrVisualization;
    props.type = 'charts';
  } else {
    // TODO: Throw an error?
  }
  var widget = new Widget(props);
  var position = calculateNewWidgetPosition(this.widgets, widget);
  widget.options.position.col = position.col;
  widget.options.position.row = position.row;
  return widget.save().then(function () {
    _this2.widgets = [].concat(_this2.widgets, [widget]);
    return widget;
  });
};
Dashboard.prototype.favorite = function favorite() {
  return Dashboard.favorite(this);
};
Dashboard.prototype.unfavorite = function unfavorite() {
  return Dashboard.unfavorite(this);
};