import { useState, useCallback, useEffect } from "react";
import useQueryFlags from "./useQueryFlags";
import useEditVisualizationDialog from "./useEditVisualizationDialog";
export default function useAddVisualizationDialog(query, queryResult, saveQuery, onChange) {
  var queryFlags = useQueryFlags(query);
  var editVisualization = useEditVisualizationDialog(query, queryResult, onChange);
  var _useState = useState(false),
    shouldOpenDialog = _useState[0],
    setShouldOpenDialog = _useState[1];
  useEffect(function () {
    if (!queryFlags.isNew && shouldOpenDialog) {
      setShouldOpenDialog(false);
      editVisualization();
    }
  }, [queryFlags.isNew, shouldOpenDialog, editVisualization]);
  return useCallback(function () {
    if (queryFlags.isNew) {
      setShouldOpenDialog(true);
      saveQuery();
    } else {
      editVisualization();
    }
  }, [queryFlags.isNew, saveQuery, editVisualization]);
}