import clsx from 'clsx';
export var mergeClassnames = function mergeClassnames() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  var joinClassnamesByName = function joinClassnamesByName(name) {
    return clsx(args.filter(Boolean).map(function (c) {
      return clsx(c[name]);
    }));
  };
  return {
    queryBuilder: joinClassnamesByName('queryBuilder'),
    ruleGroup: joinClassnamesByName('ruleGroup'),
    header: joinClassnamesByName('header'),
    body: joinClassnamesByName('body'),
    combinators: joinClassnamesByName('combinators'),
    addRule: joinClassnamesByName('addRule'),
    addGroup: joinClassnamesByName('addGroup'),
    cloneRule: joinClassnamesByName('cloneRule'),
    cloneGroup: joinClassnamesByName('cloneGroup'),
    removeGroup: joinClassnamesByName('removeGroup'),
    rule: joinClassnamesByName('rule'),
    fields: joinClassnamesByName('fields'),
    operators: joinClassnamesByName('operators'),
    value: joinClassnamesByName('value'),
    removeRule: joinClassnamesByName('removeRule'),
    notToggle: joinClassnamesByName('notToggle'),
    dragHandle: joinClassnamesByName('dragHandle'),
    lockRule: joinClassnamesByName('lockRule'),
    lockGroup: joinClassnamesByName('lockGroup'),
    valueSource: joinClassnamesByName('valueSource')
  };
};