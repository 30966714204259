import _extends from "@babel/runtime/helpers/extends";
import { fieldIsValidUtil, getFieldsArray } from '../parserUtils';
import { sqlParser } from './sqlParser';
import { evalSQLLiteralValue, generateFlatAndOrList, generateMixedAndXorOrList, getFieldName, getParamString, isSQLIdentifier, isSQLLiteralValue, normalizeOperator } from './utils';

/**
 * Converts a SQL `SELECT` statement into a query suitable for
 * the QueryBuilder component's `query` or `defaultQuery` props.
 */

function parseSQL(sql, options) {
  if (options === void 0) {
    options = {};
  }
  var _options = options,
    params = _options.params,
    paramPrefix = _options.paramPrefix,
    independentCombinators = _options.independentCombinators,
    fields = _options.fields,
    getValueSources = _options.getValueSources;
  var sqlString = /^[ \t\n\r\s]*SELECT\b/i.test(sql) ? sql : /^[ \t\n\r\s]*WHERE\b/i.test(sql) ? "SELECT * FROM t " + sql : "SELECT * FROM t WHERE " + sql;
  var ic = false;
  var fieldsFlat = getFieldsArray(fields);
  ic = !!independentCombinators;
  /* istanbul ignore else */
  if (params) {
    if (Array.isArray(params)) {
      var i = 0;
      sqlString = sqlString.replace(/\?/g, function () {
        var paramString = getParamString(params[i]);
        i++;
        return paramString;
      });
    } else {
      var keys = Object.keys(params);
      var prefix = paramPrefix !== null && paramPrefix !== void 0 ? paramPrefix : ':';
      keys.forEach(function (p) {
        sqlString = sqlString.replace(new RegExp("\\" + prefix + p + "\\b", 'ig'), getParamString(params[p]));
      });
    }
  }
  var fieldIsValid = function fieldIsValid(fieldName, operator, subordinateFieldName) {
    return fieldIsValidUtil({
      fieldName: fieldName,
      fieldsFlat: fieldsFlat,
      operator: operator,
      subordinateFieldName: subordinateFieldName,
      getValueSources: getValueSources
    });
  };
  var processSQLExpression = function processSQLExpression(expr) {
    if (expr.type === 'NotExpression') {
      var val = expr.value.type === 'SimpleExprParentheses' ? expr.value.value.value[0] : expr.value;
      var rule = processSQLExpression(val);
      /* instanbul ignore else */
      if (rule) {
        if ('rules' in rule) {
          return _extends({}, rule, {
            not: true
          });
        }
        return _extends({
          rules: [rule],
          not: true
        }, !ic && {
          combinator: 'and'
        });
      }
    } else if (expr.type === 'SimpleExprParentheses') {
      var ex = expr.value.value[0];
      if (ex.type === 'AndExpression' || ex.type === 'OrExpression' || ex.type === 'XorExpression') {
        return processSQLExpression(ex);
      }
      var _rule = processSQLExpression(ex);
      return _rule ? _extends({
        rules: [_rule]
      }, ic ? {} : {
        combinator: 'and'
      }) : null;
    } else if (expr.type === 'AndExpression' || expr.type === 'OrExpression' || expr.type === 'XorExpression') {
      if (ic) {
        var andOrList = generateFlatAndOrList(expr);
        var _rules = andOrList.map(function (v) {
          if (typeof v === 'string') {
            return v;
          }
          return processSQLExpression(v);
        });
        // Bail out completely if any rules in the list were invalid
        // so as not to return an incorrect and/or sequence
        if (_rules.includes(null)) {
          return null;
        }
        return {
          rules: _rules
        };
      }
      var andXorOrList = generateMixedAndXorOrList(expr);
      var combinator = andXorOrList.combinator;
      var rules = andXorOrList.expressions.map(function (obj) {
        if ('combinator' in obj) {
          return {
            combinator: obj.combinator,
            rules: obj.expressions.map(function (o) {
              if ('combinator' in o) {
                return {
                  combinator: o.combinator,
                  rules: o.expressions.map(function (oa) {
                    return processSQLExpression(oa);
                  }).filter(Boolean)
                };
              } else {
                return processSQLExpression(o);
              }
            }).filter(Boolean)
          };
        }
        return processSQLExpression(obj);
      }).filter(Boolean);
      /* istanbul ignore else */
      if (rules.length > 0) {
        return {
          combinator: combinator,
          rules: rules
        };
      }
    } else if (expr.type === 'IsNullBooleanPrimary') {
      /* istanbul ignore else */
      if (isSQLIdentifier(expr.value)) {
        var f = getFieldName(expr.value);
        var operator = expr.hasNot ? 'notNull' : 'null';
        if (fieldIsValid(f, operator)) {
          return {
            field: f,
            operator: operator,
            value: null
          };
        }
      }
    } else if (expr.type === 'ComparisonBooleanPrimary') {
      /* istanbul ignore else */
      if (isSQLIdentifier(expr.left) && !isSQLIdentifier(expr.right) || !isSQLIdentifier(expr.left) && isSQLIdentifier(expr.right)) {
        var identifier = isSQLIdentifier(expr.left) ? expr.left.value : expr.right.value;
        var valueObj = [expr.left, expr.right].find(function (t) {
          return !isSQLIdentifier(t);
        });
        if (isSQLLiteralValue(valueObj)) {
          var _f = getFieldName(identifier);
          // flip the operator if the identifier was on the right,
          // since it's now on the left as `field`
          var _operator = normalizeOperator(expr.operator, isSQLIdentifier(expr.right));
          if (fieldIsValid(_f, _operator)) {
            return {
              field: _f,
              operator: _operator,
              value: evalSQLLiteralValue(valueObj)
            };
          }
        }
      } else if (isSQLIdentifier(expr.left) && isSQLIdentifier(expr.right)) {
        var _f2 = getFieldName(expr.left);
        var sf = getFieldName(expr.right);
        var _operator2 = normalizeOperator(expr.operator);
        if (fieldIsValid(_f2, _operator2, sf)) {
          return {
            field: _f2,
            operator: _operator2,
            value: sf,
            valueSource: 'field'
          };
        }
      }
    } else if (expr.type === 'InExpressionListPredicate') {
      /* istanbul ignore else */
      if (isSQLIdentifier(expr.left)) {
        var _f3 = getFieldName(expr.left);
        var valueArray = expr.right.value.filter(isSQLLiteralValue).map(evalSQLLiteralValue);
        var _operator3 = expr.hasNot ? 'notIn' : 'in';
        var fieldArray = expr.right.value.filter(isSQLIdentifier).filter(function (sf) {
          return fieldIsValid(_f3, _operator3, sf.value);
        }).map(getFieldName);
        if (valueArray.length > 0) {
          var _options2;
          var value = (_options2 = options) !== null && _options2 !== void 0 && _options2.listsAsArrays ? valueArray : valueArray.join(', ');
          return {
            field: getFieldName(expr.left),
            operator: _operator3,
            value: value
          };
        } else if (fieldArray.length > 0) {
          var _options3;
          var _value = (_options3 = options) !== null && _options3 !== void 0 && _options3.listsAsArrays ? fieldArray : fieldArray.join(', ');
          return {
            field: getFieldName(expr.left),
            operator: _operator3,
            value: _value,
            valueSource: 'field'
          };
        }
      }
    } else if (expr.type === 'BetweenPredicate') {
      /* istanbul ignore else */
      if (isSQLIdentifier(expr.left) && isSQLLiteralValue(expr.right.left) && isSQLLiteralValue(expr.right.right)) {
        var _options4;
        var _valueArray = [expr.right.left, expr.right.right].map(evalSQLLiteralValue);
        var _value2 = (_options4 = options) !== null && _options4 !== void 0 && _options4.listsAsArrays ? _valueArray : _valueArray.join(', ');
        var _operator4 = expr.hasNot ? 'notBetween' : 'between';
        return {
          field: getFieldName(expr.left),
          operator: _operator4,
          value: _value2
        };
      } else if (isSQLIdentifier(expr.left) && isSQLIdentifier(expr.right.left) && isSQLIdentifier(expr.right.right)) {
        var _f4 = getFieldName(expr.left);
        var _valueArray2 = [expr.right.left, expr.right.right].map(getFieldName);
        var _operator5 = expr.hasNot ? 'notBetween' : 'between';
        if (_valueArray2.every(function (sf) {
          return fieldIsValid(_f4, _operator5, sf);
        })) {
          var _options5;
          var _value3 = (_options5 = options) !== null && _options5 !== void 0 && _options5.listsAsArrays ? _valueArray2 : _valueArray2.join(', ');
          return {
            field: _f4,
            operator: _operator5,
            value: _value3,
            valueSource: 'field'
          };
        }
      }
    } else if (expr.type === 'LikePredicate') {
      /* istanbul ignore else */
      if (isSQLIdentifier(expr.left) && expr.right.type === 'String') {
        var valueWithWildcards = evalSQLLiteralValue(expr.right);
        var valueWithoutWildcards = valueWithWildcards.replace(/(^%)|(%$)/g, '');
        var _operator6 = '=';
        /* istanbul ignore else */
        if (/^%.*%$/.test(valueWithWildcards) || valueWithWildcards === '%') {
          _operator6 = expr.hasNot ? 'doesNotContain' : 'contains';
        } else if (/%$/.test(valueWithWildcards)) {
          _operator6 = expr.hasNot ? 'doesNotBeginWith' : 'beginsWith';
        } else if (/^%/.test(valueWithWildcards)) {
          _operator6 = expr.hasNot ? 'doesNotEndWith' : 'endsWith';
        }
        var _f5 = getFieldName(expr.left);
        /* istanbul ignore else */
        if (fieldIsValid(_f5, _operator6)) {
          return {
            field: _f5,
            operator: _operator6,
            value: valueWithoutWildcards
          };
        }
      } else if (isSQLIdentifier(expr.left) && (expr.right.type === 'StartsWithExpr' || expr.right.type === 'EndsWithExpr' || expr.right.type === 'ContainsExpr')) {
        var subordinateFieldName = '';
        var _operator7 = '=';
        if (isSQLIdentifier(expr.right.value)) {
          subordinateFieldName = getFieldName(expr.right.value);
        }
        if (expr.right.type === 'EndsWithExpr') {
          _operator7 = expr.hasNot ? 'doesNotEndWith' : 'endsWith';
        } else if (expr.right.type === 'StartsWithExpr') {
          _operator7 = expr.hasNot ? 'doesNotBeginWith' : 'beginsWith';
        } else if (expr.right.type === 'ContainsExpr') {
          _operator7 = expr.hasNot ? 'doesNotContain' : 'contains';
        }
        var baseFieldName = getFieldName(expr.left);
        if (_operator7 !== '=' && fieldIsValid(baseFieldName, _operator7, subordinateFieldName)) {
          return {
            field: baseFieldName,
            operator: _operator7,
            value: subordinateFieldName,
            valueSource: 'field'
          };
        }
      } else if (isSQLIdentifier(expr.left) && isSQLIdentifier(expr.right)) {
        var _baseFieldName = getFieldName(expr.left);
        var _subordinateFieldName = getFieldName(expr.right);
        var _operator8 = '=';
        if (fieldIsValid(_baseFieldName, _operator8, _subordinateFieldName)) {
          return {
            field: _baseFieldName,
            operator: _operator8,
            value: _subordinateFieldName,
            valueSource: 'field'
          };
        }
      }
    }
    return null;
  };
  var where = sqlParser.parse(sqlString).value.where;
  if (where) {
    var result = processSQLExpression(where);
    if (result) {
      if ('rules' in result) {
        return result;
      }
      return _extends({
        rules: [result]
      }, ic ? {} : {
        combinator: 'and'
      });
    }
  }
  return _extends({
    rules: []
  }, ic ? {} : {
    combinator: 'and'
  });
}
export { parseSQL };