import { getCookie } from '.';
export var getUserId = function getUserId() {
  if (window.Meteor) {
    return window.Meteor.userId();
  }
  return getCookie("X-User-Id");
};
export var getAuthToken = function getAuthToken() {
  if (window.Meteor) {
    return window.Accounts._storedLoginToken();
  }
  return getCookie("X-Auth-Token");
};
export var getSpaceId = function getSpaceId() {
  if (window.Meteor) {
    return window.Digiforce.spaceId();
  }
  return getCookie("X-Space-Id");
};