// import _ from 'lodash';
import { RequestStatusOption } from '../constants';
export function dgplatformAppsSelector(state) {
  var apps = state.entities ? state.entities.apps : {};
  var assigned_apps = state.entities ? state.entities.assigned_apps : [];
  var adminApp, sortedApps;
  _.each(apps, function (app, key) {
    if (!app._id) {
      app._id = key;
    }
    if (app.is_dgplatform) {

      // if (isSpaceAdmin) {
      //     app.visible = true;
      // }
    } else {
      app.visible = false;
    }
  });
  sortedApps = _.sortBy(_.values(apps), 'sort');
  var dgplatformApps = {};
  adminApp = {};
  _.each(sortedApps, function (n) {
    if (n._id === "admin") {
      return adminApp = n;
    } else {
      return dgplatformApps[n._id] = n;
    }
  });
  dgplatformApps.admin = adminApp;
  if (assigned_apps.length) {
    _.each(dgplatformApps, function (app, key) {
      if (assigned_apps.indexOf(key) > -1) {
        app.visible = app.is_dgplatform;
      } else {
        app.visible = false;
      }
    });
  }
  return dgplatformApps;
}
export function visibleAppsSelector(state, includeAdmin) {
  if (includeAdmin === void 0) {
    includeAdmin = true;
  }
  var dgplatformApps = dgplatformAppsSelector(state);
  var apps = [];
  _.each(dgplatformApps, function (v, k) {
    if (v.visible !== false && v._id !== "admin" || includeAdmin && v._id === "admin") {
      apps.push(v);
    }
  });
  return apps;
}
export function isRequestStarted(state) {
  return state.requests.bootStrap.getBootStrap.status === RequestStatusOption.STARTED;
}
export function isRequestSuccess(state) {
  return state.requests.bootStrap.getBootStrap.status === RequestStatusOption.SUCCESS;
}
export function isRequestFailure(state) {
  return state.requests.bootStrap.getBootStrap.status === RequestStatusOption.FAILURE;
}
export function getRequestStatus(state) {
  return state.requests.bootStrap.getBootStrap.status;
}
export function getRequestError(state) {
  return state.requests.bootStrap.getBootStrap.error;
}
export function getBootstrapData(state) {
  return state.entities;
}