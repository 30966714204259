import _extends from "@babel/runtime/helpers/extends";
import { connect } from 'react-redux';
import Grid from './salesforce_list';
import { createGridAction, loadGridEntitiesData, removeViewAction as _removeViewAction } from '../../actions';
import { viewStateSelector } from '../../selectors';
import { makeNewID } from '../index';
function mapStateToProps() {
  return function (state, ownProps) {
    ownProps.id = ownProps.id || makeNewID(ownProps);
    var entityState = viewStateSelector(state, ownProps.id) || {};
    return Object.assign({}, entityState, _extends({}, entityState, ownProps));
  };
}
var mapDispatchToProps = function mapDispatchToProps(dispatch, ownProps) {
  return {
    handleChanged: function handleChanged(event, data) {
      dispatch(createGridAction('selection', data.selection, ownProps));
    },
    handleRefresh: function handleRefresh() {
      var newOptions = {};
      if (ownProps.pager || ownProps.showMoreLink) {
        newOptions.count = true;
      }
      dispatch(createGridAction('currentPage', 0, Object.assign({}, ownProps, newOptions)));
    },
    handleLoadMore: function handleLoadMore(currentPage) {
      var newOptions = {};
      newOptions.count = true;
      dispatch(createGridAction('currentPage', currentPage, Object.assign({}, ownProps, newOptions)));
    },
    handleResetFiltering: function handleResetFiltering() {
      var newOptions = {};
      dispatch(createGridAction('filteringText', null, Object.assign({}, ownProps, newOptions)));
    },
    init: function init(options) {
      var newOptions = {};
      if (ownProps.pager || ownProps.showMoreLink) {
        newOptions.count = true;
      }
      dispatch(loadGridEntitiesData(Object.assign({}, options, newOptions)));
    },
    removeViewAction: function removeViewAction(viewId) {
      return dispatch(_removeViewAction(viewId));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Grid);