import { useCallback } from "react";
import useUpdateQuery from "./useUpdateQuery";
import recordEvent from "../../../services/recordEvent";
export default function useUpdateQueryTags(query, onChange) {
  var updateQuery = useUpdateQuery(query, onChange);
  return useCallback(function (tags) {
    recordEvent("edit_tags", "query", query.id);
    updateQuery({
      tags: tags
    });
  }, [query.id, updateQuery]);
}