import _extends from "@babel/runtime/helpers/extends";
import Renderer from "./Renderer";
import Editor from "./Editor";
export default {
  type: "SANKEY",
  name: "Sankey",
  getOptions: function getOptions(options) {
    return _extends({}, options);
  },
  Renderer: Renderer,
  Editor: Editor,
  defaultRows: 7
};