export var configForm = {
  name: 'Digiforce:Form',
  image: 'https://cdn.builder.io/api/v1/image/assets%2FIsxPKMo2gPRRKeakUztj1D6uqed2%2Fef36d2a846134910b64b88e6d18c5ca5',
  inputs: [{
    name: 'name',
    type: 'string',
    helperText: 'Form name. Will be the prefix of Field id'
  }, {
    name: 'columns',
    type: 'number',
    defaultValue: 2,
    helperText: 'Table column'
  }, {
    name: 'layout',
    type: 'string',
    defaultValue: 'vertical',
    "enum": ['horizontal', 'vertical', 'inline'],
    helperText: 'Form layout'
  }, {
    name: 'size',
    type: 'string',
    "enum": ['small', 'middle', 'large'],
    defaultValue: 'middle',
    helperText: 'Set the size of the field component'
  }, {
    name: 'labelAlign',
    type: 'string',
    "enum": ['left', 'right'],
    defaultValue: 'right',
    helperText: 'The text align of label of all items'
  }, {
    name: 'initialValues',
    type: 'code',
    language: 'javascript',
    defaultValue: "({})",
    helperText: 'Set value by Form initialization or reset'
  }, {
    name: 'onValuesChange',
    type: 'code',
    language: 'javascript',
    defaultValue: "(values) => {  }",
    helperText: 'Trigger a callback event when the field value is updated'
  }, {
    name: 'onFinish',
    type: 'code',
    language: 'javascript',
    defaultValue: "(values) => {  }",
    helperText: 'Submit the form and the data verification successfully callback event'
  }, {
    name: 'preserve',
    type: 'boolean',
    defaultValue: 'true',
    helperText: 'Reserved field values when the field is deleted'
  }, {
    name: 'submitter',
    type: 'boolean',
    defaultValue: 'true',
    helperText: 'Submit button related configuration'
  }, {
    name: 'scrollToFirstError',
    type: 'boolean',
    defaultValue: 'false',
    helperText: 'Auto scroll to first failed field when submit'
  }, {
    name: 'dateFormatter',
    type: 'string',
    "enum": ['string', 'number', 'false'],
    defaultValue: 'string',
    helperText: 'Autoformat data, mainly moment forms, supports string and number modes'
  }, {
    name: 'omitNil',
    type: 'boolean',
    defaultValue: 'true',
    helperText: 'ProForm automatically clears null and undefined data, if you have agreed that nil means something, set to false to disable this feature'
  }, {
    name: 'labelCol',
    type: 'object',
    subFields: [{
      name: 'span',
      type: 'number'
    }, {
      name: 'offset',
      type: 'number'
    }],
    helperText: 'Label layout, like <Col> component. Set span offset value like {span: 3, offset: 12} or sm: {span: 3, offset: 12}'
  }],
  defaultStyles: {
    display: 'block',
    marginTop: '0',
    paddingBottom: '10px',
    minHeight: '100px'
  },
  canHaveChildren: true
};