import _extends from "@babel/runtime/helpers/extends";
import { defaultOperatorNegationMap } from '../../defaults';
import { convertToIC } from '../convertQuery';
import { isRuleGroupType } from '../isRuleGroup';
import { objectKeys } from '../objectKeys';
import { fieldIsValidUtil, getFieldsArray, isPojo } from '../parserUtils';
import { getRegExStr, isPrimitive, mongoDbToRqbOperatorMap } from './utils';
var emptyRuleGroup = {
  combinator: 'and',
  rules: []
};

/**
 * Converts a MongoDB query object or parseable string into a query suitable for
 * the QueryBuilder component's `query` or `defaultQuery` props.
 */

function parseMongoDB(mongoDbRules, options) {
  if (options === void 0) {
    options = {};
  }
  var listsAsArrays = !!options.listsAsArrays;
  var fieldsFlat = getFieldsArray(options.fields);
  var getValueSources = options.getValueSources;
  var fieldIsValid = function fieldIsValid(fieldName, operator, subordinateFieldName) {
    return fieldIsValidUtil({
      fieldName: fieldName,
      fieldsFlat: fieldsFlat,
      operator: operator,
      subordinateFieldName: subordinateFieldName,
      getValueSources: getValueSources
    });
  };
  function processMongoDbQueryBooleanOperator(field, mdbOperator, keyValue) {
    var operator = '=';
    var value = '';

    // istanbul ignore else
    if (mdbOperator === '$eq' || mdbOperator === '$ne' || mdbOperator === '$gt' || mdbOperator === '$gte' || mdbOperator === '$lt' || mdbOperator === '$lte') {
      if (mdbOperator === '$ne' && keyValue === null) {
        if (fieldIsValid(field, 'notNull')) {
          return {
            field: field,
            operator: 'notNull',
            value: null
          };
        }
      } else {
        operator = mongoDbToRqbOperatorMap[mdbOperator];
        if (fieldIsValid(field, operator)) {
          return {
            field: field,
            operator: operator,
            value: keyValue
          };
        }
      }
    } else if (mdbOperator === '$regex' && /^[^^].*[^$]$/.test(getRegExStr(keyValue))) {
      if (fieldIsValid(field, 'contains')) {
        return {
          field: field,
          operator: 'contains',
          value: getRegExStr(keyValue)
        };
      }
    } else if (mdbOperator === '$regex' && /^\^.*[^$]/.test(getRegExStr(keyValue))) {
      if (fieldIsValid(field, 'beginsWith')) {
        return {
          field: field,
          operator: 'beginsWith',
          value: getRegExStr(keyValue).replace(/^\^/, '')
        };
      }
    } else if (mdbOperator === '$regex' && /[^^].*\$/.test(getRegExStr(keyValue))) {
      if (fieldIsValid(field, 'endsWith')) {
        return {
          field: field,
          operator: 'endsWith',
          value: getRegExStr(keyValue).replace(/\$$/, '')
        };
      }
    } else if (mdbOperator === '$in' && Array.isArray(keyValue)) {
      if (fieldIsValid(field, 'in')) {
        if (listsAsArrays) {
          value = keyValue;
        } else {
          value = keyValue.map(function (v) {
            return "" + v;
          }).join(',');
        }
        return {
          field: field,
          operator: 'in',
          value: value
        };
      }
    } else if (mdbOperator === '$nin' && Array.isArray(keyValue)) {
      if (fieldIsValid(field, 'notIn')) {
        if (listsAsArrays) {
          value = keyValue;
        } else {
          value = keyValue.map(function (v) {
            return "" + v;
          }).join(',');
        }
        return {
          field: field,
          operator: 'notIn',
          value: value
        };
      }
    }
    return false;
  }
  function processMongoDbQueryObjectKey(key, keyValue) {
    var field = '';

    // istanbul ignore else
    if (key === '$and') {
      if (!Array.isArray(keyValue) || keyValue.length === 0 || !keyValue.every(isPojo)) {
        return false;
      }

      // Check if this should result in a "between" clause
      if (keyValue.length === 2 && keyValue.every(function (kv) {
        return objectKeys(kv).length === 1;
      })) {
        var rule1 = keyValue[0],
          rule2 = keyValue[1];
        var _keyValue$map = keyValue.map(function (kv) {
            return objectKeys(kv)[0];
          }),
          ruleKey1 = _keyValue$map[0],
          ruleKey2 = _keyValue$map[1];
        if (ruleKey1 === ruleKey2 && isPojo(rule1[ruleKey1]) && objectKeys(rule1[ruleKey1]).length === 1 && isPojo(rule2[ruleKey2]) && objectKeys(rule2[ruleKey2]).length === 1 && ('$gte' in rule1[ruleKey1] && '$lte' in rule2[ruleKey2] && rule2[ruleKey2].$lte >= rule1[ruleKey1].$gte || '$lte' in rule1[ruleKey1] && '$gte' in rule2[ruleKey2] && rule1[ruleKey1].$lte >= rule2[ruleKey2].$gte)) {
          var _rule1$ruleKey1$$gte, _rule2$ruleKey2$$lte;
          var val1 = (_rule1$ruleKey1$$gte = rule1[ruleKey1].$gte) !== null && _rule1$ruleKey1$$gte !== void 0 ? _rule1$ruleKey1$$gte : rule1[ruleKey1].$lte,
            val2 = (_rule2$ruleKey2$$lte = rule2[ruleKey2].$lte) !== null && _rule2$ruleKey2$$lte !== void 0 ? _rule2$ruleKey2$$lte : rule2[ruleKey2].$gte;
          var value = listsAsArrays ? [val1, val2] : val1 + "," + val2;
          if (val1 > val2) {
            value = listsAsArrays ? [val2, val1] : val2 + "," + val1;
          }
          return {
            field: ruleKey1,
            operator: 'between',
            value: value
          };
        }
      }
      var rules = keyValue.map(function (l) {
        return processMongoDbQueryObject(l);
      }).filter(Boolean);
      return rules.length > 0 ? {
        combinator: 'and',
        rules: rules
      } : false;
    } else if (key === '$or') {
      if (!Array.isArray(keyValue) || keyValue.length === 0 || !keyValue.every(isPojo)) {
        return false;
      }

      // Check if this should result in "notBetween"
      if (keyValue.length === 2 && keyValue.every(function (kv) {
        return objectKeys(kv).length === 1;
      })) {
        var _rule = keyValue[0],
          _rule2 = keyValue[1];
        var _keyValue$map2 = keyValue.map(function (kv) {
            return objectKeys(kv)[0];
          }),
          _ruleKey = _keyValue$map2[0],
          _ruleKey2 = _keyValue$map2[1];
        if (_ruleKey === _ruleKey2 && isPojo(_rule[_ruleKey]) && objectKeys(_rule[_ruleKey]).length === 1 && isPojo(_rule2[_ruleKey2]) && objectKeys(_rule2[_ruleKey2]).length === 1 && ('$gt' in _rule[_ruleKey] && '$lt' in _rule2[_ruleKey2] && _rule[_ruleKey].$gt >= _rule2[_ruleKey2].$lt || '$lt' in _rule[_ruleKey] && '$gt' in _rule2[_ruleKey2] && _rule2[_ruleKey2].$gt >= _rule[_ruleKey].$lt)) {
          var _rule$_ruleKey$$gt, _rule2$_ruleKey2$$lt;
          var _val = (_rule$_ruleKey$$gt = _rule[_ruleKey].$gt) !== null && _rule$_ruleKey$$gt !== void 0 ? _rule$_ruleKey$$gt : _rule[_ruleKey].$lt,
            _val2 = (_rule2$_ruleKey2$$lt = _rule2[_ruleKey2].$lt) !== null && _rule2$_ruleKey2$$lt !== void 0 ? _rule2$_ruleKey2$$lt : _rule2[_ruleKey2].$gt;
          var _value = listsAsArrays ? [_val, _val2] : _val + "," + _val2;
          if (_val > _val2) {
            _value = listsAsArrays ? [_val2, _val] : _val2 + "," + _val;
          }
          return {
            field: _ruleKey,
            operator: 'notBetween',
            value: _value
          };
        }
      }
      var _rules = keyValue.map(function (l) {
        return processMongoDbQueryObject(l);
      }).filter(Boolean);
      return _rules.length > 0 ? {
        combinator: 'or',
        rules: _rules
      } : false;
    } else if (key === '$not' && isPojo(keyValue)) {
      var rule = processMongoDbQueryObject(keyValue);
      if (rule) {
        if (!isRuleGroupType(rule) && (rule.operator === 'between' || rule.operator === 'in' || rule.operator === 'contains' || rule.operator === 'beginsWith' || rule.operator === 'endsWith')) {
          return _extends({}, rule, {
            operator: defaultOperatorNegationMap[rule.operator]
          });
        }
        return {
          combinator: 'and',
          rules: [rule],
          not: true
        };
      }
      return false;
    } else if (key === '$expr') {
      var op = objectKeys(keyValue)[0];
      if (/^\$(eq|gte?|lte?|n?in)$/.test(op)) {
        if (Array.isArray(keyValue[op]) && keyValue[op].length === 2 && typeof keyValue[op][0] === 'string' && /^\$/.test(keyValue[op][0])) {
          field = keyValue[op][0].replace(/^\$/, '');
          var val = keyValue[op][1];
          if (typeof val === 'string' && /^\$/.test(val) || Array.isArray(val) && val.every(function (v) {
            return typeof v === 'string';
          }) && val.every(function (v) {
            return /^\$/.test(v);
          })) {
            var valForProcessing = Array.isArray(val) ? val.map(function (v) {
              return v.replace(/^\$/, '');
            }) : val.replace(/^\$/, '');
            var tempRule = processMongoDbQueryBooleanOperator(field, op, valForProcessing);
            if (tempRule) {
              if (typeof tempRule.value === 'string' && !fieldIsValid(field, tempRule.operator, tempRule.value)) {
                return false;
              }
              return _extends({}, tempRule, {
                valueSource: 'field'
              });
            }
          }
          return processMongoDbQueryBooleanOperator(field, op, keyValue[op][1]);
        }
      }
    } else if (/^[^$]/.test(key)) {
      field = key;
      if (isPrimitive(keyValue)) {
        if (fieldIsValid(field, '=')) {
          return {
            field: field,
            operator: '=',
            value: keyValue
          };
        }
      } else if (keyValue === null) {
        if (fieldIsValid(field, 'null')) {
          return {
            field: field,
            operator: 'null',
            value: keyValue
          };
        }
      } else if (isPojo(keyValue)) {
        var betweenRule = false;
        var operators = objectKeys(keyValue).filter(function (o) {
          return /^\$(eq|ne|gte?|lte?|n?in|regex)$/.test(o);
        }).sort();
        if (operators.length === 0) {
          return false;
        }
        if ('$gte' in keyValue && '$lte' in keyValue) {
          // This is (at least) a compact "between" clause
          betweenRule = {
            field: field,
            operator: 'between',
            value: listsAsArrays ? [keyValue.$gte, keyValue.$lte] : keyValue.$gte + "," + keyValue.$lte
          };
        }
        var _rules2 = operators
        // filter out $gte and $lte if they were both present
        .filter(function (op) {
          return !(betweenRule && (op === '$gte' || op === '$lte'));
        }).map(function (op) {
          return processMongoDbQueryBooleanOperator(field, op, keyValue[op]);
        }).filter(Boolean);
        if (betweenRule) {
          _rules2.unshift(betweenRule);
        }
        if (_rules2.length === 0) {
          return false;
        }
        if (_rules2.length === 1) {
          return _rules2[0];
        }
        return {
          combinator: 'and',
          rules: _rules2
        };
      }
    }
    return false;
  }
  function processMongoDbQueryObject(mongoDbQueryObject) {
    var rules = objectKeys(mongoDbQueryObject).map(function (k) {
      return processMongoDbQueryObjectKey(k, mongoDbQueryObject[k]);
    }).filter(Boolean);
    return rules.length === 1 ? rules[0] : rules.length > 1 ? {
      combinator: 'and',
      rules: rules
    } : false;
  }
  var mongoDbPOJO = mongoDbRules;
  if (typeof mongoDbRules === 'string') {
    try {
      mongoDbPOJO = JSON.parse(mongoDbRules);
    } catch (err) {
      return emptyRuleGroup;
    }
  }

  // Bail if the mongoDbPOJO is not actually a POJO
  if (!isPojo(mongoDbPOJO)) {
    return emptyRuleGroup;
  }
  var result = processMongoDbQueryObject(mongoDbPOJO);
  var finalQuery = result ? isRuleGroupType(result) ? result : {
    combinator: 'and',
    rules: [result]
  } : emptyRuleGroup;
  return options.independentCombinators ? convertToIC(finalQuery) : finalQuery;
}
export { parseMongoDB };