import _extends from "@babel/runtime/helpers/extends";
import { useCallback, useState } from "react";
import localOptions from "../../../lib/localOptions";
import { get, extend } from "lodash";
function isAutoLimitAvailable(dataSource) {
  return get(dataSource, "supports_auto_limit", false);
}
export default function useAutoLimitFlags(dataSource, query, setQuery) {
  var isAvailable = isAutoLimitAvailable(dataSource);
  var _useState = useState(query.options.apply_auto_limit),
    isChecked = _useState[0],
    setIsChecked = _useState[1];
  query.options.apply_auto_limit = isChecked;
  var setAutoLimit = useCallback(function (state) {
    setIsChecked(state);
    localOptions.set("applyAutoLimit", state);
    setQuery(extend(query.clone(), {
      options: _extends({}, query.options, {
        apply_auto_limit: state
      })
    }));
  }, [query, setQuery]);
  return [isAvailable, isChecked, setAutoLimit];
}